import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  loading: boolean;
  openFilterModal: boolean;
  openSortModal: boolean;
  sortBy: string;
  activeButton: string;
  totalCount: number;
  perPage: number;
  currentPage: number;
  postData: any[];
  severitiyOptions: {},
  frequencyOptions: {},
  hazardOptions: {};
  selectedHazards: {};
  selectedRiskLevel: {};
  finalSelectedHazards: {};
  finalSelectedRiskLevel: {};
  rikLevelClass: string;
  applyFilter: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyPostsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getMyPostsApiCallId: string = "";
  getOptionsApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      loading: false,
      openFilterModal: false,
      openSortModal: false,
      sortBy: "default",
      activeButton: "Un Actioned",
      postData: [],
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      severitiyOptions: {},
      frequencyOptions: {},
      hazardOptions: {},
      selectedHazards: {},
      selectedRiskLevel: {},
      finalSelectedHazards: {},
      finalSelectedRiskLevel: {},
      rikLevelClass: "",
      applyFilter: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      responseJson
    ) {
      if (apiRequestCallId === this.getMyPostsApiCallId) {
        this.setState({ loading: false });
        this.getPostsCallback(responseJson);
      }
      if (apiRequestCallId === this.getOptionsApiCallId) {
        this.getOptionsCallback(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let token = await getStorageData("authToken", false);
    this.setState({ token });
    this.getMyPosts();
    this.getOptions();
  }

  conditions = {
    borderGreen: {
      "1": ["A", "B", "C"],
      "2": ["A", "B"],
    },
    borderYellow: {
      "1": ["D", "E"],
      "2": ["C", "D", "E"],
      "3": ["A", "B", "C"],
    },
    borderRed: {
      "3": ["D", "E"],
      "4": ["A", "B", "C", "D", "E"],
      "5": ["A", "B", "C", "D", "E"],
    },
  };

  getPostsCallback(response: any) {
    const postData = response?.posts?.data;
    const totalCount = response?.posts?.meta.pagination?.total_count;
    this.setState({ postData, totalCount });
  }

  applyFiters = async () => {
    const { selectedHazards, selectedRiskLevel } = this.state;

    const riskLevelIds = Object.keys(selectedRiskLevel);
    const hazardIds = Object.values(selectedHazards);

    this.setState({
      openFilterModal: false,
      applyFilter: hazardIds.length > 0 || riskLevelIds.length > 0,
      finalSelectedHazards: selectedHazards,
      finalSelectedRiskLevel: selectedRiskLevel,
      currentPage: 1
    },
      () => this.getMyPosts());
  }

  getMyPosts = async () => {
    this.setState({ loading: true });

    const { finalSelectedHazards, finalSelectedRiskLevel, activeButton, sortBy } = this.state;

    const is_actioned = (activeButton === "Actioned") ? true : false;

    const riskLevelIds = Object.keys(finalSelectedRiskLevel);
    const hazardIds = Object.values(finalSelectedHazards);

    const queryParams1 = hazardIds.map((id, index) => `hazard_ids[]=${id}`).join('&');
    const queryParams2 = riskLevelIds.map((val, index) => `risk_level_names[]=${val}`).join('&');

    let endPoint = `${configJSON.getMyPostsEndpoint}?sort_by=${sortBy}&is_actioned=${is_actioned}&page=${this.state.currentPage}&per_page=${this.state.perPage}`;

    if (queryParams1.length) {
      endPoint += `&${queryParams1}`
    }

    if (queryParams2.length) {
      endPoint += `&${queryParams2}`
    }

    this.getMyPostsApiCallId = await this.apiCallForPosts({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint
    });
  };

  handlePageChange = (_e: any, newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.getMyPosts();
    });
  };

  getOptionsCallback(response: any) {
    this.setState({ hazardOptions: response?.hazards, severitiyOptions: response?.severities, frequencyOptions: response?.frequencies });
  }

  getOptions = async () => {
    this.getOptionsApiCallId = await this.apiCallForPosts({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getOptionsEndpoint,
    });
  };

  // Api Call Function
  apiCallForPosts = async (data: {
    contentType: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
  }) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token,
    };

    const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    reqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type != "formData"
      ? reqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : reqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(reqMessage.id, reqMessage);

    return reqMessage.messageId;
  };

  handleFilter = () => {
    this.setState({
      openFilterModal: true,
      selectedHazards: this.state.finalSelectedHazards,
      selectedRiskLevel: this.state.finalSelectedRiskLevel
    });
  };

  closeFilterModal = () => {
    const { applyFilter } = this.state;

    this.setState({
      openFilterModal: false
    },
      () => {
        const riskLevelIds = Object.keys(this.state.finalSelectedRiskLevel);
        const hazardIds = Object.values(this.state.finalSelectedHazards);

        this.setState({
          applyFilter: (hazardIds.length === 0 && riskLevelIds.length === 0) ? false : true,
        }, () => {
          if (applyFilter !== this.state.applyFilter) {
            this.getMyPosts();
          }
        })
      });
  };

  handleSorting = () => {
    this.setState({ openSortModal: true });
  };

  closeSortModal = () => {
    this.setState({ openSortModal: false });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      sortBy: event.target.value,
    }, () => {
      this.closeSortModal()
      this.getMyPosts();
    });
  };

  handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const { name, checked } = event.target;
    this.setState((prevState) => {
      const updatedSelectedHazards: any = { ...prevState.selectedHazards };
      if (!checked) {
        delete updatedSelectedHazards[name];
      } else {
        updatedSelectedHazards[name] = id;
      }
      return {
        selectedHazards: updatedSelectedHazards,
      };
    });
  };

  handleRiskLevels = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const { checked } = event.target;
    this.setState((prevState) => {
      const updatedSelectedRiskLevel: any = { ...prevState.selectedRiskLevel };
      if (!checked) {
        delete updatedSelectedRiskLevel[value];
      } else {
        updatedSelectedRiskLevel[value] = true;
      }
      return {
        selectedRiskLevel: updatedSelectedRiskLevel
      };
    });
  };

  handleClear = () => {
    this.setState({ selectedHazards: {} });
  };

  clearRiskLevel = () => {
    this.setState({ selectedRiskLevel: {} })
  }

  handleClearAll = () => {
    this.setState({ selectedHazards: {}, selectedRiskLevel: {} });
  }

  handlePublishButtonClick = () => {
    this.setState({
      activeButton: "Actioned",
      currentPage: 1,
      selectedHazards: {},
      selectedRiskLevel: {},
      finalSelectedHazards: {},
      finalSelectedRiskLevel: {},
      applyFilter: false,
      sortBy: "default",
    }, () => this.getMyPosts());
  };

  handleUnactionedButtonClick = () => {
    this.setState({
      activeButton: "Un Actioned",
      currentPage: 1,
      selectedHazards: {},
      selectedRiskLevel: {},
      finalSelectedHazards: {},
      finalSelectedRiskLevel: {},
      applyFilter: false,
      sortBy: "default",
    }, () => this.getMyPosts());
  };

  onClickPost = (post: any) => {
    if (this.state.activeButton !== "Actioned") {
      setStorageData("from", "menu")
      const message: Message = new Message(getName(MessageEnum.ViewPost));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.Props), post); // this line is only needed when props has to be sent
      this.send(message);
    }
  };

  generateValues = () => {
    const values = [];
    for (let i = 1; i <= 5; i++) {
      for (let j = "A".charCodeAt(0); j <= "E".charCodeAt(0); j++) {
        const value = `${i}${String.fromCharCode(j)}`;
        values.push(value);
      }
    }
    return values;
  };

  updateBorderColor = (s: any, f: any) => {
    const conditions = {
      cardGreen: {
        "1": ["A", "B", "C"],
        "2": ["A", "B"]
      },
      cardYellow: {
        "1": ["D", "E"],
        "2": ["C", "D", "E"],
        "3": ["A", "B", "C"]
      },
      cardRed: {
        "3": ["D", "E"],
        "4": ["A", "B", "C", "D", "E"],
        "5": ["A", "B", "C", "D", "E"]
      }
    };

    for (const [clr, condition] of Object.entries(conditions)) {
      if ((condition as { [key: string]: string[] })[s]?.includes(f)) {
        return clr;
      }
    }
    return '';
  }

  goToUserProfile = (id: number | undefined) => {
    setStorageData("otherUserId", id)
    this.props.navigation.navigate("Profile")
  }
  // Customizable Area End
}
