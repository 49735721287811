import React from "react";

import {
    // Customizable Area Start
    Container,
    Box,
    Typography,
    Grid,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { poleOne, poleTwo, poleThree, rankOneIcon, rankTwoIcon, rankThreeIcon, avidLearner, arrowDownIcon } from "./assets";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import TopContributorsController, { Props } from "./TopConributorsController";
import { configJSON } from "./Karmapoints2Controller";
// Customizable Area End

export default class TopContributors extends TopContributorsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box sx={webStyle.wrapper}>
                    <Box style={webStyle.profileWrapper}>
                        <Typography style={webStyle.profileText}>{configJSON.ProfileText}</Typography>
                        <NavigateNext style={webStyle.navigateIcon} />
                        <Typography style={webStyle.profileText}>Rewards</Typography>
                        <NavigateNext style={webStyle.navigateIcon} />
                        <Typography style={webStyle.rewardsText}>Top Contributors</Typography> </Box>
                    <Typography style={webStyle.RewardsHeading}>{configJSON.TopContributorsText}</Typography>
                    <div style={webStyle.selectWrapper}>
                        <img src={arrowDownIcon} style={webStyle.selectWrapperImg} />
                        <select style={webStyle.levelSelect}>
                            <option>{configJSON.BULevelText}</option>
                            <option>{configJSON.CountryLevelText}</option>
                        </select>
                    </div>
                    <Box sx={webStyle.topContributorProfilePointsWrapper}>
                        <Box sx={webStyle.profileLeft}>
                            <img src={avidLearner} style={webStyle.avidLearner} alt="contributor" />
                            <Box>
                                <Typography style={webStyle.profileLeftName}>John doe</Typography>
                                <Typography style={webStyle.profileLeftLocation}>Plant B, Delhi</Typography>
                            </Box>
                        </Box>
                        <Box sx={webStyle.profileRight}>
                            <Box sx={webStyle.profileRightPoints}>
                                <Typography style={webStyle.profileRightPointsNum}>1058</Typography>
                                <Typography style={webStyle.profileRightPointsNumTitle}>{configJSON.pointsText}</Typography>
                            </Box>
                            <Box sx={webStyle.profileRightCount}>
                                <Typography style={webStyle.profileRightCountInner}>186</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={webStyle.mainAutoBox}>
                        <Box style={webStyle.row}>
                            <Box sx={webStyle.topTitleWrapper}>
                                <Typography style={webStyle.cardTitle}>Top 3 Contributors</Typography>
                                <Typography style={webStyle.cardDetail}> Warmest congratulations to our contributors! Thank you all for your incredible contribution towards making this platform more useful.  </Typography>
                            </Box>
                        </Box>
                        <Grid container spacing={3} style={webStyle.nomargin}>
                            <Grid xs={6} item style={webStyle.cardOuter}>
                                <Box style={webStyle.card}>
                                    <Box style={webStyle.cardInr}>
                                        <Box style={webStyle.cardInrCircle}>
                                            <img src={avidLearner} style={webStyle.avtarImage} alt="num-img" />
                                        </Box>
                                        <img src={rankTwoIcon} style={webStyle.numImg} alt="num-img" />
                                        <Typography style={webStyle.cardInrTitle} align="center">Marvin Zane</Typography>
                                        <Typography style={webStyle.cardInrCount} align="center">10470</Typography>
                                        <img src={poleTwo} style={webStyle.cardInrAfter} />
                                    </Box>
                                    <Box sx={webStyle.cardInr1}>
                                        <Box style={webStyle.cardInr1Circle}>
                                            <img src={avidLearner} style={webStyle.avtarImage} alt="num-img" />
                                        </Box>
                                        <img src={rankOneIcon} style={webStyle.numImg} alt="rank-one" />
                                        <Typography style={webStyle.cardInr1Title} align="center">Kristin Cooper</Typography>
                                        <Typography style={webStyle.cardInr1Count} align="center">10890</Typography>
                                        <img src={poleOne} style={webStyle.cardInr1After} />
                                    </Box>
                                    <Box sx={webStyle.cardInr2}>
                                        <Box style={webStyle.cardInr2Circle}>
                                            <img src={avidLearner} style={webStyle.avtarImage} alt="num-img" />
                                        </Box>
                                        <img src={rankThreeIcon} style={webStyle.numImg} alt="num-img" />
                                        <Typography style={webStyle.cardInr2Title} align="center">Jessica Howard</Typography>
                                        <Typography style={webStyle.cardInr2Count} align="center">10140</Typography>
                                        <img src={poleThree} style={webStyle.cardInr2After} />
                                    </Box>
                                </Box>
                            </Grid>

                        </Grid>
                        <Box style={webStyle.row}>
                            <Box sx={webStyle.topTitleWrapper}>
                                <Typography style={webStyle.cardTitle}>Lorem Ipsum</Typography>
                            </Box>
                        </Box>
                        <Grid container spacing={3}>
                            <Grid xs={6} item>
                                <Box style={webStyle.cardTopContributor}>
                                    <Box style={webStyle.cardTopContributorRow}>
                                        <Box style={webStyle.cardTopContributorInner}>
                                            <Box style={webStyle.profileImg}>
                                                <img src={avidLearner} style={webStyle.avtarImage} alt="num-img" />
                                            </Box>
                                            <Box>
                                                <Typography style={webStyle.topContributorProfileName}>Kristin Cooper</Typography>
                                                <Typography style={webStyle.topContributorProfilePoint}><strong>9000</strong> points</Typography>
                                            </Box>
                                            <Typography style={webStyle.topContributorProfileNum}>4</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={webStyle.cardTopContributorRow}>
                                        <Box style={webStyle.cardTopContributorInner}>
                                            <Box style={webStyle.profileImg}>
                                                <img src={avidLearner} style={webStyle.avtarImage} alt="num-img" />
                                            </Box>
                                            <Box>
                                                <Typography style={webStyle.topContributorProfileName}>Kristin Cooper</Typography>
                                                <Typography style={webStyle.topContributorProfilePoint}><strong>9000</strong> points</Typography>
                                            </Box>
                                            <Typography style={webStyle.topContributorProfileNum}>5</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle = {
    navigateIcon: {
        fill: '#DCDCDC'
    },
    wrapper: {
        width: '100%',
        backgroundColor: '#F7F1F1',
        padding: '30px',
        boxSizing: 'border-box',
        height: 'calc(100vh - 100px)',
        overflow: 'auto'
    },
    RewardsHeading: {
        margin: '0px 0px 20px 0',
        color: "#2B2A29",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "26px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "18px",
        fontFamily: "ClotherBold"
    },
    mainWrapperAuto: {
        padding: '0 !important'
    },
    mainAutoBox: {
        background: '#fff',
        padding: '20px',
        width: '100%',
        boxSizing: 'border-box',
        borderRadius: '8px',
        paddingBottom: '30px'
    },
    profileWrapper: {
        display: 'flex',
        gap: '10px'
    },
    profileText: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        paddingBottom: '17px',
        color: '#18181E',
    },
    rewardsText: {
        color: "#8735E1",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        fontFamily: "ClotherRegular"
    },
    contiCount: {
        color: "#FFF",
        leadingTrim: "both",
        textEdge: "cap",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "46px",
        textTransform: "capitalize" as "capitalize",
        margin: '15px 0',
        display: 'block'
    },
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        width: '100%',
        fontFamily: 'Clother-Medium',
    },
    row: {
        width: '100%',
    },
    card: {
        borderRadius: '8px',
        background: '#F4F8FC',
        minHeight: '260px',
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '70px',
        fontFamily: "ClotherBold",
    },
    cardTitle: {
        color: '#2B2A29',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        textTransform: 'capitalize' as 'capitalize',
        fontFamily: "ClotherBold"
    },
    cardDetail: {
        color: "#828282",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        letterSpacing: "-0.408px",
        fontFamily: "ClotherRegular"

    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '20px 0'
    },
    topTitleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0',
        flexDirection: 'column'
    },
    viewAll: {
        color: '#8735E1',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '22px',
        textTransform: 'capitalize' as 'capitalize',
    },
    cardInr: {
        background: '#F1F1F1',
        width: '150px',
        height: '150px',
        border: '1px solid #DCDCDC',
        display: 'block',
        borderRadius: '8px',
        position: 'relative' as 'relative',
        marginTop: '15px'
    },
    cardInrAfter: {
        width: '26px',
        height: '90px',
        content: "''",
        display: 'block',
        position: 'absolute' as 'absolute',
        bottom: '-90px',
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    cardInr1: {
        backgroundColor: '#FFF8E2',
        width: '170px',
        height: '170px',
        border: '1px solid #FFB800',
        display: 'block',
        borderRadius: '8px',
        position: 'relative',

    },
    cardInr1After: {
        width: '26px',
        height: '88px',
        content: "''",
        display: 'block',
        position: 'absolute' as 'absolute',
        bottom: '-88px',
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    cardInr1Circle: {
        width: '100px',
        height: '100px',
        display: 'block',
        content: '',
        border: '2px solid #FFB800',
        borderRadius: '100%',
        margin: '-70px auto 0 auto',
        overflow: 'hidden'

    },
    cardInr2Circle: {
        width: '75px',
        height: '75px',
        display: 'block',
        content: '',
        border: '2px solid #EF7F2E',
        borderRadius: '100%',
        margin: '-30px auto 0 auto',
        overflow: 'hidden'

    },
    cardInr2: {
        background: '#FFE9DA',
        display: 'block',
        borderRadius: '8px',
        position: 'relative',
        width: '130px',
        height: '150px',
        border: '1px solid #EF7F2E',
        marginTop: '35px'


    },
    cardInr2After: {
        width: '26px',
        height: '70px',
        content: "''",
        display: 'block',
        position: 'absolute' as 'absolute',
        bottom: '-70px',
        left: 0,
        right: 0,
        margin: '0 auto',
    },
    cardInrCircle: {
        width: '86px',
        height: '86px',
        display: 'block',
        content: '',
        border: '2px solid #DCDCDC',
        borderRadius: '100%',
        margin: '-50px auto 0 auto',
        overflow: 'hidden'

    },
    numImg: {
        margin: '0 auto',
        display: 'block',
    },
    cardInrTitle: {
        color: '#2B2A29',
        fontSize: '14px',
        fontStyle: 'normal',
        margin: 0,
        width: '100%',
        marginTop: '10px',
        fontFamily: 'ClotherRegular',

    },
    cardInrCount: {
        color: '#2B2A29',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        margin: 0,
        width: '100%',
        fontFamily: 'ClotherBold',

    },
    cardInr1Title: {
        fontSize: '16px',
        color: '#2B2A29',
        fontStyle: 'normal',
        margin: 0,
        width: '100%',
        marginTop: '10px',
        fontFamily: 'ClotherRegular',

    },
    cardInr2Title: {
        fontSize: '12px',
        color: '#2B2A29',
        fontStyle: 'normal',
        margin: 0,
        width: '100%',
        marginTop: '10px',
        fontFamily: 'ClotherRegular',

    },
    cardInr1Count: {
        fontSize: '20px',
        color: '#2B2A29',
        fontStyle: 'normal',
        fontWeight: 700,
        margin: 0,
        width: '100%',
        fontFamily: 'ClotherBold',

    },
    cardInr2Count: {
        fontSize: '14px',
        color: '#2B2A29',
        fontStyle: 'normal',
        fontWeight: 700,
        margin: 0,
        width: '100%',
        fontFamily: 'ClotherBold',

    },
    profileImg: {
        width: '44px',
        height: '44px',
        content: '',
        display: 'block',
        border: '2px solid Purple',
        borderRadius: '100%',
        overflow: 'hidden'
    },
    cardTopContributor: {
        backgroundColor: '#fff',
    },
    cardTopContributorRow: {
        display: 'flex',
        height: '50px',
        border: '1px solid #8735E1',
        padding: '12px 14px',
        borderRadius: '8px',
        marginBottom: '10px'
    },
    cardTopContributorInner: {
        display: 'flex',
        gap: '10px',
        width: '100%',
        alignItems: 'center',
    },
    topContributorProfileName: {
        color: '#2B2A29',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '22px',
        textTransform: 'capitalize' as 'capitalize',
        margin: '0 !important',
        fontFamily: "ClotherRegular"
    },
    topContributorProfilePoint: {
        color: '#2B2A29',
        fontSize: '16px',
        fontStyle: 'normal',
        lineHeight: '22px',
        textTransform: 'capitalize' as 'capitalize',
        margin: '0 !important',
        fontFamily: "ClotherRegular"
    },
    topContributorProfileNum: {
        color: '#8735E1',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 900,
        textTransform: 'capitalize' as 'capitalize',
        padding: '5px',
        backgroundColor: '#ECDBFF',
        border: '4px solid #8735E1',
        borderRadius: '100%',
        height: '20px',
        width: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 0 0 auto',
        fontFamily: "ClotherBlack"

    },
    avtarImage: {
        width: '100%'
    },
    topContributorProfilePointsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: "8px",
        border: "1px solid #8735E1",
        background: "#FFF",
        boxSizing: 'border-box',
        marginBottom: '20px'
    },
    profileLeft: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        padding: '30px',

    },
    avidLearner: {
        width: '75px',
        height: '75px',
        borderRadius: '100%',
        border: '1px solid #8735E1'
    },
    profileLeftName: {
        color: "#2B2A29",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        textTransform: "capitalize" as "capitalize",
        marginBottom: '15px',
        fontFamily: 'ClotherRegular',

    },
    profileLeftLocation: {
        color: "#A1A1A1",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        textTransform: "capitalize" as "capitalize",
        fontFamily: 'ClotherRegular',

    },
    profileRight: {
        borderLeft: '1px solid #8735E1',
        display: 'flex'
    },
    profileRightPoints: {
        padding: '30px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: "100%", boxSizing: "border-box",
        gap: '10px'
    },
    profileRightPointsNum: {
        color: "#8735E1",
        textAlign: "center" as "center",
        fontSize: "34px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "22px",
        textTransform: "capitalize" as "capitalize",
        fontFamily: 'ClotherBold',

    },
    profileRightPointsNumTitle: {
        color: "#8735E1",
        textAlign: "center" as "center",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        textTransform: "capitalize" as "capitalize",
        fontFamily: 'ClotherRegular',

    },
    profileRightCount: {
        borderRadius: "0px 8px 8px 0px",
        background:
            "linear-gradient(104deg, #8735E1 0.93%, #C53AAF 54.04%, #8735E1 100%)",
        padding: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    profileRightCountInner: {
        color: "#FFA017",
        textAlign: "center" as "center",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 900,
        lineHeight: "22px",
        textTransform: "capitalize" as "capitalize",
        background: '#FFF0C5',
        border: '7px solid #FFA017',
        width: "50px",
        height: "50px",
        display: "flex",
        alignItems: "center",
        borderRadius: "100%",
        fontFamily: "ClotherBlack",
        justifyContent: 'center'
    },
    levelSelect: {
        borderRadius: "8px",
        border: "1px solid #DCDCDC",
        background: "#FFF",
        padding: "10px",
        marginBottom: "15px",
        width: "130px",
        appearance: "none" as "none"
    },
    cardOuter: {
        border: '1px solid  #DCDCDC', margin: '20px 12px', borderRadius: '8px', maxWidth: '48%'
    },
    nomargin: {
        margin: '0 !important'
    },
    selectWrapper: {
        position: 'relative' as 'relative',
        width: 'max-content'
    },
    selectWrapperImg: {
        position: 'absolute' as 'absolute',
        right: '8px',
        top: '8px'
    }
};
// Customizable Area End