import React from "react";

import {
  Box,
  Button,
  Typography,
  Grid,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { starIcon, sprinterIcon, rewardCard, poleOne, poleTwo, poleThree, rankOneIcon, rankTwoIcon, rankThreeIcon, teamPlayer, avidLearner } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Karmapoints2Controller, {
  Props,
  configJSON,
} from "./Karmapoints2Controller";
import { webStyles } from "../../../components/src/styles";

export default class Karmapoints2 extends Karmapoints2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Box sx={webStyle.wrapper}>
          <Box style={webStyles.breadcrumbWrapper}>
            <Typography style={webStyles.breadcrumbItem}>{configJSON.ProfileText}</Typography>
            <NavigateNext style={webStyles.navigateIcon} />
            <Typography style={webStyles.breadcrumbActiveItem}>Rewards</Typography>
          </Box>
          <Typography style={webStyle.RewardsHeading}>{configJSON.RewardsText}</Typography>
          <Box sx={webStyle.mainAutoBox}>
            <Box sx={webStyle.contributionBox}>
              <Typography style={webStyle.contriText}>{configJSON.ContributionPointsText}</Typography>
              <span style={webStyle.contiCount}> 6500 </span>
              <Typography style={webStyle.contriText}>{configJSON.NeedText} <strong>+ 500</strong> {configJSON.PointsLevelText}</Typography>
              <Button data-test-id="redeem-point" onClick={this.navigateToRedeemPointsScreen} style={webStyle.redeemBtn}>{configJSON.RedeemPointsText}</Button>
              <img src={starIcon} alt="star" style={webStyle.starImg} />
              <Typography style={webStyle.starValue}>19</Typography>
              <img src={rewardCard} alt='card' style={webStyle.contributionBoxImg} />
            </Box>
            <Box style={webStyle.row}>
              <Box style={webStyle.titleWrapper}>
                <Typography style={webStyle.cardTitle}>{configJSON.TopContributorsText}</Typography>
                <Button data-test-id="top-contributors" style={webStyle.viewAll} onClick={this.navigateToTopContributorsScreen}>View All</Button>
              </Box>
            </Box>
            <Grid container spacing={3}>
              <Grid xs={6} item>
                <Box sx={webStyle.card}>
                  <Box style={webStyle.cardInner}>
                    <Box style={webStyle.cardInnerCircle}>
                      <img src={avidLearner} style={webStyle.avtarImg} alt="num-img" />
                    </Box>
                    <img src={rankTwoIcon} style={webStyle.numImg} alt="num-img" />
                    <Typography style={webStyle.cardInnerTitle} align="center">Marvin Zane</Typography>
                    <Typography style={webStyle.cardInnerCount} align="center">10470</Typography>
                    <img src={poleTwo} style={webStyle.cardInnerAfter} />
                  </Box>
                  <Box sx={webStyle.cardInner1}>
                    <Box style={webStyle.cardInner1Circle}>
                      <img src={avidLearner} style={webStyle.avtarImg} alt="num-img" />
                    </Box>
                    <img src={rankOneIcon} style={webStyle.numImg} alt="rank-one" />
                    <Typography style={webStyle.cardInner1Title} align="center">Kristin Cooper</Typography>
                    <Typography style={webStyle.cardInner1Count} align="center">10890</Typography>
                    <img src={poleOne} style={webStyle.cardInner1After} />
                  </Box>
                  <Box sx={webStyle.cardInner2}>
                    <Box style={webStyle.cardInner2Circle}>
                      <img src={avidLearner} style={webStyle.avtarImg} alt="num-img" />
                    </Box>
                    <img src={rankThreeIcon} style={webStyle.numImg} alt="num-img" />
                    <Typography style={webStyle.cardInner2Title} align="center">Jessica Howard</Typography>
                    <Typography style={webStyle.cardInner2Count} align="center">10140</Typography>
                    <img src={poleThree} style={webStyle.cardInner2After} />
                  </Box>
                </Box>
              </Grid>
              <Grid xs={6} item>
                <Box style={webStyle.cardRight}>
                  <Box style={webStyle.cardRightRow}>
                    <Box style={webStyle.cardRightInner}>
                      <Box style={webStyle.profileImg}>
                        <img src={avidLearner} style={webStyle.avtarImg} alt="num-img" />
                      </Box>
                      <Box>
                        <Typography style={webStyle.profileName}>Kristin Cooper</Typography>
                        <Typography style={webStyle.profilePoint}><strong>9000</strong> {configJSON.pointsText}</Typography>
                      </Box>
                      <Typography style={webStyle.profileNum}>4</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box style={webStyle.row}>
              <Box style={webStyle.titleWrapper}>
                <Typography style={webStyle.cardTitle}>{configJSON.MyBadgesText}</Typography>
                <Button style={webStyle.viewAll}>{configJSON.ViewAllText}</Button>
              </Box>
            </Box>
            <Grid container spacing={7} style={{ width: '100%' }}>
              <Grid item xs={2}>
                <Box sx={webStyle.badgeCardFirst}>
                  <img src={sprinterIcon} alt="sprinter" style={webStyle.badgeCardimg} />
                  <Typography style={webStyle.badgeCardTitleFirst} align="center">{configJSON.SprinterText}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={webStyle.badgeCard}>
                  <img src={teamPlayer} alt="team-player" style={webStyle.badgeCardimg} />
                  <Typography style={webStyle.badgeCardTitle} align="center">{configJSON.TeamPlayerText}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={webStyle.badgeCard}>
                  <img src={sprinterIcon} alt="team-player" style={webStyle.badgeCardimg} />
                  <Typography style={webStyle.badgeCardTitle} align="center">{configJSON.WarriorText}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={webStyle.badgeCard}>
                  <img src={teamPlayer} alt="team-player" style={webStyle.badgeCardimg} />

                  <Typography style={webStyle.badgeCardTitle} align="center">{configJSON.HighAchieverText}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={webStyle.badgeCardRed}>
                  <img src={avidLearner} alt="sprinter" style={webStyle.badgeCardimg} />
                  <Typography style={webStyle.badgeCardTitleFirst} align="center">{configJSON.AvidLearnerText}</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box sx={webStyle.badgeCard}>
                  <img src={teamPlayer} alt="team-player" style={webStyle.badgeCardimg} />
                  <Typography style={webStyle.badgeCardTitle} align="center">{configJSON.NewcomerText}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box style={webStyle.row}>
                  <Box style={webStyle.titleWrapper}>
                    <Typography style={webStyle.cardTitle}>{configJSON.PointsHistoryText}</Typography>
                    <Button data-test-id="points-history" style={webStyle.viewAll} onClick={this.navigateToPointsHistoryScreen}>View All</Button>
                  </Box>
                  <Box style={webStyle.pointsCard}>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>You liked Jason’s Post</Typography>
                        <Typography style={webStyle.karmaPointsDate}>30/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.karmaPointsCount}>50 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>{configJSON.reportedConcernText}</Typography>
                        <Typography style={webStyle.karmaPointsDate}>26/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.karmaPointsCount}>150 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>Commented on John’s Post</Typography>
                        <Typography style={webStyle.karmaPointsDate}>26/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.karmaPointsCount}>50 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>Completed Road Safety Course</Typography>
                        <Typography style={webStyle.karmaPointsDate}>22/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.karmaPointsCount}>50 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>You added Risk Level to Profile</Typography>
                        <Typography style={webStyle.karmaPointsDate}>10/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.karmaPointsCount}>50 {configJSON.pointsText}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box style={webStyle.row}>
                  <Box style={webStyle.titleWrapper}>
                    <Typography style={webStyle.cardTitle}>{configJSON.RedemptionHistoryText}</Typography>
                    <Button data-test-id="redemption-history" style={webStyle.viewAll} onClick={this.navigateToRedemptionHistoryScreen}>View All</Button>
                  </Box>
                  <Box style={webStyle.pointsCard}>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>Amazon voucher worth ₹200 Redeemed</Typography>
                        <Typography style={webStyle.karmaPointsDate}>30/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.rewandsCount}>500 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>Myntra voucher worth ₹200 Redeemed</Typography>
                        <Typography style={webStyle.karmaPointsDate}>26/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.rewandsCount}>150 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>Flipkart voucher worth ₹200 Redeemed</Typography>
                        <Typography style={webStyle.karmaPointsDate}>26/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.rewandsCount}>50 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>Flipkart voucher worth ₹200 Redeemed</Typography>
                        <Typography style={webStyle.karmaPointsDate}>22/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.rewandsCount}>50 {configJSON.pointsText}</Typography>
                    </Box>
                    <Box style={webStyle.karmaPointsCardRow}>
                      <Box>
                        <Typography style={webStyle.karmaPointsTitle}>Myntra voucher worth ₹200 Redeemed</Typography>
                        <Typography style={webStyle.karmaPointsDate}>10/10/2023</Typography>
                      </Box>
                      <Typography align="right" style={webStyle.rewandsCount}>50 {configJSON.pointsText}</Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  navigateIcon: {
    fill: '#DCDCDC'
  },
  wrapper: {
    width: '100%',
    backgroundColor: '#F7F1F1',
    padding: '30px',
    boxSizing: 'border-box',
    overflow: 'auto'
  },
  RewardsHeading: {
    margin: '17px 0px 20px 0px',
    color: "#2B2A29",
    leadingTrim: "both",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    fontFamily: 'ClotherBold',
  },
  mainWrapperAuto: {
    padding: `0 !important`
  },
  mainAutoBox: {
    background: '#fff',
    padding: '30px',
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  profileWrapper: {
    display: 'flex',
  },
  profileText: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '22px',
    paddingBottom: '17px',
    color: '#18181E',
  },
  rewardsText: {
    color: "#8735E1",
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: 'ClotherBold',
  },
  contributionBox: {
    borderRadius: "8px",
    background:
      "linear-gradient(104deg, #8735E1 0.93%, #C53AAF 54.04%, #8735E1 100%)",
    padding: '16px',
    width: '370px',
    position: 'relative',
    height: '150px'
  },
  contributionBoxImg: {
    position: "absolute" as "absolute",
    right: "0",
    bottom: "0",
    height: "170px"
  },
  starImg: {
    position: "absolute" as "absolute",
    right: "20px",
    top: "20px"
  },
  contriText: {
    color: "#FFF",
    leadingTrim: "both",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    textTransform: "capitalize" as "capitalize",
    fontFamily: 'ClotherRegular',
  },
  contiCount: {
    color: "#FFF",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "40px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "46px",
    textTransform: "capitalize" as "capitalize",
    margin: '10px 0',
    display: 'block',
    fontFamily: 'ClotherBold',
    marginTop: '0'
  },
  redeemBtn: {
    borderRadius: "8px",
    background: "#FFD541",
    marginTop: "15px",
    color: "#2B2A29",
    textAlign: "center" as "center",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "18px",
    letterSpacing: "-0.41px",
    textTransform: "capitalize" as "capitalize",
    padding: '10px',
    fontFamily: 'ClotherBold',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    width: '100%',
    fontFamily: 'Clother-Medium',
  },
  row: {
    width: '100%',
  },
  card: {
    borderRadius: '8px',
    background: '#F4F8FC',
    minHeight: '260px',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '70px',
    fontFamily: "ClotherBold",
    width: 'calc(100% - 20px)',
    boxSizing: 'content-box'
  },
  cardTitle: {
    color: '#2B2A29',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    textTransform: 'capitalize' as 'capitalize',
    fontFamily: 'ClotherBold',
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0'
  },
  viewAll: {
    color: '#8735E1',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '22px',
    textTransform: 'capitalize' as 'capitalize',
  },
  cardInner: {
    background: '#F1F1F1',
    width: '150px',
    height: '150px',
    border: '1px solid #DCDCDC',
    display: 'block',
    borderRadius: '8px',
    position: 'relative' as 'relative',
    marginTop: '15px'
  },
  cardInnerAfter: {
    width: '26px',
    height: '90px',
    content: "''",
    display: 'block',
    position: 'absolute' as 'absolute',
    bottom: '-90px',
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  cardInner1: {
    backgroundColor: '#FFF8E2',
    width: '170px',
    height: '170px',
    border: '1px solid #FFB800',
    display: 'block',
    borderRadius: '8px',
    position: 'relative',
  },
  cardInner1After: {
    width: '26px',
    height: '88px',
    content: "''",
    display: 'block',
    position: 'absolute' as 'absolute',
    bottom: '-88px',
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  cardInner1Circle: {
    width: '100px',
    height: '100px',
    display: 'block',
    content: '',
    border: '2px solid #FFB800',
    borderRadius: '100%',
    margin: '-70px auto 0 auto',
    overflow: 'hidden'
  },
  cardInner2Circle: {
    width: '75px',
    height: '75px',
    display: 'block',
    content: '',
    border: '2px solid #EF7F2E',
    borderRadius: '100%',
    margin: '-30px auto 0 auto',
    overflow: 'hidden'
  },
  cardInner2: {
    background: '#FFE9DA',
    display: 'block',
    borderRadius: '8px',
    position: 'relative',
    width: '130px',
    height: '150px',
    border: '1px solid #EF7F2E',
    marginTop: '35px'
  },
  cardInner2After: {
    width: '26px',
    height: '70px',
    content: "''",
    display: 'block',
    position: 'absolute' as 'absolute',
    bottom: '-70px',
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  cardInnerCircle: {
    width: '86px',
    height: '86px',
    display: 'block',
    content: '',
    border: '2px solid #DCDCDC',
    borderRadius: '100%',
    margin: '-50px auto 0 auto',
    overflow: 'hidden'
  },
  numImg: {
    margin: '0 auto',
    display: 'block',
  },
  cardInnerTitle: {
    color: '#2B2A29',
    fontSize: '14px',
    fontStyle: 'normal',
    margin: 0,
    width: '100%',
    marginTop: '10px',
    fontFamily: 'ClotherRegular',
  },
  cardInnerCount: {
    color: '#2B2A29',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    margin: 0,
    width: '100%',
    fontFamily: 'ClotherBold',
  },
  cardInner1Title: {
    fontSize: '16px',
    color: '#2B2A29',
    fontStyle: 'normal',
    margin: 0,
    width: '100%',
    marginTop: '10px',
    fontFamily: 'ClotherRegular',
  },
  cardInner2Title: {
    fontSize: '12px',
    color: '#2B2A29',
    fontStyle: 'normal',
    margin: 0,
    width: '100%',
    marginTop: '10px',
    fontFamily: 'ClotherRegular',
  },
  cardInner1Count: {
    fontSize: '20px',
    color: '#2B2A29',
    fontStyle: 'normal',
    fontWeight: 700,
    margin: 0,
    width: '100%',
    fontFamily: 'ClotherBold',
  },
  cardInner2Count: {
    fontSize: '14px',
    color: '#2B2A29',
    fontStyle: 'normal',
    fontWeight: 700,
    margin: 0,
    width: '100%',
    fontFamily: 'ClotherBold',
  },
  profileImg: {
    width: '44px',
    height: '44px',
    content: '',
    display: 'block',
    border: '2px solid #8735E1',
    borderRadius: '100%',
    overflow: 'hidden'
  },
  cardRight: {
    backgroundColor: '#fff',
  },
  cardRightRow: {
    display: 'flex',
    height: '50px',
    border: '1px solid #8735E1',
    padding: '12px 14px',
    borderRadius: '8px',
    marginBottom: '10px'
  },
  cardRightInner: {
    display: 'flex',
    gap: '10px',
    width: '100%',
    alignItems: 'center',
  },
  profileName: {
    color: '#2B2A29',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '22px',
    textTransform: 'capitalize' as 'capitalize',
    margin: '0 !important',
    fontFamily: 'ClotherRegular',
  },
  profilePoint: {
    color: '#2B2A29',
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: '22px',
    textTransform: 'capitalize' as 'capitalize',
    margin: '0 !important',
    fontFamily: 'ClotherRegular',
  },
  profileNum: {
    color: '#8735E1',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '22px',
    textTransform: 'capitalize' as 'capitalize',
    padding: '5px',
    backgroundColor: '#ECDBFF',
    border: '4px solid #8735E1',
    borderRadius: '100%',
    height: '20px',
    width: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 0 auto',
    fontFamily: 'ClotherBold',
  },
  badgeCard: {
    width: '100%',
    height: '150px',
    borderRadius: '8px',
    padding: '15px',
    background: '#F6F6F6',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeCardimg: {
    height: '150px'
  },
  badgeCardFirst: {
    width: '100%',
    height: '150px',
    borderRadius: '8px',
    padding: '15px',
    background: '#92C8FF',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeCardRed: {
    width: '100%',
    height: '150px',
    borderRadius: '8px',
    padding: '15px',
    background: '#FF9292',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeCardTitle: {
    color: "#A1a1a1",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    marginTop: '15px',
    fontFamily: 'ClotherBold',
  },
  badgeCardTitleFirst: {
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize" as "capitalize",
    marginTop: '15px',
    fontFamily: 'ClotherBold',
  },
  pointsCard: {
    borderRadius: '8px',
    border: '1px solid #DCDCDC',
    background: 'white'
  },
  karmaPointsCardRow: {
    padding: '10px 20px',
    borderBottom: '1px solid lightgray',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  karmaPointsTitle: {
    color: "#2B2A29",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: 'ClotherRegular',
  },
  karmaPointsDate: {
    color: "#A1A1A1",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    fontFamily: 'ClotherRegular',
  },
  karmaPointsCount: {
    color: "#21C274",
    textAlign: "right" as "right",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    fontFamily: 'ClotherBold',
  },
  rewandsCount: {
    color: "#F30202",
    textAlign: "right" as "right",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    fontFamily: 'ClotherBold',
  },
  avtarImg: {
    width: '100%'
  },
  starValue: {
    color: "#2B2A29",
    textAlign: "center" as "center",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    textTransform: "capitalize" as "capitalize",
    position: 'absolute' as 'absolute',
    top: '37px',
    right: '37px',
    fontFamily: 'ClotherBold',
  }
};
// Customizable Area End
