import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");

interface RiskRecordItem {
  id: number | null;
  title: string;
  type: string;
  level?: string;
  description?: string;
  name: string;
  created_at?: string;
  updated_at?: string;
  levelCreated?: boolean;
}

interface RiskDataItem {
  title: string;
  home: RiskRecordItem[];
  road: RiskRecordItem[];
  work: RiskRecordItem[];
}

interface CurrentRiskLevel {
  title: string,
  levels: RiskRecordItem[],
}
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  activeStep: () => void;
  privousStep: () => void;
  gotoLast: boolean;
  notGoToPrevious: () => void;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  stepValue: number;
  isLoading: boolean;
  riskLevels: RiskDataItem[];
  currentRiskLevel: CurrentRiskLevel;
  selectedLevel: number;
  progressPercentage: number;
  levelIds: number[];
  totalLevels: number;
  visibleLevel: number;
  riskLevelsCreated: boolean;
  levelSelectError: boolean;
  // Customizable Area End
}
interface SS { }

export default class RiskLevelsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getRiskLevelsCallId: string = "";
  getAccountInfoCallID: string = "";
  createRiskLevelCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      stepValue: 1,
      isLoading: false,
      riskLevels: [],
      currentRiskLevel: { title: '', levels: [] },
      selectedLevel: 0,
      progressPercentage: 0,
      levelIds: [],
      totalLevels: 0,
      visibleLevel: 0,
      riskLevelsCreated: false,
      levelSelectError: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getRiskLevelsData();

    if (this.props.gotoLast) {
      this.setState({ stepValue: 3 });
      this.props.notGoToPrevious();
    }

    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.getRiskLevelsCallId) {
          if (webResponseJson.all_risk_levels && webResponseJson.selected_risk_levels) {

            const allRisksTitleWiseData: RiskDataItem[] = await this.destructureRiskApiData(webResponseJson.all_risk_levels);
            const selectedRisksTitleWiseData: RiskDataItem[] = await this.destructureRiskApiData(webResponseJson.selected_risk_levels);
            const updatedAllDataTitleWise: RiskDataItem[] = this.addLevelCreatedFlag(allRisksTitleWiseData, selectedRisksTitleWiseData);

            this.setState({
              isLoading: false,
              riskLevels: updatedAllDataTitleWise,
            }, () => {
              this.setCurrentRiskLevel(this.state.riskLevels[0]);
            });

          } else {
            this.setState({ isLoading: false });
          }
        }

        if (webApiRequestCallId === this.createRiskLevelCallId) {
          if (webResponseJson.risk_levels) {
            this.setState({ isLoading: false, riskLevelsCreated: true });
          } else {
            this.setState({ isLoading: false });
          }
        }
      } else {
        this.setState({ isLoading: false });
      }

    }
    // Customizable Area End
  }
  // Customizable Area Start
  setNextStepValue = () => {
    this.setState((prevState) => ({
      stepValue: prevState.stepValue + 1,
      currentRiskLevel: { title: '', levels: [] }
    }), () => {
      this.setCurrentRiskLevel(this.state.riskLevels[0]);
    });
  };

  setPreviousStepValue = () => {
    this.setState((prevState) => ({
      stepValue: prevState.stepValue - 1,
      currentRiskLevel: { title: '', levels: [] }
    }), () => {
      this.setCurrentRiskLevel(this.state.riskLevels[0]);
    });
  };

  previousStep = () => {
    const { riskLevels, currentRiskLevel } = this.state;
    const index = riskLevels.findIndex(item => item.title === currentRiskLevel.title);
    if (index > 0 && index <= riskLevels.length - 1) {
      this.setCurrentRiskLevel(riskLevels[index - 1]);
    } else {
      if (this.state.stepValue === 1) {
        this.props.privousStep();
      } else {
        this.setPreviousStepValue();
      }
    }
  };

  nextStep = () => {
    const { riskLevels, currentRiskLevel, selectedLevel, levelIds } = this.state;
    const index = riskLevels.findIndex(item => item.title === currentRiskLevel.title);

    let isLevelSelected = false;
    currentRiskLevel.levels.forEach((level: any) => {
      if (level.id === selectedLevel || levelIds.includes(level.id)) {
        isLevelSelected = true;
      }
    });

    if (!isLevelSelected) {
      this.setState({ levelSelectError: true });
      return false;
    } else {
      this.setState({ levelSelectError: false });
    }

    if (index >= 0 && index < riskLevels.length - 1) {
      this.handleCreateRiskLevel(selectedLevel);
      this.setCurrentRiskLevel(riskLevels[index + 1]);
    } else {
      this.handleCreateRiskLevel(selectedLevel);
      if (this.state.stepValue === 3) {
        const requestBody = {
          risk_level_ids: this.state.levelIds
        }
        this.createRiskLevelApiCall(requestBody);
      } else {
        this.setNextStepValue();
      }
    }
  };

  getRiskLevelsData = async () => {
    this.setState({ isLoading: true });
    let tokenValue = localStorage.getItem("authToken");
    const headers = { token: tokenValue };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRiskLevelsCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRisksLevelEndpoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiMethodType
    );

    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  };

  setCurrentRiskLevel = (riskLevel: RiskDataItem) => {
    const { stepValue } = this.state;
    type SectionKey = 'home' | 'road' | 'work';
    let riskType: SectionKey = 'home';

    if (stepValue === 2) {
      riskType = 'road';
    }
    if (stepValue === 3) {
      riskType = 'work';
    }
    const displayData = {
      title: riskLevel.title,
      levels: riskLevel[riskType],
    }
    this.setState({ currentRiskLevel: displayData }, () => {
      this.getStepsProgressPercentage();
    });
  }

  selectLevel = (id: number) => {
    const { currentRiskLevel, riskLevels, levelIds } = this.state;
    let updatedCurrentRiskLevel = {
      title: currentRiskLevel.title,
      levels: currentRiskLevel.levels.map((level: any) => {
        return { ...level, levelCreated: false };
      })
    }
    updatedCurrentRiskLevel.levels.forEach((level: any) => {
      if (level.id === id) {
        level.levelCreated = true;
      }
    });
    const updatedLevelIds = levelIds.filter(id =>
      !currentRiskLevel.levels.some((level: any) => level.id === id)
    );
    const updatedDataWithFalseFlag = this.markLevelCreatedFalseForId(riskLevels, id);
    this.setState({
      currentRiskLevel: updatedCurrentRiskLevel,
      selectedLevel: id,
      riskLevels: updatedDataWithFalseFlag,
      levelIds: updatedLevelIds,
      levelSelectError: false,
    });
  }

  changeRiskCategory = (step: number) => {
    this.setState({
      stepValue: step
    }, () => {
      this.setCurrentRiskLevel(this.state.riskLevels[0]);
    });
  }

  getStepsProgressPercentage = () => {
    const { riskLevels, currentRiskLevel, stepValue } = this.state;
    const categories = 3;
    const riskLevelsPerCategory = riskLevels.length;
    const totalLevels = riskLevelsPerCategory * categories;
    const index = riskLevels.findIndex(item => item.title === currentRiskLevel.title) + 1;
    const visibleLevel = (stepValue * categories) - (categories - index);
    const percentage = visibleLevel * 100 / totalLevels;
    this.setState({ progressPercentage: percentage, totalLevels, visibleLevel });
  }

  handleCreateRiskLevel = (id: number) => {
    const { levelIds } = this.state;
    if (id) {
      let riskLevelIds = levelIds;
      riskLevelIds.push(id);
      this.setState({ levelIds: riskLevelIds });
    }
  }

  createRiskLevelApiCall = async (payload: any) => {
    this.setState({ isLoading: true });
    let token = await getStorageData("authToken", false);
    const headers = {
      "Content-Type": configJSON.postsContentType,
      token
    };

    const requestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createRiskLevelCallId = requestMsg.messageId;
    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postsApiPostMethodType
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createRiskLevelEndpoint
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  }

  destructureRiskApiData = (riskData: any) => {
    const levelOrder = ['Level 1', 'Level 2', 'Level 3'];
    const riskLevels = [...riskData.Home, ...(riskData.Road || riskData.Raod), ...riskData.Work];
    let titleWiseData = [];

    titleWiseData = riskLevels.reduce((acc, curr) => {
      let levelGroup = acc.find((item: any) => item.title === curr.title);

      if (levelGroup) {
        if (curr.type === 'Home') levelGroup.home.push(curr);
        if (curr.type === 'Road') levelGroup.road.push(curr);
        if (curr.type === 'Work') levelGroup.work.push(curr);
      } else {
        acc.push({
          title: curr.title,
          home: curr.type === 'Home' ? [curr] : [],
          road: curr.type === 'Road' ? [curr] : [],
          work: curr.type === 'Work' ? [curr] : [],
        });
      }

      return acc;
    }, []);

    const sortAndFillLevels = (typeArray: any) => {
      const sorted = levelOrder.map(level => typeArray.find((item: any) => item.name === level) || { id: null, title: '', type: '', level });
      return sorted;
    };

    titleWiseData.forEach((group: any) => {
      group.home = sortAndFillLevels(group.home);
      group.road = sortAndFillLevels(group.road);
      group.work = sortAndFillLevels(group.work);
    });

    return titleWiseData;
  }

  isRecordInSelectedData = (record: RiskRecordItem, selectedSection: RiskRecordItem[]): boolean => {
    if (record.id === null) return false;
    const isMatch = selectedSection.some(selectedRecord => selectedRecord.id === record.id);
    if (isMatch) {
      this.handleCreateRiskLevel(record.id);
    }
    return isMatch;
  }

  addLevelCreatedFlag = (allData: RiskDataItem[], selectedData: RiskDataItem[]): RiskDataItem[] => {
    return allData.map(allItem => {
      // Find the corresponding item in selectedData
      const selectedItem = selectedData.find(sItem => sItem.title === allItem.title);

      if (selectedItem) {
        return {
          ...allItem,
          home: allItem.home.map(homeRecord => ({
            ...homeRecord,
            levelCreated: this.isRecordInSelectedData(homeRecord, selectedItem.home),
          })),
          road: allItem.road.map(roadRecord => ({
            ...roadRecord,
            levelCreated: this.isRecordInSelectedData(roadRecord, selectedItem.road),
          })),
          work: allItem.work.map(workRecord => ({
            ...workRecord,
            levelCreated: this.isRecordInSelectedData(workRecord, selectedItem.work),
          })),
        };
      }

      // If no corresponding item is found in selectedData, mark all records as levelCreated: false
      return {
        ...allItem,
        home: allItem.home.map(homeRecord => ({ ...homeRecord, levelCreated: false })),
        road: allItem.road.map(roadRecord => ({ ...roadRecord, levelCreated: false })),
        work: allItem.work.map(workRecord => ({ ...workRecord, levelCreated: false })),
      };
    });
  }

  getLevelActiveStatus = (levelId: number, isLevelCreated: boolean): boolean => {
    const { selectedLevel } = this.state;
    if (selectedLevel === levelId) {
      return true;
    }
    if (isLevelCreated) {
      return true;
    }
    return false;
  }

  markLevelCreatedFalseForId = (updatedAllData: RiskDataItem[], idToMatch: number): RiskDataItem[] => {
    return updatedAllData.map(item => {
      // Check if the idToMatch exists in any object of home, road, or work
      const homeMatch = item.home.some(record => record.id === idToMatch);
      const roadMatch = item.road.some(record => record.id === idToMatch);
      const workMatch = item.work.some(record => record.id === idToMatch);

      return {
        ...item,
        home: item.home.map(record => ({
          ...record,
          levelCreated: homeMatch ? false : record.levelCreated,
        })),
        road: item.road.map(record => ({
          ...record,
          levelCreated: roadMatch ? false : record.levelCreated,
        })),
        work: item.work.map(record => ({
          ...record,
          levelCreated: workMatch ? false : record.levelCreated,
        })),
      };
    });
  }
  // Customizable Area End
}
