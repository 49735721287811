import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  styled,
  Modal,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { Like, View, Close, addUserStory, closeIcon, mic, micStart, upload, plusIcon } from "./assets"
import { webStyles } from "../../../components/src/styles";
import Stories from 'react-insta-stories';

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
      color: '#A1A1A1'
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import Story2Controller, {
  Props,
  configJSON,
} from "./Story2Controller";

export default class Story2 extends Story2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  storySlider = () => {
    const { loading, storyConfig, viewedStories, storySliderData } = this.state;

    const width = storyConfig.sliderWidth ? `${storyConfig.sliderWidth}px` : "100%";

    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="white"
        p={2}
        borderRadius={8}
        boxShadow={1}
        mt={2}
      >
        <Box className="storySliderBox">
          <p style={{ ...webStyles.basicHeader, margin: 0, paddingBottom: 3 }}>Stories</p>
          <p style={{ margin: 0 }}>
            <KeyboardArrowLeft
              className="leftArrowStyle"
              onClick={this.handlePrevious}
              data-test-id="previous" />
            <KeyboardArrowRight
              className="rightArrowStyle"
              data-test-id="next"
              onClick={this.handleNext} />
          </p>
        </Box>
        <Box style={{ display: "flex" }}>
          {!loading && this.getPlusIconVisible() < 0 && <Box className="addStory">
            <img src={addUserStory} alt="Add Story" data-test-id="add_own_story" onClick={this.handleCreateOwnStoryModal} className="cursorPointer" />
          </Box>}
          <Box className={this.getPlusIconVisible() > -1 ? "storySlider" : "storySliderWithPlus"}>
            <div ref={this.state.customSliderRef} style={{
              overflow: "hidden", margin: 0, padding: 0,
              width: `100%`
            }}>
              <Box style={{
                minWidth: `${storySliderData.length * storyConfig.width}px`,
                width: "100%",
                transition: `transform ${storyConfig.speed}ms ease-in-out`,
                transform: `translate3d(${storyConfig.transformPosition}px, 0, 0)`
              }}>
                {storySliderData.map((value, index) => {
                  const image = value.attributes;
                  const viewedClass = viewedStories.includes(value.id) ? "viewedImage" : "notViewedImage";

                  return (
                    <div
                      style={{
                        width: `${storyConfig.width}px`,
                        display: "inline-block"
                      }}
                      data-test-id="slider_image"
                      key={index}
                      onClick={() => this.handleStoryImageClick(value.attributes.account_id)}>
                      <div className="sliderImageContainer"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}>
                        <Typography className={`imageDetailTypo ${viewedClass}`}>
                          <img
                            src={this.getProfileImageList(value)}
                            alt={image?.title}
                            className="detailImageStyle" />
                          {this.getPlusIconVisible() === index && <img
                            onClick={this.handleCreateOwnStoryModal}
                            src={plusIcon}
                            alt={"own image"}
                            width={14}
                            height={14}
                            className="plusIconPosition"
                          />}
                        </Typography>
                      </div>
                    </div>
                  )
                }
                )}
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    )
  }

  createOwnStory = () => {
    const {
      openStoryModal,
      storyTitle,
      listening,
      storyDescription,
      referenceImage,
      uploadedImagePreview,
      errorTitle,
      errorDescription,
      clickPost,
      disablePostButton,
      errorMessageReferenceImage
    } = this.state;

    return (
      <Modal open={openStoryModal} onClose={this.closeStoryModal}>
        <StoryModal>
          <Box className="story-modal-header">
            <Typography>Story Creation</Typography>
            <img
              onClick={this.closeStoryModal}
              data-test-id="close_story_modal"
              className="cursorPointer"
              alt="close"
              src={closeIcon}
            />
          </Box>

          <Box className="story-modal-body">
            <Grid container className="mainGrid">

              <Grid className="inputBlock" xs={12}>
                <Typography className="storyInputLabel">Title</Typography>
                <Input
                  onChange={this.handleChangeStoryTitle}
                  value={storyTitle}
                  data-test-id="story_title"
                  className="enterInputField"
                  placeholder="Title"
                  autoComplete="off"
                  disableUnderline
                />
                <Typography className="errorStyle">
                  {errorTitle.length > 0 && errorTitle}
                  {clickPost && !storyTitle && configJSON.errorMsg}
                </Typography>
              </Grid>

              <Grid className="inputBlock" xs={12}>
                <Box className="descriptionMicContainer">
                  <Typography className="storyInputLabel">Brief Description</Typography>
                  <img
                    className="descMicIcon"
                    src={listening ? micStart : mic}
                    onClick={(storyDescription) => { this.handleMicForStoryDescription(storyDescription) }}
                    data-test-id="description_speechtext"
                  />
                </Box>
                <textarea
                  value={storyDescription}
                  data-test-id="story_description"
                  className="storyTextarea"
                  onChange={this.handleChangeStoryDesc}
                  placeholder="Brief Description"
                />
                <Typography className="errorStyle">
                  {errorDescription.length > 0 && errorDescription}
                  {clickPost && !storyDescription && configJSON.errorMsg}
                </Typography>
              </Grid>

              <Grid className="inputBlock" xs={12}>
                <Typography className="storyInputLabel">Related Image</Typography>
                <Button component="label" className="storyUploadImage">
                  <Box className="uploadImageFontBox">
                    <div className="upload-img">
                      <img
                        alt="upload image"
                        className="storyUploadedImg"
                        src={uploadedImagePreview || upload}
                      />
                    </div>
                    {!referenceImage && (
                      <>
                        <div className="imageFont">Upload Image</div>
                      </>
                    )}
                  </Box>
                  <input
                    accept="image/png, image/jpeg"
                    type="file"
                    name="image"
                    data-test-id="upload_relatedImage"
                    onChange={(event) => {
                      this.handleImageChange(event);
                    }}
                  />
                </Button>
                <Typography className="errorStyle">
                  {errorMessageReferenceImage.length > 0 && errorMessageReferenceImage}
                  {clickPost && !referenceImage && errorMessageReferenceImage.length === 0
                    && configJSON.errorMsg}
                </Typography>
              </Grid>

            </Grid>

            <Box className="button-wrapper">
              <Button
                onClick={this.postStory}
                variant="contained"
                data-test-id="post_story"
                className="post-btn"
                disabled={disablePostButton}
              >
                Post
              </Button>
            </Box>
          </Box>
        </StoryModal>
      </Modal>
    )
  }

  storyDetails = () => {
    const { visibleStory, currentStoriesData } = this.state;
    return (
      <Box className="largeImageWrapper">
        <Box className="storyWrapper">
          {currentStoriesData.length > 0 &&
            <Stories
              data-test-id="stories-block"
              stories={this.getStoryItems()}
              defaultInterval={5000}
              width={435}
              height={812}
              keyboardNavigation={true}
              onStoryStart={this.handleStoryStart}
              onStoryEnd={this.handleStoryEnd}
              progressContainerStyles={
                {
                  background: 'transparent',
                  filter: 'none',
                  paddingTop: '20px',
                }
              }
              progressWrapperStyles={
                {
                  background: '#EEEEEE',
                  height: '3px',
                }
              }
              progressStyles={
                {
                  background: '#8735E1',
                  height: '3.1px',
                }
              }
              storyStyles={
                {
                  width: '100%',
                  height: '100%',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  margin: 'auto',
                  objectFit: 'cover',
                }
              }
            />
          }
        </Box>
        <Box className="detailRightImgMainBox">
          <Box className="detailRightImgInnerBox">
            <Box
              className="closeDetail"
              onClick={this.closeStoryDetail}
              data-test-id="close_image"
            >
              <img
                alt="Close"
                src={Close}
                width="20px"
                height="20px"
              />
            </Box>
            <Box style={{ marginTop: "8px" }}>
              <img
                className="cursorPointer"
                alt="Like"
                src={Like}
                width="35px"
                height="35px"
              />
            </Box>
            <Box className="viewBox">
              <img
                className="viewImage"
                src={View}
                height="16px"
                width="16px"
                alt="View"
              />
              <Typography className="viewFont">{visibleStory?.views || 0}</Typography>
            </Box>
          </Box>
        </Box>
        <Box style={webStyle.footerStyle}>
          {this.getFooter()}
        </Box>
        <Box style={webStyle.errorMessage}>
          {this.loadErrorMessage()}
        </Box>
        <Box style={webStyle.imageErrorMessage}>
          {this.loadImageErrorMessage()}
        </Box>
      </Box>
    )
  }

  getStoryItems = () => {
    const { currentStoriesData } = this.state;
    let stories: any = [];
    currentStoriesData.forEach((story) => {
      stories.push(
        {
          content: () => {
            return (
              <Box data-test-id={`story-item-${stories.length}`} style={{ width: '100%', height: '100%', position: 'relative' }}>
                <img style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center' }}
                  src={story?.story_image} />
                <Box className="imageMainBox">
                  <Box className="imageInnerBox1">
                    <Box className="imageInnerBox2">
                      <img
                        width={44}
                        height={44}
                        className="profileImage"
                        src={this.getProfileImage(story)}
                      />
                    </Box>
                    <Box className="imageInnerBox3">
                      <Typography className="profileNameFontStyle">{this.getProfileName(story)}</Typography>
                      <Typography className="createdTimeFontStyle">{this.formatTimeAgo(story?.created_at)}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box className="titleDescMainBox">
                  <Box className="titleBox">{story.title.length > 40 ? story.title.substring(0, 40) : story.title}</Box>
                  <Box className="titleDescBox">
                    {story.description.length > 40 ? (
                      <>
                        {this.state.isExpanded ? story.description : `${story.description.substring(0, 40)}...`}
                        <span
                          data-test-id="read_more"
                          onClick={this.handleReadMoreClick}
                          className="readMoreText"
                        >
                          {this.state.isExpanded ? 'Read Less' : 'Read More'}
                        </span>
                      </>
                    ) : (
                      story.description
                    )}
                  </Box>
                </Box>
              </Box>
            );
          },
        }
      );
    });
    return stories;
  }

  getFooter = () => {
    return (
      <Box>
        <Typography component={'p'}>
          {configJSON.noMoreStoriesMessage}
        </Typography>
      </Box>
    );
  }

  loadErrorMessage = () => {
    return (
      <Box>
        <Typography component={'p'}>
          {configJSON.storyErrorMEssage}
        </Typography>
      </Box>
    );
  }

  loadImageErrorMessage = () => {
    return (
      <Box>
        <Typography component={'span'}>
          {configJSON.imageErrorMEssage}
        </Typography>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <MainContainer>
          {this.storySlider()}
          {this.state.showStories && this.storyDetails()}
          {this.createOwnStory()}
        </MainContainer>
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const StoryModal = styled(Box)({
  maxWidth: "720px",
  width: "80%",
  height: "auto",
  overflow: "auto",
  background: "#fff",
  maxHeight: "100%",
  borderRadius: "8px",
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowX: "hidden",
  overflowY: "hidden",
  transform: "translate(-50%, -50%)",
  "& .story-modal-header": {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 30px 0px",
    alignItems: "center",
    "& p": {
      color: "#2B2A29",
      fontFamily: "ClotherBold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      textTransform: "capitalize",
    },
  },
  "& .story-modal-body": {
    padding: "15px 30px 15px 30px",
    maxHeight: "100%",
    overflow: "auto"
  },
  "& .descriptionMicContainer": {
    display: "flex",
    justifyContent: "space-between"
  },
  "& .cursorPointer": {
    cursor: "pointer"
  },
  "& .storyMainBox": {
    backgroundColor: "white",
    width: "100%",
    margin: "15px 0px"
  },
  "& .inputBlock": {
    maxWidth: '102%',
    marginBottom: "10px"
  },
  "& .storyInputLabel": {
    fontSize: '18px',
    fontWeight: 600,
    color: '#2B2A29',
    fontFamily: 'ClotherRegular',
    marginBottom: '5px'
  },
  "& .enterInputField": {
    fontFamily: 'ClotherRegular',
    backgroundColor: 'white',
    border: '1px solid lightgray',
    fontSize: '18px',
    borderRadius: '8px',
    height: '54px',
    padding: '10px 15px',
    width: '100%'
  },
  "& .MuiInputBase-root": {
    fontSize: "18px",
  },
  "& .storyTextarea": {
    borderRadius: '8px',
    fontFamily: 'ClotherRegular',
    backgroundColor: 'white',
    border: '1px solid lightgray',
    userDrag: 'none',
    resize: 'none',
    fontSize: '18px',
    fontWeight: 400,
    height: '176px',
    padding: '10px 15px',
    width: '100%'
  },
  "& .descMicIcon": {
    width: "25px",
    height: "25px",
    cursor: "pointer"
  },
  "& .storyUploadImage": {
    backgroundColor: 'white',
    height: '170px',
    borderRadius: '8px',
    textTransform: 'capitalize',
    border: '1px solid lightgray',
    fontFamily: 'ClotherRegular',
    padding: '10px 15px',
    width: '100%',
    "& .MuiButton-label": {
      diplay: 'flex',
      flexDirection: 'column',
    },
  },
  "& .storyUploadedImg": {
    maxWidth: '5rem',
    maxHeight: '5rem',
    objectFit: 'contain',
  },
  "& .imageFont": {
    fontSize: '16px',
    fontFamily: 'ClotherRegular',
    color: 'lightgray',
  },
  "& .upload-img": {
    display: 'flex',
    flexDirection: 'column'
  },
  "& .button-wrapper": {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    padding: "20px 0px 10px 30px",
    boxSizing: "border-box",
  },
  "& .post-btn": {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "ClotherRegular",
    borderRadius: "8px",
    background: "#8735E1",
    width: "160px",
    height: "50px",
    padding: "10px",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    boxShadow: "none",
  },
  "& *::-ms-input-placeholder": {
    color: "#A8A0A0 !important",
    fontSize: "18px"
  },
  "& *::placeholder": {
    color: "#DCDCDC !important",
    fontSize: "18px",
    fontWeight: 400
  },
  "& input::placeholder": {
    color: "#A8A0A0 !important",
    fontSize: "18px"
  },
  "& .uploadImageFontBox": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  "& .errorStyle": {
    color: "#F30202",
    fontSize: "0.85rem",
    marginBottom: "0.5rem",
  }
})
const MainContainer = styled(Box)({
  width: '-webkit-fill-available',
  "& .largeImageWrapper": {
    margin: "30px 0px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  "& .storyWrapper": {
    overflow: 'hidden',
    borderRadius: '10px',
    height: '100%',
  },
  "& .largeImageWrapperContent": {
    display: "flex",
    justifyContent: "center"
  },
  "& .storyImageModal": {
    maxWidth: "400px",
    width: "50vw",
    border: "1px solid black",
    height: "500px",
    display: "flex",
    borderRadius: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .topGradientStyle": {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '16%',
    right: 0,
    transition: "opacity 0.3s ease-out",
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
  },
  "& .bottomGradientStyle": {
    position: 'absolute',
    left: 0,
    bottom: 0,
    height: '18%',
    right: 0,
    transform: 'translateZ(0)',
    transition: "opacity 0.3s ease-out",
    backgroundImage: 'linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
  },
  "& .storyImageModalImg": {
    maxWidth: "95%",
    maxHeight: "95%",
  },
  "& .progressBox": {
    position: "absolute",
    left: "20px",
    right: "20px",
    top: "10px"
  },
  "& .progressBoxInner": {
    display: "flex",
    flexDirection: "row",
    gap: "1%"
  },
  "& .imageMainBox": {
    position: "absolute",
    top: "0px",
    color: "white",
    width: "100%",
    height: '200px',
    padding: "35px 10px 5px 10px",
    backgroundImage: 'linear-gradient(#898989cf, #ffffff00)',
  },
  "& .imageInnerBox1": {
    marginLeft: "10px",
    display: "flex",
    gap: "10px"
  },
  "& .imageInnerBox2": {
    display: "inline",
    marginTop: "7px"
  },

  "& .imageInnerBox3": {
    display: "inline"
  },
  "& .profileImage": {
    borderRadius: "50%",
  },
  "& .titleDescMainBox": {
    position: "absolute",
    bottom: 0,
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    color: "white",
    wordBreak: "break-word",
    padding: "0px 10px",
    width: "100%",
    height: '160px',
    backgroundImage: 'linear-gradient(#ffffff00, #000)',
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    zIndex: 9999,
  },
  "& .titleBox": {
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    fontWeight: 700,
    marginLeft: "10px"
  },
  "& .titleDescBox": {
    marginLeft: "10px",
    paddingBottom: "10px",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontWeight: 400
  },
  "& .profileNameFontStyle": {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "ClotherRegular",
    color: '#fff',
  },
  "& .cursorPointer": {
    cursor: "pointer"
  },
  "& .createdTimeFontStyle": {
    fontSize: "16px",
    fontWeight: 400,
    fontFamily: "ClotherRegular",
    color: '#fff',
  },
  "& .viewFont": {
    position: "absolute",
    marginTop: "20px",
    fontFamily: "ClotherRegular",
    fontSize: "16px"
  },
  "& .addStory": {
    width: "52px",
    marginRight: "10px"
  },
  "& .storySlider": {
    width: "100%"
  },
  "& .storySliderWithPlus": {
    width: "calc(100% - 60px)"
  },
  "& .plusIconPosition": {
    position: "absolute",
    top: 36,
    left: 38,
    cursor: "pointer"
  },
  "& .viewImage": {
    cursor: "pointer",
    position: "absolute",
    marginTop: "5px"
  },
  "& .viewBox": {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginTop: "8px",
    borderRadius: "20px",
    border: "1px solid #8735E1",
    height: "52px",
    width: "35px"
  },
  "& .closeDetail": {
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    backgroundColor: "#DCDCDC",
    cursor: "pointer"
  },
  "& .storySliderBox": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #eee",
    margin: "-12px -1rem 0.75rem",
    padding: "0.3rem 1rem",
  },
  "& .leftArrowStyle": {
    cursor: "pointer", width: "24px",
    height: "24px",
    border: "1px solid lightgray",
    color: "lightgray",
    marginRight: "10px",
    borderRadius: "50%"
  },
  "& .rightArrowStyle": {
    cursor: "pointer", width: "24px",
    height: "24px",
    border: "1px solid lightgray",
    color: "lightgray",
    borderRadius: "50%"
  },
  "& .sliderImageContainer": {
    display: "flex",
    justifyContent: "flex-start"
  },
  "& .imageDetailTypo": {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    position: "relative"
  },
  "& .notViewedImage": {
    border: "2px solid #8735E1"
  },
  "& .viewedImage": {
    border: "2px solid black"
  },
  "& .readMoreText": {
    color: 'white',
    cursor: 'pointer',
    marginLeft: '5px',
    fontFamily: 'ClotherRegular',
    fontWeight: 700
  },
  "& .detailImageStyle": {
    width: "48px",
    height: "48px",
    border: "1px solid white",
    borderRadius: "50%",
    cursor: "pointer",
  },
  "& .detailRightImgMainBox": {
    position: "relative"
  },
  "& .detailRightImgInnerBox": {
    position: 'absolute',
    left: '10px'
  }
});
const webStyle = {
  footerStyle: {
    align: 'center',
    fontSize: '12px',
    color: '#transparent',
    display: 'none',
    fontWeight: 600,
    lineheight: '22px',
  },
  errorMessage: {
    fontSize: '16px',
    display: 'none',
    fontWeight: 600,
    background: 'transparent',
  },
  imageErrorMessage: {
    fontSize: '14px',
    color: '#000',
    fontWeight: 600,
    display: 'none',
    background: 'transparent',
  }
};
// Customizable Area End