Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.getMyPostsEndpoint = "bx_block_posts/posts/my_posts";
exports.safetyCouncilText = "Safety Council";
exports.createdText = "Created this";
exports.assignedToText = "Assigned to";
exports.noLocationText = "No Location";
exports.noDataText = "No Data";
exports.PostText = "Post";
exports.FormText = "Form";
exports.ProceedToAssignText = "Next";
exports.StatutoryConcernText = "Statutory / Regulatory / Legal Concern";
exports.StandardComplianceText = "Standard Compliance - SOP Compliance";
exports.NoViolationText = "No Go Parameter Violation";
exports.SafetyAbsoluteViolationText = "Safety Absolute Violation";
exports.CorrectiveActionText = "Corrective Action";
exports.PreventiveActionText = "Preventive Action";
exports.RiskCountText = "Risk Count";
exports.NextBtnText = "Next";
exports.getPostAPIEndPoint = "/bx_block_posts/action_trackers/safety_council_data";
exports.postsApiMethodType = "GET";
exports.getPostDataAPIEndPoint = "/bx_block_posts/action_trackers/post_data";
exports.getCouncilDataAPIEndPoint = "/bx_block_posts/action_trackers/post_safety_council_data";
exports.getLocationsApiEndPoint = "/bx_block_surveys/inspections/load_locations";
exports.getDepartmentsApiEndPoint = "/bx_block_surveys/inspections/load_departments";
exports.getApiMethodType = "GET";

exports.getAnalyticsAssignedByAndToApiEndPoint = "bx_block_posts/action_trackers/load_assigned_by_and_assigned_to";
exports.getAnalyticsAllApiEndPoint = "bx_block_posts/action_trackers/select_work_locations";
exports.getAnalyticsDepartmentsApiEndPoint = "bx_block_posts/action_trackers/select_designations";
exports.getAnalyticsPlantsApiEndPoint = "bx_block_posts/action_trackers/select_bus";
exports.getAnalyticsCitiesApiEndPoint = "bx_block_posts/action_trackers/select_plants";
exports.getAnalyticsCountryApiEndPoint = "bx_block_posts/action_trackers/select_countries";
exports.getAnalyticsActionsApiEndPoint = "bx_block_posts/action_trackers/load_counts_and_actions";

// Customizable Area End