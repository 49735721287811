import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config.js");
export interface ProfileData {
  attributes: {
    full_name: string;
    function_name: string;
    work_location: string;
    date_of_birth: string;
    role_name: string;
    employee_id: string;
    profile_image: string;
  }
}

export interface RiskRecordItem {
  id: number | null;
  title: string;
  type: string;
  level?: string;
  description?: string;
  name: string;
  created_at?: string;
  updated_at?: string;
  levelCreated?: boolean;
}

export interface RiskDataItem {
  title: string;
  home: RiskRecordItem[];
  road: RiskRecordItem[];
  work: RiskRecordItem[];
}
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  closeModel?: any;
  logoutClear?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  type: string;
  token: string;
  ProfileData: ProfileData;
  isLoading: boolean;
  currentPage: number,
  userId: number;
  totalCount: number;
  GoalsRank: object[];
  riskLevelRange: string[];
  riskProfileGoals: Array<any>;
  riskLevels: Array<any>;
  riskLevelsNotCreated: boolean;
  currentRiskLevelId: string;
  // Customizable Area End
}
interface SS { }

export default class RiskCardProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProfileDetailsAPICallID: string = "";
  getRiskDetailsAPICallID: string = "";
  getRiskProfileGoalsCallId: string = "";
  getRiskLevelsCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      type: "",
      token: "",
      ProfileData: {
        attributes: {
          full_name: "",
          function_name: "",
          work_location: "",
          date_of_birth: "",
          role_name: "",
          employee_id: "",
          profile_image: "",
        }
      },
      isLoading: false,
      currentPage: 1,
      userId: 0,
      totalCount: 0,
      GoalsRank: [
        { month: "January", letter: "J" },
        { month: "February", letter: "F" },
        { month: "March", letter: "M" },
        { month: "April", letter: "A" },
        { month: "May", letter: "M" },
        { month: "June", letter: "J" },
        { month: "July", letter: "J" },
        { month: "August", letter: "A" },
        { month: "September", letter: "S" },
        { month: "October", letter: "O" },
        { month: "November", letter: "N" },
        { month: "December", letter: "D" },
      ],
      riskLevelRange: ['1', '2', '3'],
      riskProfileGoals: [],
      riskLevels: [],
      riskLevelsNotCreated: true,
      currentRiskLevelId: '',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let SignUpID = JSON.parse(await getStorageData("SignUpID"));
    this.setState({ userId: SignUpID })
    this.getRiskDetailsAPI();
    this.getProfileDetails();
    this.getRiskProfileGoals();
    this.getRiskLevels();
    // Customizable Area End
  }

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.getProfileDetailsAPICallID) {
          if (webResponseJson.data) {
            this.setState({
              isLoading: false,
              ProfileData: webResponseJson.data,
            });
          } else {
            this.setEmptyData()
            this.setState({ isLoading: false });
          }
        }

        if (webApiRequestCallId === this.getRiskProfileGoalsCallId) {
          if (webResponseJson?.goals?.data) {
            this.setState({
              isLoading: false,
              riskProfileGoals: webResponseJson.goals.data,
            });
          } else {
            this.setState({ isLoading: false });
          }
        }

        if (webApiRequestCallId === this.getRiskLevelsCallId) {
          if (webResponseJson.all_risk_levels && webResponseJson.selected_risk_levels) {

            const selectedRisksTitleWiseData: RiskDataItem[] = await this.riskResponeMapping(webResponseJson.selected_risk_levels);
            const allRisksTitleWiseData: RiskDataItem[] = await this.riskResponeMapping(webResponseJson.all_risk_levels);
            const updatedAllDataTitleWise: RiskDataItem[] = this.updateLevelCreatedFlag(allRisksTitleWiseData, selectedRisksTitleWiseData);

            this.setState({
              isLoading: false,
              riskLevels: updatedAllDataTitleWise,
            });

          } else {
            this.setState({ isLoading: false });
          }
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  setEmptyData = () => {
    this.setState({
      ProfileData: {
        attributes: {
          full_name: "",
          function_name: "",
          work_location: "",
          date_of_birth: "",
          role_name: "",
          employee_id: "",
          profile_image: "",
        }
      }
    })
  }

  getRiskDetailsAPI = async () => {
    let token = await getStorageData("authToken", false);
    this.setState({ isLoading: true })
    const headers = {
      token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.RiskDetailsAPIEndPoint}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getRiskDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  getProfileDetails = async () => {
    let token = await getStorageData("authToken", false);
    this.setState({ isLoading: true })
    const headers = {
      token,
    };
    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.accountDetailsAPIEndPoint}${this.state.userId}`
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getProfileDetailsAPICallID = getAllRequestMsg.messageId;
    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  goToHome = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Dashboard");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  goToMyProfile = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "MyProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  }

  getRiskProfileGoals = async () => {
    let token = await getStorageData("authToken", false);
    this.setState({ isLoading: true });
    const headers = { token };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRiskProfileGoalsCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.riskProfileGoalsEndpoint
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  getRiskLevels = async () => {
    this.setState({ isLoading: true });
    let token = await getStorageData("authToken", false);
    const headers = { token };

    const getAllRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRiskLevelsCallId = getAllRequestMsg.messageId;
    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    getAllRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.riskLevelsEndpoint
    );

    runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
  }

  findRecordInSelectedData = (riskRecord: RiskRecordItem, selectedSectionData: RiskRecordItem[]): boolean => {
    if (riskRecord.id === null) return false;
    const isMatch = selectedSectionData.some(selectedRecord => selectedRecord.id === riskRecord.id);
    return isMatch;
  }

  riskResponeMapping = (riskData: any) => {
    const riskLevelsData = [...riskData.Home, ...(riskData.Road || riskData.Raod), ...riskData.Work];
    const levelsOrder = ['Level 1', 'Level 2', 'Level 3'];
    let titleBasedData = [];
    titleBasedData = riskLevelsData.reduce((acc, curr) => {
      let levelGroupData = acc.find((item: any) => item.title === curr.title);
      if (levelGroupData) {
        if (curr.type === 'Home') levelGroupData.home.push(curr);
        if (curr.type === 'Road') levelGroupData.road.push(curr);
        if (curr.type === 'Work') levelGroupData.work.push(curr);
      } else {
        acc.push({
          title: curr.title,
          home: curr.type === 'Home' ? [curr] : [],
          road: curr.type === 'Road' ? [curr] : [],
          work: curr.type === 'Work' ? [curr] : [],
        });
      }
      return acc;
    }, []);

    const sortLevels = (types: any) => {
      const sorted = levelsOrder.map(level => types.find((item: any) => item.name === level) || { id: null, title: '', type: '', level });
      return sorted;
    };
    titleBasedData.forEach((group: any) => {
      group.home = sortLevels(group.home);
      group.road = sortLevels(group.road);
      group.work = sortLevels(group.work);
    });

    return titleBasedData;
  }

  updateLevelCreatedFlag = (allData: RiskDataItem[], selectedData: RiskDataItem[]): RiskDataItem[] => {
    return allData.map(riskItem => {
      const selectedItem = selectedData.find(sItem => sItem.title === riskItem.title);
      if (selectedItem) {
        return {
          ...riskItem,
          home: riskItem.home.map(homeRecord => ({
            ...homeRecord,
            levelCreated: this.findRecordInSelectedData(homeRecord, selectedItem.home),
          })),
          road: riskItem.road.map(roadRecord => ({
            ...roadRecord,
            levelCreated: this.findRecordInSelectedData(roadRecord, selectedItem.road),
          })),
          work: riskItem.work.map(workRecord => ({
            ...workRecord,
            levelCreated: this.findRecordInSelectedData(workRecord, selectedItem.work),
          })),
        };
      }
      return {
        ...riskItem,
        home: riskItem.home.map(homeRecord => ({ ...homeRecord, levelCreated: false })),
        road: riskItem.road.map(roadRecord => ({ ...roadRecord, levelCreated: false })),
        work: riskItem.work.map(workRecord => ({ ...workRecord, levelCreated: false })),
      };
    });
  }
  // Customizable Area End
}
