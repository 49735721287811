import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";
import { ObjectSchema } from 'yup';
import { ChangeEvent } from 'react';
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";

export interface ScheduleInspectionData {
    equipmentName: string;
    equipmentNumber: string;
    location: string;
    department: string;
    machines: string;
    email: string;
    inspectionDate: Date | null;
    assignTo: {
        label: string;
        value: string;
    } | string;
    image1: File | string;
    image2: File | string;
}

interface AccountData {
    data: {
        attributes: {
            official_email: string;
        }
    }
}
interface PostInspectionResponseData {
    errors: string;
    data: {
        id: string;
        type: string;
        attributes: {
            account_id: number;
            equipment_name: string;
            equipment_number: string;
            location_id: string;
            machine_tool_id: string;
            department_id: string;
            email: string;
            inspection_date: string;
            assigned_to: string;
            assigned_to_id: string;
            supervisor_id: string;
            status: string;
            location: string;
            machine_tool: string;
            department: string;
            first_image: string;
            second_image: string;
        }
    }
}

interface MachinesData {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
    }
}
interface MachinesResponseData {
    errors: string;
    machine_tools: {
        data: MachinesData[];
    }
}
interface PersonListData {
    id: string;
    type: string;
    attributes: {
        full_name: string;
        employee_id: string;
    }
}
interface PersonListResponseData {
    errors: string;
    accounts: {
        data: PersonListData[];
    }
}
export interface PersonListOptions {
    label: string;
    value: string;
}
interface LocationsData {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        country_id: number;
    }
}
interface LocationResponseData {
    errors: string;
    locations: {
        data: LocationsData[];
    }
}
interface DepartmentsData {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
    }
}
interface DepartmentsResponseData {
    errors: string;
    departments: {
        data: DepartmentsData[];
    }

}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    token: string;
    loading: boolean;
    inputSchema: ObjectSchema<object>;
    errorMsg: string;
    accountData: string;
    assignPersonLoading: boolean;
    personList: PersonListData[];
    locations: LocationsData[];
    departments: DepartmentsData[];
    machines: MachinesData[];
    image1: File | ArrayBuffer | string;
    errorMessageImage1: string;
    image2: File | ArrayBuffer | string;
    errorMessageImage2: string;
    disableSubmit: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class ScheduleInspectionController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getAssignPersonApiCallId: string = "";
    searchPerson: NodeJS.Timeout | number = 0;
    getLocationsApiCallId: string = "";
    getDepartmentsApiCallId: string = "";
    scheduleInspectionApiCallId: string = "";
    getMachinesApiCallId: string = "";
    getAccountDetailsAPICallID: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];
        const today = new Date()
        today.setDate(today.getDate() - 1);
        const inputSchema = Yup.object().shape({
            equipmentName: Yup.string().required(configJSON.errorrMsg),
            equipmentNumber: Yup.string().required(configJSON.errorrMsg),
            location: Yup.string().required(configJSON.errorrMsg),
            department: Yup.string().required(configJSON.errorrMsg),
            machines: Yup.string().required(configJSON.errorrMsg),
            inspectionDate: Yup.date().required(configJSON.errorrMsg).min(today, "Please select current or after date"),
            assignTo: Yup.string().required(configJSON.errorrMsg),
            image1: Yup.mixed().when('image2', {
                is: (image2) => !image2,
                then: Yup.mixed().required('At least one image is required'),
            }),
            image2: Yup.mixed(),
        });

        this.state = {
            // Customizable Area Start
            token: "",
            accountData: "",
            loading: false,
            assignPersonLoading: false,
            inputSchema: inputSchema,
            errorMsg: "",
            personList: [],
            locations: [],
            departments: [],
            machines: [],
            image1: "",
            errorMessageImage1: "",
            errorMessageImage2: "",
            image2: "",
            disableSubmit: false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.getLocationsApiCallId) {
                this.handleLocationsApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getMachinesApiCallId) {
                this.handleMachinesApiResponse(responseJson)
            }

            if (apiRequestCallId === this.getDepartmentsApiCallId) {
                this.handleDepartmentApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getAssignPersonApiCallId) {
                this.handleAssignPersonApiResponse(responseJson)
            }
            if (apiRequestCallId === this.scheduleInspectionApiCallId) {
                this.handleScheduleInspectionApiResponse(responseJson)
            }
            if (apiRequestCallId === this.getAccountDetailsAPICallID) {
                this.handleAccountDetailApiResponse(responseJson)
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        super.componentDidMount();
        let token = await getStorageData("authToken", false);
        this.setState({ token });

        this.getLocationsApiCall();
        this.getDepartmentsApiCall();
        this.getMachinesApiCall();
        this.getAccountDetailsAPI();
    }

    getAssignPersonOptionsList = () => {
        const { personList, assignPersonLoading } = this.state;
        const options: PersonListOptions[] = [];
        !assignPersonLoading && personList.length > 0 && personList?.forEach((item) => {
            if (item.attributes.full_name !== null || item.attributes.employee_id !== null) {
                options.push({
                    label: `${item.attributes.full_name}(${item.attributes.employee_id})`,
                    value: item.id
                })
            }
        });
        return options;
    }

    getOptionForMenu = (option: { attributes: { name: string; } }) => {
        return option.attributes.name.length > 25 ? `${option.attributes.name.substring(0, 25)}...` : option.attributes.name;
    }
    
    handleInspectionDateChange = (SelectedDate: Date | null | undefined, setFieldValue: Function) => {
        if (SelectedDate) {
            SelectedDate?.setHours(SelectedDate?.getHours() + 5);
            SelectedDate?.setMinutes(SelectedDate?.getMinutes() + 30);
            const formatedDate = SelectedDate?.toISOString().replace(/(,|\s)+/g, 'T');
            setFieldValue("inspectionDate", new Date(formatedDate))
        }
    }

    handleAccountDetailApiResponse = (responseJson: AccountData) => {
        this.setState({ accountData: responseJson.data.attributes.official_email })
    }

    handleScheduleInspectionApiResponse = (responseJson: PostInspectionResponseData) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ loading: false, disableSubmit: false });
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "PlannedInspection");
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                loading: false,
                disableSubmit: false
            });
        }
    }
    handleNavigateToHome = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "PlannedInspection");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }

    handleMachinesApiResponse = (responseJson: MachinesResponseData) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ machines: responseJson.machine_tools.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleAssignPersonApiResponse = (responseJson: PersonListResponseData) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ personList: responseJson.accounts.data, assignPersonLoading: false })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors,
                assignPersonLoading: false
            });
        }
    }

    handleLocationsApiResponse = (responseJson: LocationResponseData) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ locations: responseJson.locations.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleDepartmentApiResponse = (responseJson: DepartmentsResponseData) => {
        if (responseJson && !responseJson.errors) {
            this.setState({ departments: responseJson.departments.data })
        } else if (responseJson && responseJson.errors) {
            this.setState({
                errorMsg: responseJson.errors
            });
        }
    }

    handleAssignToPerson = (newValue: string, setFieldValue: Function) => {
        clearTimeout(this.searchPerson);
        this.searchPerson = setTimeout(() => {
            this.assignPersonListApiCall(newValue);
        }, 500);
    }

    handleImage1Change(event: ChangeEvent<HTMLInputElement>, setFieldValue: Function) {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/png', 'image/jpeg'];

        if (file) {
            if (acceptedFormats.includes(file.type)) {
                setFieldValue("image1", event.target.files?.[0]);
                this.changeImg1(event);
                this.setState({ errorMessageImage1: '' });
            } else {
                this.setState({ errorMessageImage1: 'Please select a valid image file (PNG, JPG or JPEG).' });
            }
        }
    }

    changeImg1(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            this.setState({ image1: fileReader.result as string });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    handleImage2Change(event: ChangeEvent<HTMLInputElement>, setFieldValue: Function) {
        const file = event.target.files?.[0];
        const acceptedFormats = ['image/png', 'image/jpeg'];

        if (file) {
            if (acceptedFormats.includes(file.type)) {
                setFieldValue("image2", event.target.files?.[0]);
                this.changeImg2(event);
                this.setState({ errorMessageImage2: '' });
            } else {
                this.setState({ errorMessageImage2: 'Please select a valid image file (PNG, JPG or JPEG).' });
            }
        }
    }

    changeImg2(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files;

        const fileReader = new FileReader();
        fileReader.addEventListener("load", async () => {
            this.setState({ image2: fileReader.result as string });

        });
        files?.length && fileReader.readAsDataURL(files[0]);
    }

    scheduleInspectionApiCall = async (values: ScheduleInspectionData) => {
        this.setState({
            loading: true,
            disableSubmit: true
        });
        const headers = {
            token: this.state.token,
        };

        const formattedDate = moment(values.inspectionDate?.toISOString()).format('DD MMM YYYY');
        const currentDate = moment().format('DD MMM YYYY');
        let status = currentDate === formattedDate ? "Due" : "Planned";
        const assignTo = typeof(values.assignTo) === "object" ? values.assignTo.value : values.assignTo;

        let formData = new FormData();
        formData.append("equipment_name", values.equipmentName);
        formData.append("equipment_number", values.equipmentNumber);
        formData.append("location_id", values.location);
        formData.append("machine_tool_id", values.machines);
        formData.append("department_id", values.department);
        formData.append("email", values.email);
        formData.append("inspection_date", formattedDate);
        formData.append("assigned_to_id", assignTo);
        if (values.image1 && typeof (values.image1) !== 'string') {
            formData.append("first_image", values.image1, values.image1.name);
        }
        if (!values.image1 && values.image2 && typeof (values.image2) !== 'string') {
            formData.append("first_image", values.image2, values.image2.name);
        }
        if (values.image1 && values.image2 && typeof (values.image2) !== 'string') {
            formData.append("second_image", values.image2, values.image2.name);
        }
        formData.append("status", status);

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.scheduleInspectionApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postScheduleInspectionApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getAccountDetailsAPI = async () => {
        let SignUpID = JSON.parse(await getStorageData("SignUpID"));

        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };

        const getAllRequestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAccountDetailsAPICallID = getAllRequestMsg.messageId;
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.accountApiEndPoint}/${SignUpID}`
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        getAllRequestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );

        runEngine.sendMessage(getAllRequestMsg.id, getAllRequestMsg);
    };

    getLocationsApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getLocationsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getLocationsApiEndPoint}?per_page=200`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getDepartmentsApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDepartmentsApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getDepartmentsApiEndPoint}?per_page=200`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    getMachinesApiCall = () => {
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getMachinesApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getMachinesApiEndPoint}?per_page=200`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    assignPersonListApiCall = (searchText: string) => {
        this.setState({ assignPersonLoading: true })
        const headers = {
            "Content-Type": "application/json",
            token: this.state.token,
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAssignPersonApiCallId = requestMsg.messageId;
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getAssignPersonApiEndPoint}?search=${searchText}`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethodType
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    // Customizable Area End
}
