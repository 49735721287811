import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, profileDefaultImage } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import React from "react";

interface ListStoryData {
  id: number;
  attributes: {
    created_at: string;
    title: string;
    account_id: number;
    description: string;
    story_image: string;
    profile_pic: string;
    own_story: boolean;
    is_created_by_worker: boolean;
    is_created_by_admin: boolean;
    account: {
      data: {
        id: number;
        attributes: {
          full_name: string;
          profile_image: string;
        }
      }
    },
    admin_user: {
      data: {
        id: number;
        attributes: {
          profile_image: string;
        }
      }
    }
  }
};

interface StoryDetailData {
  id?: number;
  attributes?: {
    created_at: string;
    updated_at: string;
    title: string;
    description: string;
    story_image: string;
    profile_pic: string;
    is_created_by_worker: boolean;
    is_created_by_admin: boolean;
    views: number;
    user_name: string;
    account: {
      data: {
        id: number;
        attributes: {
          full_name: string;
          profile_image: string;
        }
      }
    },
    admin_user: {
      data: {
        id: number;
        attributes: {
          profile_image: string;
        }
      }
    }
  }
}

interface SpeechRecognition extends EventTarget {
  lang: string;
  continuous: boolean;
  interimResults: boolean;
  onresult: (event: SpeechRecognitionEvent) => void;
  start: () => void;
  stop: () => void;
}

interface SpeechRecognitionEvent {
  results: { transcript: string }[][];
  resultIndex?: number;
  emma?: Document;
}

interface SpeechRecognitionConstructor {
  new(): SpeechRecognition;
}

interface GlobalWebKit {
  webkitSpeechRecognition: SpeechRecognitionConstructor;
}

interface GlobalSpeech {
  SpeechRecognition: SpeechRecognitionConstructor;
}

interface DocumentWithVisibilityState extends Document {
  hidden: boolean;
  visibilityState: 'hidden' | 'visible';
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onImageClick: (id: number) => void;
  handleCloseStoryImage: () => void;
  storyImageId: number;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  customSliderRef: React.RefObject<HTMLDivElement>;
  largeImage: boolean;
  token: string;
  loading: boolean;
  errorMsg: string;
  storyImageId: number | undefined;
  storyProgress: number;
  currentStoryId: number;
  lastVisibleSlide: number;
  isExpanded: boolean;
  timerPaused: boolean;
  listStoryData: ListStoryData[];
  storySliderData: ListStoryData[];
  storyDetailData: StoryDetailData;
  openStoryModal: boolean;
  uploadedImagePreview: string | ArrayBuffer | null;
  referenceImage: File | string;
  storyTitle: string;
  storyDescription: string;
  errorTitle: string;
  errorDescription: string;
  errorMessageReferenceImage: string;
  clickPost: boolean;
  disablePostButton: boolean;
  listening: boolean;
  stoppedByTimeout: boolean;
  transcription: string;
  storyConfig: {
    width: number;
    baseWidth: number;
    speed: number;
    transformPosition: number;
    totalVisible: number;
    current: number;
    sliderWidth: number;
  };
  viewedStories: number[];
  viewedOwnStories: number[];
  plusIconVisible: boolean;
  showStories: boolean;
  visibleStory: any;
  currentStoriesData: Array<any>;
  visibleStoryIndex: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Story2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  listUserStoriesApiCallId: string = "";
  detailUserStoriesApiCallId: string = "";
  createStoryApiCallId: string = "";
  recognition: SpeechRecognition | null = null;
  storyTimer = 30;
  timer: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      customSliderRef: React.createRef<HTMLDivElement>(),
      largeImage: false,
      token: "",
      isExpanded: false,
      timerPaused: false,
      listStoryData: [],
      storySliderData: [],
      loading: false,
      storyDetailData: {},
      errorMsg: "",
      storyImageId: 0,
      openStoryModal: false,
      uploadedImagePreview: "",
      referenceImage: "",
      storyTitle: "",
      storyDescription: "",
      errorTitle: "",
      errorDescription: "",
      errorMessageReferenceImage: "",
      clickPost: false,
      disablePostButton: false,
      listening: false,
      stoppedByTimeout: false,
      transcription: "",
      storyProgress: 0,
      currentStoryId: 0,
      lastVisibleSlide: 0,
      storyConfig: {
        width: 70,
        baseWidth: 65,
        speed: 500,
        transformPosition: 0,
        totalVisible: 0,
        current: 0,
        sliderWidth: 0,
      },
      viewedStories: [],
      viewedOwnStories: [],
      plusIconVisible: true,
      showStories: false,
      visibleStory: {},
      currentStoriesData: [],
      visibleStoryIndex: 0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.listUserStoriesApiCallId) {
        this.handleListUserStories(responseJson)
      }
      if (apiRequestCallId === this.detailUserStoriesApiCallId) {
        this.handleDetailUserStories(responseJson)
      }
      if (apiRequestCallId === this.createStoryApiCallId) {
        this.handleCreateStory(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("authToken", false);
    this.setState({ token, storyImageId: this.props.storyImageId });
    this.props.storyImageId === 0 && this.listUserStoriesApiCall();

    this.initializeStoryConfig(this.state.listStoryData);
    this.initializeStoryConfig(this.state.storySliderData);
    this.detailUserStoryApiCall(0);

    window.addEventListener('resize', () => {
      this.initializeStoryConfig(this.state.listStoryData);
      this.initializeStoryConfig(this.state.storySliderData);
    });

    if ("SpeechRecognition" in global || "webkitSpeechRecognition" in global) {
      this.setupSpeechRecognition();
    }
  }

  setupSpeechRecognition() {
    this.recognition = new ((global as unknown as GlobalSpeech).SpeechRecognition ||
      (global as unknown as GlobalWebKit).webkitSpeechRecognition)();

    if (this.recognition) {
      this.recognition.lang = "en-US";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event: SpeechRecognitionEvent) => {
        const transcription = Array.from(event.results)
          .map((result: { transcript: string }[]) => result[0].transcript)
          .join("");
        this.setState({ transcription });
      };
      const pageHiddenProp = this.getPageHiddenProp();
      if (pageHiddenProp) {
        this.addVisibilityChangeListener(pageHiddenProp);
      }
    }
  }

  addVisibilityChangeListener(pageHiddenProp: string) {
    const customDocument: DocumentWithVisibilityState = document;
    customDocument.addEventListener(pageHiddenProp.replace(/[H|h]idden/, '') + 'visibilitychange', () => {
      if (customDocument['hidden'] && this.recognition) {
        this.stopRecognition();
      }
    });
  }

  stopRecognition() {
    if (this.state.listening) {
      this.recognition?.stop();
      this.setState({ listening: false });
    }
  }

  getPageHiddenProp() {
    const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
    for (const prop of hiddenProps) {
      if (prop in document) {
        return prop;
      }
    }
    return null;
  }

  toggleListening = () => {
    if (this.recognition) {
      if (this.state.listening) {
        this.setState({ stoppedByTimeout: true });
        this.recognition?.stop();
      } else {
        this.setState({ stoppedByTimeout: false });
        this.recognition?.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listening && !this.state.stoppedByTimeout) {
            this.recognition?.stop();
            this.setState({ listening: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listening: !prevState.listening,
      }));
    }
  };

  handleMicForStoryDescription = (storyDescription: React.MouseEvent<HTMLImageElement, MouseEvent | string>) => {
    let text = storyDescription + " " + this.state.transcription;
    text = text.replace('[object Object]', '').trim();
    this.toggleListening();
    this.setState({
      storyDescription: text
    });
  }

  formatTimeAgo = (dateString: string) => {
    const currentDate: Date = new Date();
    const pastDate: Date = new Date(dateString);
    const timeDifference = currentDate.getTime() - pastDate.getTime();
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let formattedString = '';
    if (hours > 0) {
      formattedString += hours + 'h ';
    }
    formattedString += minutes + ' min ago';
    return formattedString;
  }

  closeStoryModal = () => {
    this.setState({ openStoryModal: false })
  }

  handleCreateOwnStoryModal = (event: React.MouseEvent<HTMLImageElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      openStoryModal: true,
      uploadedImagePreview: "",
      referenceImage: "",
      storyTitle: "",
      storyDescription: "",
      errorTitle: "",
      errorDescription: "",
      errorMessageReferenceImage: "",
      clickPost: false,
      disablePostButton: false,
    })
  }

  handleChangeStoryTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    this.setState({ storyTitle: newValue, errorTitle: "" });
  };

  handleChangeStoryDesc = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    this.setState({ storyDescription: newValue, errorDescription: "" });
  };

  handleImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    const acceptedFormats = ['image/png', 'image/jpeg'];

    if (file) {
      if (acceptedFormats.includes(file.type)) {
        this.setState({ referenceImage: event.target.files?.[0] || "" });
        this.changeImg(event);
        this.setState({ errorMessageReferenceImage: '' });
      } else {
        this.setState({ errorMessageReferenceImage: 'Please select a valid image file (PNG, JPG or JPEG).' });
      }
    }
  }

  changeImg(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;

    const fileReader = new FileReader();
    fileReader.addEventListener("load", async () => {
      this.setState({ uploadedImagePreview: fileReader.result });

    });
    files?.length && fileReader.readAsDataURL(files[0]);
  }

  async componentWillUnmount() {
    super.componentWillUnmount();

    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  handleListUserStories = (responseJson: { error: string; user_stories: { data: ListStoryData[]; }; latest_own_story: { data: ListStoryData } }) => {
    if (responseJson && !responseJson.error) {
      let listStoryData: ListStoryData[] = [];
      let storySliderData: ListStoryData[] = [];

      const latestStory = responseJson.latest_own_story.data;
      if (latestStory) {
        listStoryData.push(latestStory);
        storySliderData.push(latestStory);
      }

      const userStories = responseJson.user_stories.data;
      userStories.forEach((story) => {

        listStoryData.push(story);

        if (!storySliderData.find((item) => item.attributes.account_id === story.attributes.account_id)) {
          storySliderData.push(story);
        }
      });

      this.setState({ storySliderData, listStoryData, loading: false }, () => {
        this.initializeStoryConfig(listStoryData);
      })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false
      });
    }
  }

  handleDetailUserStories = (responseJson: { data: StoryDetailData, error: string }) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        storyDetailData: responseJson.data,
        storyImageId: responseJson.data?.id,
        storyProgress: 0,
        isExpanded: false,
        loading: false
      });
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false
      });
    }
  }

  handleCreateStory = (responseJson: { error: string }) => {
    if (responseJson && !responseJson.error) {
      this.setState({ loading: false, openStoryModal: false, disablePostButton: false })
      this.listUserStoriesApiCall();
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        loading: false
      });
    }
  }

  postStory = () => {
    this.setState({ clickPost: true })
    const { storyTitle, storyDescription, referenceImage } = this.state;

    const errorInStoryTitle = !storyTitle || storyTitle.length < 5 || storyTitle.length > 50;
    const errorInstoryDescription = !storyDescription || storyDescription.length < 10 || storyDescription.length > 100;
    const errorInImage = !referenceImage

    if (errorInStoryTitle || errorInstoryDescription || errorInImage) {
      if (storyTitle && storyTitle.length < 5) {
        this.setState({ errorTitle: "Title must be more than 5 characters." })
      }
      if (storyTitle && storyTitle.length > 50) {
        this.setState({ errorTitle: "Title is too long (maximum 50 characters)" })
      }
      if (storyDescription && storyDescription.length < 10) {
        this.setState({ errorDescription: "Description must be more than 10 characters." })
      }
      if (storyDescription && storyDescription.length > 100) {
        this.setState({ errorDescription: "Description is too long (maximum 100 characters)" })
      }
      return;
    }
    this.createStoryApiCall();
  }

  createStoryApiCall = () => {
    this.setState({
      loading: true,
      disablePostButton: true
    });
    const headers = {
      token: this.state.token,
    };

    let formData = new FormData();
    formData.append("title", this.state.storyTitle);
    formData.append("description", this.state.storyDescription);
    formData.append("is_active", "true");
    formData.append("story_image", this.state.referenceImage);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createStoryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createStoryApiEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleReadMoreClick = () => {
    this.setState({ isExpanded: !this.state.isExpanded })
  }

  listUserStoriesApiCall = () => {
    this.setState({ loading: true });
    const headers = {
      token: this.state.token,
      "Content-Type": "application/json",
    };
    const listUserStoriesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.listUserStoriesApiCallId = listUserStoriesRequestMsg.messageId;
    listUserStoriesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listUserStoriesApiEndPoint}?page=1&per_page=200`
    );
    listUserStoriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    listUserStoriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(listUserStoriesRequestMsg.id, listUserStoriesRequestMsg);
  }

  detailUserStoryApiCall = (storyId: number) => {
    this.setState({ loading: true, currentStoryId: storyId });
    if (this.timer) {
      clearInterval(this.timer);
    }
    const headers = {
      token: this.state.token,
      "Content-Type": "application/json",
    };
    const detailUserStoriesRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.detailUserStoriesApiCallId = detailUserStoriesRequestMsg.messageId;
    detailUserStoriesRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.detailUserStoriesApiEndPoint}/${storyId}`
    );
    detailUserStoriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    detailUserStoriesRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(detailUserStoriesRequestMsg.id, detailUserStoriesRequestMsg);
  }

  initializeStoryConfig(listStoryData: ListStoryData[]) {
    let plusIconVisible = true;
    let offsetWidth = this.state.customSliderRef?.current?.offsetWidth || 0;

    if (listStoryData.length) {
      listStoryData.forEach((value: ListStoryData, index: number) => {
        if (plusIconVisible && value.attributes.own_story) {
          plusIconVisible = false;
        }
      });
    }

    offsetWidth = this.state.plusIconVisible && listStoryData.length && plusIconVisible ? offsetWidth - 60 : offsetWidth;

    const totalVisible = Math.floor(offsetWidth / this.state.storyConfig.baseWidth);
    const width = Math.ceil(offsetWidth / totalVisible);

    this.setState({
      storyConfig: {
        ...this.state.storyConfig,
        width,
        totalVisible,
        sliderWidth: offsetWidth
      },
      plusIconVisible: this.state.plusIconVisible ?
        !(this.state.plusIconVisible && listStoryData.length && plusIconVisible) : false
    });
  }

  handlePrevious = () => {
    const { width, current, transformPosition } = this.state.storyConfig;
    const newCurrent = current - 1;

    if (current > 0) {
      this.setState({
        storyConfig: {
          ...this.state.storyConfig,
          current: newCurrent,
          transformPosition: transformPosition + width
        }
      });
    }
  }

  handleNext = () => {
    const { width, current, totalVisible, transformPosition } = this.state.storyConfig;
    const totalSlides = this.state.listStoryData.length;
    const newCurrent = current + 1;

    if ((current + totalVisible) < totalSlides) {
      this.setState({
        storyConfig: {
          ...this.state.storyConfig,
          current: newCurrent,
          transformPosition: transformPosition - width
        }
      });
    }
  }

  handleStoryImageClick = (accountId: number | undefined) => {
    this.setState({ viewedOwnStories: [], showStories: false }, () => {
      this.displayStoryDetail(accountId as number);
    });
  }

  displayStoryDetail = (accountId: number | undefined) => {
    this.props.onImageClick(accountId as number);
    const { listStoryData, currentStoriesData, visibleStoryIndex } = this.state;
    let storiesData: any = [];
    listStoryData.forEach((item) => {
      const story = item.attributes;
      if (accountId === story.account_id) {
        storiesData.push(story);
      }
    });
    this.setState({ showStories: true, currentStoriesData: storiesData, visibleStory: currentStoriesData[visibleStoryIndex] });
  }

  closeStoryDetail = () => {
    this.props.handleCloseStoryImage();
    this.setState({ storyImageId: 0, showStories: false });
  }

  pauseTimer = () => {
    this.setState({ timerPaused: true });
  }

  releaseTimer = () => {
    this.setState({ timerPaused: false });
  }

  getProfileImage = (story: any) => {
    let profileImage = profileDefaultImage;

    if (story?.is_created_by_admin &&
      story.admin_user.data.attributes.profile_image.length) {
      profileImage = story.admin_user.data.attributes.profile_image;
    }

    if (story?.is_created_by_worker &&
      story.profile_pic.length) {
      profileImage = story.profile_pic;
    }

    return profileImage;
  }

  getProfileImageList = (listItem: ListStoryData) => {

    let profileImage = profileDefaultImage;

    if (listItem.attributes?.is_created_by_admin &&
      listItem.attributes.admin_user.data.attributes.profile_image.length) {
      profileImage = listItem.attributes.admin_user.data.attributes.profile_image;
    }

    if (listItem.attributes?.is_created_by_worker &&
      listItem.attributes.profile_pic.length) {
      profileImage = listItem.attributes.profile_pic;
    }

    return profileImage;
  }

  getProfileName = (story: any) => {
    let profileName = "";

    if (story?.is_created_by_admin) {
      profileName = "Admin User";
    }

    if (story?.is_created_by_worker) {
      profileName = story.user_name;
    }

    return profileName;
  }

  getPlusIconVisible = () => {
    let plusIconVisible = -1;

    this.state.listStoryData.forEach((value, index) => {
      if (plusIconVisible < 0 && value.attributes.own_story) {
        plusIconVisible = index;
      }
    });

    return plusIconVisible;
  }

  handleStoryStart = (storyIndex: number) => {
    const { currentStoriesData } = this.state;
    this.setState({ visibleStoryIndex: storyIndex, visibleStory: currentStoriesData[storyIndex] });
  }

  handleStoryEnd = (storyIndex: number) => {
    const { currentStoriesData } = this.state;
    if (storyIndex < currentStoriesData.length - 1) {
      this.setState({ visibleStoryIndex: storyIndex + 1, visibleStory: currentStoriesData[storyIndex + 1] });
    }
  }
  // Customizable Area End
}
