// Customizable Area Start
import React from "react";
import {
    Avatar,
    Grid,
    Typography,
    Paper,
    Button,
    Box,
} from "@material-ui/core";
import Loader from "../../../components/src/Loader.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});

import {
    locationIcon,
    profileDefaultImage,
    timeIcon,
} from "./assets";
import { NavigateNext } from "@material-ui/icons";
import ViewPostController, { Props, configJSON } from "./ViewPostController";
import { getTimeFromNow } from "../../../components/src/helpers/getTimeFromNow";
import { webStyles } from "../../../components/src/styles";

export default class SafetyCouncilPosts extends ViewPostController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        // Customizable Area Start
        const {
            loading,
            actionPostData,
        } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <Grid style={webStyle.mainContainer}>                 
                    <Box style={webStyles.breadcrumbWrapper}>
                        <Typography style={webStyles.breadcrumbItem} onClick={() => this.redirectToSafetyCouncil()}>{configJSON.safetyCouncilText}</Typography>
                        <NavigateNext style={webStyles.navigateIcon} />
                        <Typography style={webStyles.breadcrumbActiveItem}>{configJSON.PostText}</Typography>
                    </Box>
                    <Typography style={webStyle.header}>{configJSON.PostText}</Typography>
                    {!loading && (
                        <Paper style={webStyle.paperStyle}>
                            <Grid>
                                <Typography onClick={() => this.goToUserProfile(actionPostData.attributes.account_id)} style={webStyle.firstTypography} data-test-id="profileImgText">
                                    <Avatar data-test-id="profileImg" src={actionPostData?.attributes?.profile_pic || profileDefaultImage} style={webStyle.profileImage} />
                                    <span style={webStyle.fullName as React.CSSProperties}>{actionPostData?.attributes?.user_name} Created this post</span>
                                </Typography>
                                <img src={actionPostData?.attributes?.image_url}
                                    alt="Your Image"
                                    style={webStyle.postImage}
                                    data-test-id="postImage"
                                />
                                <Typography style={webStyle.postDescribtion}>
                                    {actionPostData?.attributes?.description}
                                </Typography>
                                <Grid container>
                                    <Typography style={webStyle.locationTypography as React.CSSProperties}>
                                        <img src={locationIcon} style={webStyle.locationIcon} />
                                        <span style={webStyle.locationText as React.CSSProperties}>{actionPostData?.attributes?.work_location || "No Location"}</span>
                                    </Typography>
                                    <Typography style={webStyle.usernameTimestyle}>
                                        <Avatar src={actionPostData?.attributes?.profile_pic || profileDefaultImage} style={webStyle.bottomProfileImg} />
                                        <span style={webStyle.locationText as React.CSSProperties}>{actionPostData?.attributes?.user_name}</span>
                                    </Typography>
                                    <Typography style={webStyle.usernameTimestyle}>
                                        <img src={timeIcon} style={webStyle.timeIcon} />{getTimeFromNow(actionPostData?.attributes?.created_at)} ago
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    )}

                    <div style={webStyle.btnDiv}>
                        <Button
                            variant="outlined"
                            color="primary"
                            style={webStyle.btnStyleColor as React.CSSProperties}
                            data-test-id="proceed"
                            onClick={this.SafetyCouncilPostDetail}
                        >
                            {configJSON.ProceedToAssignText}
                        </Button>
                    </div>
                </Grid>
                <Loader loading={this.state.loading} />
            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    fillColor: {
        fill: '#DCDCDC'
    },
    mainContainer: {
        padding: "30px",
        background: "rgb(238, 238, 238)"
    },
    bottomProfileImg: {
        width: '26px',
        height: '26px',
        border: '1px',
        marginRight: '6px',
    },
    btnStyleColor: {
        minWidth: "160px",
        height: "50px",
        backgroundColor: "#8735E1",
        textTransform: "none",
        color: "white",
        border: "1px solid #8735E1",
        padding: '10px',
        borderRadius: "8px",
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        lineHeight: '22px',
    },
    btnDiv: {
        padding: "30px 0px",
        display: "flex",
        justifyContent: "flex-end",
    },
    timeIcon: {
        width: '24px',
        height: '24px',
        marginRight: '6px',
    },
    usernameTimestyle: {
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        display: "flex",
        alignItems: "center",
        color: '#A1A1A1',
        marginRight: '20px',
    },
    locationIcon: {
        width: '20px',
        height: '20px',
        marginRight: '5px',
    },
    locationTypography: {
        alignItems: "center",
        display: "flex",
        borderRadius: "6px",
        background: "#F7F3F3",
        whiteSpace: "nowrap",
        color: "#A1A1A1",
        fontFamily: 'ClotherRegular',
        fontSize: '16px',
        padding: "5px 15px",
        marginRight: '20px',
    },
    postDescribtion: {
        fontFamily: 'ClotherRegular',
        fontSize: '18px',
        lineHeight: '22px',
        paddingBottom: '20px',
    },
    postImage: {
        width: '100%',
        height: '380px',
        margin: '20px 0px',
        borderRadius: '8px',
    },
    fullName: {
        overflow: "hidden",
        maxWidth: "100%",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
    },
    firstTypography: {
        fontFamily: 'ClotherBold',
        fontSize: '20px',
        display: "flex",
        alignItems: "center",
        cursor: 'pointer',
    },
    paperStyle: {
        height: "auto",
        padding: "30px",
        background: "#fff",
        minHeight: "80vh",
        marginTop: '10px'
    },
    header: {
        fontFamily: 'ClotherBold',
        fontSize: '24px',
        lineHeight: '24px',
        color: '#2B2A29',
        padding: '10px 0px'
    },
    currentPage: {
        fontFamily: 'ClotherBold',
        fontSize: '14px',
        color: '#8735E1',
        marginLeft: '2px',
    },
    headers: {
        fontFamily: 'ClotherRegular',
        fontSize: '14px',
        lineHeight: '22px',
        color: '#18181E',
        margin: '0px',
    },
    profileImage: {
        width: '46px',
        height: '46px',
        marginRight: '16px',
    },
    locationText: {
        overflow: "hidden",
        maxWidth: "130px",
        textOverflow: "ellipsis",
        whiteSpace: 'nowrap',
    },
};
