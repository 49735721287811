import React from "react";

// Customizable Area Start
import { Button, Grid, MenuItem, Paper, Select, Typography, Divider, RadioGroup, FormControlLabel, Radio, TextField, Input, InputAdornment, Dialog, DialogTitle, DialogActions, TextFieldProps } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { CompletedIcon, calendarIcon, mic, micStart } from "./assets";
import DatePicker from "react-datepicker";
import Autocomplete from "@material-ui/lab/Autocomplete";

const theme = createTheme({
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#DCDCDC'
      },
      colorSecondary: {
        '&$checked': {
          color: '#8735E1',
        },
      },
    },
    MuiInputBase: {
      root: {
        height: "54px",
        border: "1px solid #DCDCDC",
        padding: "15px 15px",
        fontSize: "16px",
        lineHeight: "18px",
        color: "#2B2A29",
        width: "100%",
        fontWeight: 400,
        letterSpacing: "0.5px",
        textOverflow: "ellipsis",
        justifyContent: "flex-start",
        borderRadius: "8px",
        "&.Mui-disabled": {
          cursor: "no-drop",
        },
        "&:hover, &.Mui-focused": {
          border: `1px solid #8735E1`,
        },
        "&.date-input": {
          background: `url(${calendarIcon}) no-repeat`,
          backgroundPosition: "right 15px center",
          borderWidth: "thin",
          paddingRight: "15px",
        },
      },
    },
    MuiMenu: {
      paper: {
        height: '160px',
        marginLeft: '-20px'
      }
    }
  }
});

import PerformanceTrackerController, {
  ActionTrackerPostData,
  Props, configJSON,
} from "./PerformanceTrackerController";
import { KeyboardArrowDown, NavigateNext } from "@material-ui/icons";
import Loader from "../../../components/src/Loader.web";
import moment from "moment";

type RenderInputParams = TextFieldProps & {
  InputProps: {
    className?: string;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
    ref?: React.Ref<HTMLDivElement>;
  };
};
// Customizable Area End

export default class ActionTrackerPostForm extends PerformanceTrackerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderMicForFollowUp = () => {
    return this.state.listeningFollowUp ? micStart : mic;
  }

  renderMicForActionTaken = () => {
    return this.state.listeningActionTaken ? micStart : mic;
  }

  Headers = () => {
    return (
      <Grid container>
        <Typography style={webStyle.actionTrackerHeader} data-test-id="ActiontrackerText" onClick={this.goToActionTracker}>
          {configJSON.ActiontrackerText}
        </Typography>
        <NavigateNext style={webStyle.navigateIcon} />
        <Typography style={webStyle.actionTrackerHeader} data-test-id="PostText" onClick={this.goToPostPage}>
          {configJSON.PostText}
        </Typography>
        <NavigateNext style={webStyle.navigateIcon} />
        <Typography style={webStyle.currentPage} onClick={this.goToPostPage}>{configJSON.FormText}</Typography>
      </Grid>
    )
  }

  followUpData = (actionPostData: ActionTrackerPostData | null) => {
    return (
      <>
        {(!actionPostData?.attributes.follow_up && actionPostData?.attributes.status !== "pending_review" && actionPostData?.attributes.status !== "verified") && (
          <Grid style={webStyle.followUpBox}>
            <Grid container justifyContent="space-between">
              <Typography style={webStyle.label}>{configJSON.FollowUpOption}</Typography>
              <img
                src={this.renderMicForFollowUp()}
                style={webStyle.mikeIcon}
                data-test-id="followup_speechtext"
                onClick={
                  async () => {
                    await this.toggleListeningForFollowUP();
                    this.setState({
                      followUpTextArea: this.state.followUpTextArea + " " + this.state.transcription
                    });
                  }
                } />
            </Grid>
            <textarea
              style={webStyle.textareaStyle}
              placeholder="Follow-Up Description"
              value={this.state.followUpTextArea}
              onChange={(e) => this.handleTextAreaChange(e)}
              data-test-id="handleTextAreaChange"
              className="textarea"
            />
          </Grid>
        )}
      </>
    )
  }

  ActionTakenOption = (actionPostData: ActionTrackerPostData | null) => {
    return (
      actionPostData?.attributes.action_taken && (
        <>
          <Grid item lg={12} sm={12} style={webStyle.followDetailsBox}>
            <Typography style={webStyle.label2}>{configJSON.actionTakenText}</Typography>
            <Divider variant="inset" component="div" style={webStyle.noMargin} />
            <Typography style={webStyle.details}>{actionPostData?.attributes.action_taken}</Typography>
          </Grid>
          {this.state.is_supervisor && this.state.actionPostData?.attributes.status === "pending_review" && (
            <Grid container style={webStyle.approveBtnBox} alignItems="center">
              <Typography style={webStyle.approveOptionText}>{configJSON.ApproveText}</Typography>
              {this.state.actionPostData?.want_to_approve}
              {this.ApprovalChoices()}
            </Grid>
          )
          }
        </>
      )
    )
  }

  ApprovalChoices = () => {
    return (
      <RadioGroup
        data-test-id="radio_button"
        aria-labelledby="hazard-radio-buttons-group-label"
        defaultValue="no"
        value={this.state.actionPostData?.attributes.want_to_approve}
        name="radio-buttons-group"
        style={webStyle.footerRadioG as React.CSSProperties}
        onChange={this.handleActionApprove.bind(this)}
      >
        <FormControlLabel
          style={webStyle.footerRadioLabel}
          name="yes"
          data-test-id="hazardFirstId"
          value="yes"
          control={<Radio />}
          label={
            <Typography
              style={webStyle.formControlLabel as React.CSSProperties}
            >
              {configJSON.YesText}
            </Typography>
          }
        />
        <FormControlLabel
          style={webStyle.footerRadioLabel}
          name="no"
          data-id="hazardSecondId"
          value="no"
          control={<Radio />}
          label={
            <Typography
              style={webStyle.formControlLabel as React.CSSProperties}
            >
              No
            </Typography>
          }
        />
      </RadioGroup>
    )
  }

  ActionButtons = (actionPostData: ActionTrackerPostData | null) => {
    return (
      <div style={webStyle.btnDiv}>
        {this.state.is_supervisor && (
          <>
            {(this.state.followUp === "followUp" && !actionPostData?.attributes.follow_up && actionPostData?.attributes.status !== "pending_review" && actionPostData?.attributes.status !== "verified") && (
              <Button
                variant="outlined"
                color="primary"
                style={webStyle.btnStyleColor as React.CSSProperties}
                data-test-id="FollowupButton"
                onClick={this.followUpForAction}
              >
                Follow-Up
              </Button>
            )}
            {this.state.followUp !== "followUp" && (
              <Button
                variant="outlined"
                color="primary"
                style={webStyle.btnStyleColor as React.CSSProperties}
                data-test-id="reAssignButton"
                onClick={this.reAssignTracker}
              >
                Re-Assign
              </Button>
            )}
            {actionPostData?.attributes.action_taken && actionPostData?.attributes.status === "pending_review" && (
              <Button
                variant="outlined"
                color="primary"
                style={webStyle.btnStyleColor as React.CSSProperties}
                data-test-id="approveBtn"
                onClick={this.approveActionTracker}
              >
                Approve
              </Button>
            )}
          </>
        )}
        {!this.state.is_supervisor && (
          <>
            {((this.state.actionPostData?.attributes.status === "verified" || this.state.actionPostData?.attributes.status === "pending_review" ||
              (actionPostData?.attributes.follow_up && actionPostData?.attributes.reply)) && !this.state.isStatusChanges) ? (
              <Button
                variant="outlined"
                color="primary"
                style={webStyle.btnStyleColor as React.CSSProperties}
                data-test-id="DoneBtn"
                onClick={this.goToListing}
              >
                Done
              </Button>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                style={webStyle.btnStyleColor as React.CSSProperties}
                data-test-id="SaveBtn"
                onClick={this.updateActionForWorker}
              >
                Save Changes
              </Button>
            )}
          </>
        )}
      </div>
    )
  }

  FollowUpReplyData = (actionPostData: ActionTrackerPostData | null) => {
    return (
      <>
        {actionPostData?.attributes.follow_up && (
          <Grid item lg={12} sm={12} style={webStyle.followDetailsBox}>
            <Typography style={webStyle.label2}>{configJSON.FollowupOptionText}</Typography>
            <Divider variant="inset" component="div" style={webStyle.noMargin} />
            <Typography style={webStyle.details}>{actionPostData?.attributes.follow_up}</Typography>
          </Grid>
        )}
        {actionPostData?.attributes.reply && (
          <Grid item lg={12} sm={12} style={webStyle.followDetailsBox}>
            <Typography style={webStyle.label2}>{configJSON.replyText}</Typography>
            <Divider variant="inset" component="div" style={webStyle.noMargin} />
            <Typography style={webStyle.details}>{actionPostData?.attributes.reply}</Typography>
          </Grid>
        )}
      </>
    )
  }

  StatusUpdate = () => {
    return (
      <Grid item xs={12} md={6} sm={6}>
        <Typography style={webStyle.label}>{configJSON.StatusText}</Typography>
        <Select
          data-test-id="status"
          labelId="demo-simple-select-autowidth-label"
          name="sub_category_id"
          label={"Age"}
          style={{ ...webStyle.input, ...webStyle.inputStd } as React.CSSProperties}
          IconComponent={KeyboardArrowDown}
          value={this.state.selectedStatus}
          disableUnderline
          onChange={(event) => this.handleStatusChange(event)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          defaultValue={""}
        >
          <MenuItem value={configJSON.SelectStatusText}>
            <em style={webStyle.placeholderText}>{configJSON.SelectStatusText}</em>
          </MenuItem>
          {this.state.statusList.map((menuItem) => (
            <MenuItem value={menuItem} key={menuItem}>
              {menuItem}
            </MenuItem>
          ))}
          {this.state.statusList.length === 0 && (
            <MenuItem>
              {configJSON.NoDataText}
            </MenuItem>
          )}
        </Select>
      </Grid >
    )
  }

  DateUpdate = () => {
    return (
      <Grid item xs={12} md={6} sm={6} lg={6}>
        <Typography style={webStyle.label}>{this.state.selectedStatus === "Completed" ? 'Completion Date' : configJSON.DueDateText}</Typography>
        <DatePicker
          disabled={this.state.is_supervisor || (!this.state.is_supervisor && this.state.selectedStatus) === "Completed" ? false : true}
          scrollableYearDropdown
          className="Location-select"
          showYearDropdown
          yearDropdownItemNumber={100}
          dateFormatCalendar="MMMM"
          dateFormat="dd/MM/yyyy"
          data-test-id="dateChange"
          name="due_date_CA"
          placeholderText="Due Date"
          minDate={new Date()}
          wrapperClassName="date-picker"
          data-testId="date-picker-inline-todate"
          selected={this.state.selectedDate === "" ? null : moment(this.state.selectedDate).toDate()}
          onChange={(date) => this.dateChange(date)}
          popperClassName="example-datepicker-class"
          customInput={
            <Input
              onClick={() => { }}
              fullWidth={true}
              disableUnderline
              data-testid="textInputTest"
              name="dateTest"
              defaultValue={""}
              endAdornment={
                <div>
                  <InputAdornment position="end">
                    <img
                      src={calendarIcon}
                      style={webStyle.calenderIcon}
                    />
                  </InputAdornment>
                </div>
              }
            />
          }
        />
      </Grid>
    )
  }

  ReAssignToUpdate = () => {
    return (
      <Grid item xs={12} md={6} sm={6}>
        <Typography style={webStyle.label}>{configJSON.ReAssignLabel}</Typography>
        <Autocomplete
          options={this.getAssignPersonOptionsList()}
          loading={this.state.assignPersonLoading}
          noOptionsText=""
          popupIcon={<KeyboardArrowDown />}
          data-test-id="handleNameChange"
          id={`assigned_person_for`}
          getOptionLabel={(option) => `${option?.label}`}
          onChange={(event, newValue) => this.handleNameChange(newValue)}
          onInputChange={(event, newValue) => this.handleAssignPersonInputChange(newValue)}
          renderInput={(params: RenderInputParams) => (
            <TextField
              {...params}
              id={`assigned_person_for_CA_input_`}
              placeholder={configJSON.empNameOption}
              variant="outlined"
            />
          )}
        />
        <Typography style={webStyle.popupErrorText}>{this.state.reAssignError}</Typography>
      </Grid>
    )
  }

  ManagerIsFollowing = (actionPostData: ActionTrackerPostData | null) => {
    return (
      <>
        {actionPostData?.attributes.follow_up && (
          <>
            <Grid item lg={12} sm={12} style={webStyle.followDetailsBox}>
              <Typography style={webStyle.label2}>Your Manager is Following-Up with you</Typography>
              <Divider variant="inset" component="div" style={webStyle.noMargin} />
              <Typography style={webStyle.details}>{actionPostData?.attributes.follow_up}</Typography>
            </Grid>
            {actionPostData?.attributes.reply && (
              <Grid item lg={12} sm={12} style={webStyle.followDetailsBox}>
                <Typography style={webStyle.label2}>Your Reply</Typography>
                <Divider variant="inset" component="div" style={webStyle.noMargin} />
                <Typography style={webStyle.details}>{actionPostData?.attributes.reply}</Typography>
              </Grid>
            )}
            {!actionPostData?.attributes.reply && (
              <Grid style={webStyle.followUpBox}>
                <Grid container justifyContent="space-between">
                  <Typography style={webStyle.label}>Reply</Typography>
                  <img src={this.renderMicForReply()}
                    style={webStyle.mikeIcon}
                    data-test-id="reply_speechtext"
                    onClick={
                      async () => {
                        await this.toggleListeningForReply();
                        this.setState({
                          reply: this.state.reply + " " + this.state.transcription
                        });
                      }
                    } />
                </Grid>
                <textarea
                  style={webStyle.textareaStyle}
                  placeholder="Reply to your Manager"
                  value={this.state.reply}
                  onChange={(e) => this.handleReplyChange(e)}
                  data-test-id="reply"
                  className="textarea"
                />
              </Grid>
            )}
          </>
        )}
      </>
    )
  }

  renderMic = () => {
    return this.state.listening ? micStart : mic;
  }

  renderMicForReply = () => {
    return this.state.listeningReply ? micStart : mic;
  }

  SupervisorDetails = (actionPostData: ActionTrackerPostData | null, formattedDate: string | undefined) => {
    return (
      <>
        {this.state.followUp !== "reAssign" && (
          <Grid item xs={12} md={4}>
            <Paper style={webStyle.gridStyle}>
              <Typography style={webStyle.ConcernLocation}>{this.state.is_supervisor ? 'Assigned Due Date:' : 'New Assigned Due Date:'} </Typography>
              <Typography style={webStyle.ConcernLocationValue}>{formattedDate || 'No Due Date'}</Typography>
            </Paper>
          </Grid>
        )
        }
        <Grid item xs={12} md={4}>
          <Paper style={webStyle.gridStyle}>
            <Typography style={webStyle.ConcernLocation}>{configJSON.AssignedByText}: </Typography>
            <Typography style={webStyle.ConcernLocationValue} className="action_description">{actionPostData?.attributes.supervisor_account?.full_name || configJSON.NoNameText}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={webStyle.gridStyle}>
            <Typography style={webStyle.ConcernLocation}>{configJSON.AssignedtoText}: </Typography>
            <Typography style={webStyle.ConcernLocationValue} className="action_description">
              {actionPostData?.attributes.worker_account?.full_name || configJSON.NoNameText}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper style={webStyle.gridStyle}>
            <Typography style={webStyle.ConcernLocation}>{configJSON.EmployeeIDText}: </Typography>
            <Typography style={webStyle.ConcernLocationValue} className="action_description">
              {actionPostData?.attributes.supervisor_account?.employee_id || configJSON.NoEmployeeIDText}
            </Typography>
          </Paper>
        </Grid>
      </>
    )
  }

  DescriptionDetails = () => {
    return (
      <Grid item xs={12} md={8}>
        <Paper style={webStyle.gridStyle}>
          <Typography style={webStyle.ConcernLocation}>{this.state.actionPostData?.attributes.action_type === "corrective_action" ? configJSON.CorrectiveActiontoTake : configJSON.PreventiveActiontoTake}: </Typography>
          <Typography style={webStyle.ConcernLocationValue} className="action_description">
            {this.state.actionPostData?.attributes.description || configJSON.NoActionText}
          </Typography>
        </Paper>
      </Grid>
    )
  }

  locationDetails = (actionPostData: ActionTrackerPostData | null) => {
    return (
      <>
        {actionPostData?.attributes.checkpoint?.non_compiled &&
          <Grid item xs={12} md={4}>
            <Paper style={webStyle.gridStyle}>
              <Typography style={webStyle.ConcernLocation}>Non-Compiled Checkpoint: </Typography>
              <Typography style={webStyle.ConcernLocationValue} className="action_description">
                {actionPostData?.attributes.checkpoint?.non_compiled || configJSON.NoLocationText}
              </Typography>
            </Paper>
          </Grid>
        }
        {actionPostData?.attributes.location_description ?
          <Grid item xs={12} md={4}>
            <Paper style={webStyle.gridStyle}>
              <Typography style={webStyle.ConcernLocation}>Action Title:</Typography>
              <Typography style={webStyle.ConcernLocationValue} className="action_description">
                {actionPostData?.attributes.location_description || configJSON.NoLocationText}
              </Typography>
            </Paper>
          </Grid>
          :
          <Grid item xs={12} md={4}>
            <Paper style={webStyle.gridStyle}>
              <Typography style={webStyle.ConcernLocation}>{configJSON.ConcernLocationText}: </Typography>
              <Typography style={webStyle.ConcernLocationValue} className="action_description">
                {actionPostData?.attributes.post?.location || configJSON.NoLocationText}
              </Typography>
            </Paper>
          </Grid>
        }
        {actionPostData?.attributes.deficiency_number &&
          <Grid item xs={12} md={4}>
            <Paper style={webStyle.gridStyle}>
              <Typography style={webStyle.ConcernLocation}>Deficiency Number:</Typography>
              <Typography style={webStyle.ConcernLocationValue} className="action_description">
                {actionPostData?.attributes.deficiency_number || configJSON.NoLocationText}
              </Typography>
            </Paper>
          </Grid>
        }
      </>
    )
  }

  RemarksInput = () => {
    return (
      <Grid container justifyContent="space-between">
        <Typography style={webStyle.label}>Remarks</Typography>
        <img
          style={webStyle.mikeIcon}
          data-test-id="remark_speechtext"
          src={this.renderMic()}
          onClick={
            async () => {
              await this.toggleListening();
              this.setState({
                remarks: this.state.remarks + " " + this.state.transcription
              });
            }
          }
        />
      </Grid>
    )
  }

  ErrorPopUp = () => {
    return (
      <Dialog open={this.state.isError}>
        <DialogTitle style={webStyle.popupErrorText}>
          <Typography>
            {this.state.errorMessage}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button data-test-id="error-ok-btn" style={webStyle.okBtn} variant="contained" onClick={this.closeErrorModal}>Ok</Button>
        </DialogActions>
      </Dialog>
    )
  }

  ActionTakenFields = () => {
    return (
      <Grid style={webStyle.followUpBox}>
        <Grid container justifyContent="space-between">
          <Typography style={webStyle.label}>Action Taken</Typography>
          <img src={this.renderMicForActionTaken()}
            style={webStyle.mikeIcon}
            data-test-id="action_speechtext"
            onClick={
              async () => {
                await this.toggleListeningForActionTaken();
                this.setState({
                  action_taken: this.state.action_taken + " " + this.state.transcription,
                });
              }
            } />
        </Grid>
        <textarea
          style={webStyle.textareaStyle}
          placeholder="Action Taken"
          value={this.state.action_taken}
          onChange={(e) => this.handleActionTakenChange(e)}
          data-test-id="Action_Taken"
          className="textarea"
        />
      </Grid>
    )
  }

  calculateDate = (actionPostData: ActionTrackerPostData | null) => {
    if (actionPostData !== null) {
      const formateDate = new Date(actionPostData?.attributes?.due_date);
      const day = String(formateDate.getDate()).padStart(2, "0");
      const month = String(formateDate.getMonth() + 1).padStart(2, "0");
      const year = formateDate.getFullYear();
      return `${day}/${month}/${year}`;
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { actionPostData } = this.state;
    let formattedDate = this.calculateDate(actionPostData)

    return (
      <ThemeProvider theme={theme}>
        <Wrapper>
          {this.Headers()}
          <Typography style={webStyle.formHeader}>{configJSON.FormText}</Typography>
          {!this.state.loaderData && (
            <>
              <Paper style={webStyle.paperStyle}>
                <Grid container spacing={3} style={webStyle.marginTop}>
                  {this.locationDetails(actionPostData)}
                  {this.SupervisorDetails(actionPostData, formattedDate)}
                  {this.DescriptionDetails()}
                </Grid>

                {this.state.followUp !== "followUp" && (
                  <Grid container spacing={3} style={webStyle.marginTop}>
                    {this.state.is_supervisor && (this.ReAssignToUpdate())}
                    {this.DateUpdate()}
                    {!this.state.is_supervisor && (this.StatusUpdate())}
                  </Grid>
                )}
                {this.state.followUp === configJSON.followUpText && this.state.is_supervisor &&
                  <>
                    {this.followUpData(actionPostData)}
                    {(this.state.actionPostData?.attributes.status !== "pending_review" && this.state.actionPostData?.attributes.status !== "verified") && this.FollowUpReplyData(actionPostData)}
                    {(this.state.actionPostData?.attributes.status === "pending_review" || this.state.actionPostData?.attributes.status === "verified") && this.ActionTakenOption(actionPostData)}
                  </>
                }
                {this.state.followUp !== configJSON.followUpText && !this.state.is_supervisor &&
                  <>
                    {this.state.actionPostData?.attributes.status !== "verified" && this.state.actionPostData?.attributes.status !== "pending_review" && !this.state.isStatusChanges && this.ManagerIsFollowing(actionPostData)}
                    {((this.state.actionPostData?.attributes.status === "pending_review" || this.state.actionPostData?.attributes.status === "verified") && !this.state.isStatusChanges) && (
                      this.ActionTakenOption(actionPostData)
                    )}
                  </>
                }
                {!this.state.is_supervisor && (
                  <>
                    {(((this.state.selectedStatus === "Cancelled" || this.state.selectedStatus === "Planned" || this.state.selectedStatus === "Not My Domain") && this.state.isStatusChanges)) && (
                      <Grid style={webStyle.followUpBox}>
                        {this.RemarksInput()}
                        <textarea
                          style={webStyle.textareaStyle}
                          placeholder="Remarks"
                          value={this.state.remarks}
                          onChange={(e) => this.handleRemarkChange(e)}
                          data-test-id="Remarks"
                          className="textarea"
                        />
                      </Grid>
                    )}
                    {(this.state.selectedStatus === "Completed" && this.state.actionPostData?.attributes.status !== "verified") && (
                      this.ActionTakenFields()
                    )}
                    {(this.state.actionPostData?.attributes.status === "verified" && this.state.actionPostData?.attributes.action_taken) && (
                      <Grid item style={webStyle.completedStatusText} container alignItems="center">
                        <img src={CompletedIcon} style={webStyle.completeActionText} />
                        Completed action has been approved by manager!
                      </Grid>
                    )}
                  </>
                )}
              </Paper>
              {this.ActionButtons(actionPostData)}
              {this.ErrorPopUp()}
            </>
          )}
        </Wrapper>
        <Loader loading={this.state.loaderData} data-test-id="loader" />
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  marginTop: {
    marginTop: "10px"
  },
  calenderIcon: {
    width: 28,
    height: 28,
    cursor: "pointer",
  },
  completeActionText: {
    marginRight: '6px'
  },
  approveBtnBox: {
    padding: '10px 0px'
  },
  noMargin: {
    margin: '0px'
  },
  followUpBox: {
    marginTop: "15px"
  },
  navigateIcon: {
    fill: '#DCDCDC'
  },
  popupErrorText: {
    fontSize: '14px',
    fontFamily: 'ClotherRegular',
    color: 'rgb(243, 2, 2)',
  },
  okBtn: {
    background: '#8735E1',
    color: '#FFFFFF',
    fontSize: '12px',
    fontFamily: 'ClotherBold',
    width: '45px',
    height: '35px',
  },
  placeholderText: {
    fontFamily: 'ClotherRegular',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#DCDCDC',
    fontStyle: "normal"
  },
  completedStatusText: {
    marginTop: '20px',
    width: '370px',
    height: '42px',
    padding: '8px',
    borderRadius: '8px',
    border: '1px solid #21C274',
    background: '#21C2741A',
    color: '#21C274',
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '16px',
  },
  footerRadioG: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    marginTop: '8px'
  },
  footerRadioLabel: {
    margin: '0 0.5rem'
  },
  formControlLabel: {
    fontFamily: 'ClotherBold',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#18181E'
  },
  approveOptionText: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#2B2A29',
  },
  followDetailsBox: {
    minHeight: '149px',
    borderRadius: '8px',
    marginTop: '20px',
    border: '1px solid #DCDCDC',
  },
  datePicker: {
    borderRadius: '8px',
    height: '54px',
    fontFamily: 'ClotherBold',
    fontSize: '16px',
    lineHeight: '22px',
    paddingRight: '0px',
    paddingLeft: '0px',
  },
  input: {
    borderRadius: '8px',
    border: '1px solid #DCDCDC',
    fontFamily: 'ClotherRegular',
    backgroundColor: 'white',
    padding: '20px 20px',
  },
  inputStd: {
    height: '54px',
    width: '100%',
  },
  label: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#18181E',
    marginBottom: '8px',
  },
  label2: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#18181E',
    padding: '15px'
  },
  details: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#828282',
    padding: '15px'
  },
  ConcernLocation: {
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#2B2A29',
    marginBottom: '5px',
  },
  ConcernLocationValue: {
    fontFamily: 'ClotherBold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#2B2A29',
  },
  currentPage: {
    fontFamily: 'ClotherBold',
    fontSize: '14px',
    lineHeight: '22px',
    marginLeft: '10px',
    color: '#8735E1',
    cursor: 'pointer'
  },
  actionTrackerHeader: {
    fontFamily: 'ClotherRegular',
    fontSize: '14px',
    lineHeight: '22px',
    paddingBottom: '17px',
    color: '#18181E',
    cursor: 'pointer'
  },
  formHeader: {
    fontFamily: 'ClotherBold',
    fontSize: '24px',
    lineHeight: '24px',
    margin: '0px 0px 20px 0px',
  },
  gridStyle: {
    backgroundColor: "#F8F8F8",
    padding: "20px 15px",
    borderRadius: "8px",
    cursor: "pointer",
    boxShadow: 'none',
    minHeight: '68px',
  },
  textareaStyle: {
    padding: '15px',
    borderRadius: '8px',
    width: " 100%",
    minHeight: "178px",
    border: "1px solid #DCDCDC",
    fontFamily: 'ClotherRegular',
    fontSize: '16px',
    lineHeight: '18px',
  },
  btnStyleColor: {
    minWidth: '160px',
    height: '50px',
    padding: '10px',
    bordeRadius: '8px',
    color: "white",
    border: "1px solid #8735E1",
    textTransform: "none",
    backgroundColor: "#8735E1",
    fontFamily: 'ClotherBold',
    fontSize: '20px',
    lineHeight: '22px',
    borderRadius: '8px',
  },
  btnDiv: {
    display: "flex",
    padding: "30px 0px",
    gap: "20px",
    justifyContent: "flex-end",
  },
  paperStyle: {
    padding: "30px",
    background: "#fff",
    height: "auto",
    minHeight: "80vh",
  },
  mikeIcon: {
    cursor: 'pointer',
    height: '22px',
    width: '22px',
  }
};

const Wrapper = withStyles({
  root: {
    padding: "30px",
    background: "rgb(238, 238, 238)",
    "& .react-datepicker-wrapper": {
      display: 'flex'
    },
    "& .textarea": {
      resize: 'none',
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: 'white'
    },
    "& .MuiAutocomplete-inputRoot": {
      borderRadius: "8px",
      border: "0.5px solid #DCDCDC",
    },
  }
})(Grid)
// Customizable Area End
