import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "framework/src/Utilities";
import * as Yup from "yup";
import YupPassword from 'yup-password'
YupPassword(Yup)
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  gender: string;
  dob: Date | null;
  emailErr: string;
  isModalOpen: boolean;
  loader: boolean;
  isPrivacyPolicy: boolean;
  errorMessage: string;
  dialogModalOpen: boolean;
  countrieCode: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;

  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  registerAccountApiCallId: any;

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;
  signupSchema: object;
  getCountryCodeAPICallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      gender: "",
      dob: null,
      emailErr: "",
      isModalOpen: true,
      loader: false,
      isPrivacyPolicy: false,
      errorMessage: "",
      dialogModalOpen: false,
      countrieCode: []
      // Customizable Area End
    };

    // Customizable Area Start

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    this.signupSchema = Yup.object({
      employee_id: Yup.string().required(configJSON.empIDErrMsg).min(3, "Employee id should have minimum 3 characters"),
      full_name: Yup.string().required(configJSON.fullNameErrMsg).min(3, "Full name should have minimum 3 characters").max(50, "Full Name max length must be 50 characters"),
      mobile_number: Yup.string().matches(/^\d+$/, "Please enter a valid numeric mobile number")
        .length(10, "Please enter a 10-digit mobile number").typeError(configJSON.MobileErrMsg).required(configJSON.MobileErrMsg),
      official_email_address: Yup.string().email("Please enter valid Official email").required("Please enter valid Official email"),
      personal_email_address: Yup.string().email("Please enter valid Personal email").required("Please enter valid Personal email"),
      gender: Yup.string().required(configJSON.genderErrMsg),
      password: Yup.string().required(configJSON.errorPasswordNotValid)
        .minLowercase(1, configJSON.errorPasswordNotValid)
        .minUppercase(1, configJSON.errorPasswordNotValid)
        .minNumbers(1, configJSON.errorPasswordNotValid)
        .minSymbols(1, configJSON.errorPasswordNotValid)
        .min(8, configJSON.errorPasswordNotValid),
      dob: Yup.string().nullable().required(configJSON.dobErrMsg)
    })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.handleAPIresponse(apiRequestCallId, responseJson)
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }


    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getCountryCode();
  }
  handleAPIresponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.validationApiCallId) {
      this.arrayholder = responseJson.data;
      let regexData = this.arrayholder[0];
      this.emailReg = new RegExp(regexData.email_validation_regexp);
    } else if (apiRequestCallId === this.registerAccountApiCallId) {
      this.setState({ loader: false })
      this.registerApiSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.getCountryCodeAPICallID) {
      this.setState({ countrieCode: responseJson.country_code_and_flags?.data })
    }
  }
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos: any = this.txtInputEmailMobilePrpos

  txtPhoneNumberMobileProps = {
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps: any = this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };


  goToSignIn() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  apiCall = async (resposnse: any) => {
    const { contentType, method, endPoint, body } = resposnse
    const header1 = {
      'Content-Type': contentType,
    }
    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header1)
    )
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    runEngine.sendMessage(requestMessage1.id, requestMessage1);
    return requestMessage1.messageId;
  }

  handleSignup = async (values: any) => {
    this.setState({ loader: true })
    let body = {
      data: {
        attributes: {
          full_name: values.full_name,
          full_phone_number: values.mobile_number,
          country_code: values.country_code,
          email: values.personal_email_address,
          password: values.password,
          gender: values.gender,
          date_of_birth: values.dob,
          employee_id: values.employee_id,
          official_email: values.official_email_address,
        },
        type: "email_account",
      },
    };

    this.registerAccountApiCallId = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.apiMethodTypeAddDetail,
      endPoint: configJSON.registerApiEndPoint,
      body: body
    });
  }

  registerApiSuccessCallback = async (response: any) => {
    if (!response.errors) {
      this.saveLoggedInUserData(response);
      setStorageData('SignUpID', JSON.stringify(response.data.id));
      this.props.navigation.navigate("EditProfile")
    }
    if (response.errors) {
      if (response.errors.employee_id) {
        this.setState({ errorMessage: response.errors.employee_id, dialogModalOpen: true });
      }
      if (response.errors.full_name) {
        this.setState({ errorMessage: response.errors.full_name, dialogModalOpen: true });
      }
      if (response.errors.email && response.errors.email !== "both emails can't be same") {
        this.setState({ errorMessage: response.errors.email, dialogModalOpen: true });
      } 
      if (response.errors.official_email && response.errors.official_email !== "both emails can't be same") {
        this.setState({ errorMessage: response.errors.official_email, dialogModalOpen: true });
      }
      if (response.errors.email === "both emails can't be same" || response.errors.official_email === "both emails can't be same") {
        this.setState({ errorMessage: "Both emails can't be same", dialogModalOpen: true });
      }
      if (response.errors.length == 1 && response.errors[0].account) {
        this.setState({ errorMessage: "Domain name in official email should be apollotyres"});
      }
    }
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }
  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField
    })
  }

  setPrivacyPolicyModal = () => {
    this.setState({ isPrivacyPolicy: !this.state.isPrivacyPolicy })
  }

  handleClose = () => {
    this.setState({ dialogModalOpen: false })
  }

  getCountryCode = async () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    const getAllRequestMsgCountry = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    getAllRequestMsgCountry.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `country_codes`
    );
    getAllRequestMsgCountry.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllRequestMsgCountry.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    this.getCountryCodeAPICallID = getAllRequestMsgCountry.messageId;
    runEngine.sendMessage(getAllRequestMsgCountry.id, getAllRequestMsgCountry);
  };

  handleDobChange = (date: any, setFieldValue: any) => {
    if (date) {
      date?.setHours(date?.getHours() + 5);
      date?.setMinutes(date?.getMinutes() + 30);
      const formattedDate = date?.toISOString().replace(/(,|\s)+/g, 'T');
      setFieldValue("dob", new Date(formattedDate))
    }
  }
  // Customizable Area End
}
