import React from "react";

import {
    Container,
    Box,
    Typography,
    // Customizable Area Start
    styled,
    Grid,
    Button,
    Paper,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";

const today = new Date()

// Customizable Area End

import CheckpointDetailController, {
    Props,
} from "./CheckpointDetailController";

export default class CheckpointDetail extends CheckpointDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    managerCheckpointDetailForm = () => {
        return (
            <Box className="mainBox">
                <Grid container className="paperContent inputBlock" spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box>
                            <Typography> Related Image </Typography>
                        </Box>
                        <Box className="relatedImage">
                            <img
                                src={this.state.checkpointDetailData?.attributes?.reference_image}
                                height={"100%"}
                                width={"100%"}
                                alt="related Image"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}></Grid>

                    <Grid item xs={12} md={6}>
                        <Typography>Inspection Frequency</Typography>
                        <Paper elevation={0}>
                            <Box className="boxFont2 trimWords">{this.state.checkpointDetailData?.attributes?.inspection_frequency}</Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}></Grid>

                    <Grid item xs={12} md={6}>
                        <Box className="remarksGrid" style={{ height: "100%" }}>
                            <Box className="remarksBox1">Recommended Checks</Box>
                            <Box className="remarksBoxFont2">
                                {this.state.checkpointDetailData?.attributes?.recommended_check && this.state.checkpointDetailData?.attributes?.recommended_check.split('\n').map((value, index) => (
                                    <React.Fragment key={index}>
                                        {value} <br />
                                    </React.Fragment>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box className="remarksGrid" style={{ height: "100%" }}>
                            <Box className="remarksBox1">Actions Required</Box>
                            <Box className="remarksBoxFont2">
                                {this.state.checkpointDetailData?.attributes?.action_required && this.state.checkpointDetailData?.attributes?.action_required.split('\n').map((value, index) => (
                                    <React.Fragment key={index}>
                                        {value} <br />
                                    </React.Fragment>
                                ))}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography>Cateory</Typography>
                        <Paper elevation={0}>
                            <Box className="boxFont2 trimWords">{this.state.checkpointDetailData?.attributes?.sub_category}</Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography>Ref Standard</Typography>
                        <Paper elevation={0}>
                            <Box className="boxFont2 trimWords">{this.state.checkpointDetailData?.attributes?.reference_standard}</Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Box >
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <MainWrapper>
                        <Box className="headingWrapper">
                            <Typography className="subHeadingText" data-test-id="navigate_to_audit_screen" onClick={this.navigateToAuditScreen}>Audit</Typography>
                            <Typography className="subHeadingText">
                                <NavigateNext className="navigateNext" />
                            </Typography>
                            <Typography className="subHeadingText trimWords" data-test-id="navigate_to_macinecheckpoint_screen" onClick={this.navigateTomachinecheckpointScreen}>{this.state.selectedMachineData?.equipment_name}</Typography>
                            <Typography className="subHeadingText">
                                <NavigateNext className="navigateNext" />
                            </Typography>
                            <Typography className="subHeadingText activeText trimWords">{this.state.checkpointDetailData?.attributes?.name}</Typography>
                        </Box>
                        <Box className="checkpointTitle">
                            <Typography className="mainHeading trimWords">{this.state.checkpointDetailData?.attributes?.name}</Typography>
                        </Box>
                        {this.managerCheckpointDetailForm()}
                        <Box className="nextButton">
                            <Button
                                data-test-id="next_btn"
                                variant="contained"
                                className="add-btn"
                                onClick={() => this.navigateToWorkerCheckpoint()}
                            >
                                Next
                            </Button>
                        </Box>
                        {this.state.loading && (
                            <Box data-test-id={"activityLoader"} className="loader-outer">
                                <Box className="loader-inner">
                                    <CircularProgress />
                                </Box>
                            </Box>
                        )}
                    </MainWrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const MainWrapper = styled(Box)({
    padding: "30px",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    height: "100%",
    fontFamily: 'ClotherRegular',
    position: "relative",
    boxSizing: "border-box",
    "& .headingWrapper": {
        display: "flex",
    },
    "& .loader-outer": {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        minHeight: "100vh",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .inputBlock": {
        fontFamily: 'ClotherRegular',
    },
    "& .paperContent .MuiPaper-root": {
        backgroundColor: 'rgb(238, 238, 238)',
        border: '1px solid lightgray',
        fontSize: '16px',
        color: '#A1A1A1',
        fontWeight: 700,
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        marginTop: '5px',
        padding: '15px',
    },
    "& .relatedImage": {
        border: "1px solid lightgray",
        height: "250px",
        marginTop: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .MuiTypography-body1": {
        fontFamily: 'ClotherRegular',
        fontSize: "17px",
        fontWeight: 400,
    },
    "& .remarksGrid": {
        border: '1px solid lightgrey',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        width: '100%',
        display: "flex",
        flexDirection: "column"
    },
    "& .boxFont2": {
        fontFamily: "clotherBold",
    },
    "& .remarksBox1": {
        fontFamily: "clotherRegular",
        fontSize: '17px',
        borderBottom: "1px solid lightgrey",
        padding: '10px 15px',
    },
    "& .remarksBoxFont2": {
        fontFamily: "clotherRegular",
        color: '#828282',
        fontSize: '17px',
        fontWeight: 400,
        padding: '10px 15px',
        minHeight: "100px",
        wordWrap: "break-word"
    },
    "& .subHeadingText": {
        color: '#18181E',
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
    },
    "& .enterInputField": {
        backgroundColor: 'white',
        border: '1px solid lightgray',
        fontSize: '18px',
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        height: '60px',
        padding: '10px 15px',
        width: '96%'
    },
    "& .activeText": {
        color: "#8735E1",
        fontSize: "14px",
        lineHeight: "22px",
        fontFamily: "ClotherBold",
        cursor: "pointer"
    },
    "& .mainHeading": {
        fontSize: "26px",
        leadingTrim: "both",
        fontWeight: 700,
        color: "#2B2A29",
        textEdge: "cap",
        fontStyle: "normal",
        fontFamily: "ClotherBold",
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .checkpointTitle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0",
    },
    "& .nextButton": {
        width: "100%",
        display: "flex",
        justifyContent: "end",
        marginBottom: "10px",
    },
    "& .mainBox": {
        backgroundColor: 'white',
        borderRadius: "8px",
        padding: '20px 20px 20px 20px',
        margin: "15px 0px"
    },
    "& .add-btn": {
        height: "50px",
        boxShadow: "none",
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        width: "160px",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },

});

// Customizable Area End
