import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  styled,
  Card,
  CardContent,
  Grid,
  Chip,
  Modal,
  Select,
  MenuItem,
  InputBase,
  CircularProgress,
  Avatar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Done, Add, KeyboardArrowDown } from "@material-ui/icons";
import moment from "moment";
import { Pagination } from "@material-ui/lab";
import { buttonFilter, Calender, Profile, Location, stepLadder, closeIcon, buttonFilterActive } from "./assets";
import { ListScheduledInspectionData } from "./Repeatingtasks2Controller";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiSelect: {
      select: {
        '&:hover': {
          borderColor: "#DCDCDC"
        },
        '&:focus': {
          backgroundColor: '#fff',
          borderColor: "#DCDCDC"
        },
        '&.MuiSelect-select': {
          borderRadius: '0.5rem'
        }
      },
    }
  }
});
// Customizable Area End

import Repeatingtasks2Controller, {
  Props,
  configJSON,
} from "./Repeatingtasks2Controller";
import { webStyles } from "../../../components/src/styles";

export default class Repeatingtasks2 extends Repeatingtasks2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  plannnedInsepctionCard = (value: ListScheduledInspectionData) => {
    let chipClass;
    if (value.attributes.status === 'Due') {
      chipClass = 'orangeChip';
    } else if (value.attributes.status === "Completed") {
      chipClass = 'greenChip';
    } else if (value.attributes.status === "Overdue") {
      chipClass = 'redChip';
    } else {
      chipClass = 'blueChip';
    }
    const title = `${value.attributes.department} / ${value.attributes.location}`
    return (
      <Grid item md={4} lg={4} sm={6} style={{ width: "100%" }}>
        <Card className="cardStyle"
          onClick={() => this.navigateToDetail(value.id)}
          data-test-id="planned_inspection_card">
          <CardContent>
            <Box className="cardContentMainBox">
              <Typography className="cardMainHeading">{title}</Typography>
              <Chip className={chipClass} label={value.attributes.status} />
            </Box>
            <Box className="imageAndText">
              <Avatar
                src={value.attributes.account?.profile_image}
                alt={value.attributes.account?.full_name}
                style={{ height: '23px', width: '23px' }}
              />
              <Typography className="cardText">
                {value.attributes.assigned_to}
              </Typography>
            </Box>
            <Box className="imageAndText">
              <img src={stepLadder} alt="Profile" height={23} width={23} />
              <Typography className="cardText">
                {value.attributes.equipment_name}
              </Typography>
            </Box>
            <Box className="imageAndText">
              <img src={Location} alt="Profile" height={23} width={23} />
              <Typography className="cardText">{value.attributes.location}</Typography>
            </Box>
            <Box className="imageAndText">
              <img src={Calender} alt="Profile" height={23} width={23} />
              <Typography className="cardText">Due Date: {moment(value.attributes.inspection_date).format('DD/MM/YYYY')}</Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>)
  }

  renderFilterModal = () => {
    const { openFilterModal, selectedStatus, selectedLocation, locations, selectedMachine, machines } = this.state;
    const status = ["Planned", "Due", "Overdue", "Completed", "Cancelled"]
    return (

      <Modal open={openFilterModal} onClose={this.closeFilterModal}>
        <Filters>
          <Box className="filters-modal-header">
            <Typography>Filters</Typography>
            <img
              style={{ cursor: "pointer" }}
              data-test-id="close_filter"
              src={closeIcon}
              alt="close"
              onClick={this.closeFilterModal}
            />
          </Box>

          <Box className="filters-modal-body">
            <Box className="risk-card">
              <Typography className="status-card-title">
                Status
              </Typography>
              <Button
                data-test-id="status_clear"
                className="clear-btn"
                onClick={this.handleStatusClear}
              >
                clear
              </Button>
            </Box>
            <span>
              {status.map((value, index) => (
                <label key={index} className="filters-label">
                  <input
                    data-test-id="status-options"
                    id={`checkbox_${value}`}
                    onChange={(event) =>
                      this.handleStatusChange(event, value)
                    }
                    type="checkbox"
                    name={value}
                    checked={selectedStatus.includes(value)}
                  />
                  <span>
                    {selectedStatus.includes(value) ? (
                      <Done className="selectedIcon" />
                    ) : (
                      <Add className="selectedIcon" />
                    )}{" "}
                    {value}{" "}
                  </span>
                </label>
              ))}
            </span>
          </Box>
          <Box className="filters-modal-body">
            <Box className="risk-card">
              <Typography className="status-card-title">
                Location
              </Typography>
              <Button
                data-test-id="location_clear"
                className="clear-btn"
                onClick={this.handleSelectedLocationClear}
              >
                clear
              </Button>
            </Box>
            <Select
              labelId="multi-select-dropdown-label"
              data-test-id="select_location"
              id="multi-select-dropdown"
              IconComponent={KeyboardArrowDown}
              variant="outlined"
              displayEmpty
              value={selectedLocation}
              className="location-dropdown"
              onChange={(event) => this.handleLocationsChange(event.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={(selected) => (
                <div style={{ color: "#A8A0A0" } as React.CSSProperties}>
                  Location
                </div>
              )}
              input={<InputBase />}
            >
              {locations.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.attributes.name.length > 25 ? `${option.attributes.name.substring(0, 25)}...` : option.attributes.name}
                </MenuItem>
              ))}
            </Select>
            <span>
              {selectedLocation.map((value) => (
                <label key={value.id} className="location-chip">
                  <span>
                    <Done className="selectedIcon" />
                    {" "}
                    {value.attributes.name.length > 25 ? `${value.attributes.name.substring(0, 25)}...` : value.attributes.name}
                    {" "}
                  </span>
                </label>
              ))}
            </span>
          </Box>
          <Box className="filters-modal-body" style={{ borderBottom: "none" }}>
            <Box className="risk-card">
              <Typography className="status-card-title">
                Machine/Tool
              </Typography>
              <Button
                data-test-id="machine_clear"
                className="clear-btn"
                onClick={this.handleSelectedMachinesClear}
              >
                clear
              </Button>
            </Box>
            <Select
              labelId="multi-select-dropdown-label"
              id="multi-select-dropdown"
              data-test-id="select_machine"
              IconComponent={KeyboardArrowDown}
              variant="outlined"
              displayEmpty
              value={selectedMachine}
              className="location-dropdown"
              onChange={(event) => this.handleMachineChange(event.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              renderValue={(selected) => (
                <div style={{ color: "#A8A0A0" } as React.CSSProperties}>
                  Machine/Tool
                </div>
              )}
              input={<InputBase />}
            >
              {machines.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.attributes.name.length > 25 ? `${option.attributes.name.substring(0, 25)}...` : option.attributes.name}
                </MenuItem>
              ))}
            </Select>
            <span>
              {selectedMachine.map((value) => (
                <label key={value.id} className="location-chip">
                  <span>
                    <Done className="selectedIcon" />
                    {" "}
                    {value.attributes.name.length > 25 ? `${value.attributes.name.substring(0, 25)}...` : value.attributes.name}
                    {" "}
                  </span>
                </label>
              ))}
            </span>
          </Box>
          <Box className="bottom-wrapper">
            <Button
              data-test-id="clear_all"
              variant="contained"
              className="clear-all-btn"
              onClick={this.handleClearAll}
            >
              Clear All
            </Button>
            <Button
              data-test-id="apply-filter"
              variant="contained"
              className="apply-btn"
              onClick={this.applyFiters}
            >
              Apply
            </Button>
          </Box>
        </Filters>
      </Modal >
    )
  }

  // Customizable Area End

  render() {
    const { scheduleInspectionData, totalCount, currentPage, loading, openFilterModal, finalSelectedLocation, finalSelectedMachine, finalSelectedStatus } = this.state;
    const filterActive = openFilterModal || finalSelectedLocation.length || finalSelectedMachine.length || finalSelectedStatus.length;

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Wrapper>
          <Box style={webStyles.breadcrumbWrapper}>
            <Typography style={webStyles.breadcrumbItem}>Planned Inspection</Typography>
          </Box>
          <Grid container xs={12} sm={12} className="myPostsTitle">
            <Grid>
              <Typography className="mainHeading">Planned Inspection</Typography>
            </Grid>
            <Grid>
              <Box className="filter-wrapper">
                <Button
                  data-test-id="schedule_inspection"
                  className="ScheduleButtonStyle"
                  type="submit"
                  onClick={() => this.handleScheduleInspection()}
                >
                  Schedule an Inspection
                </Button>
                <img
                  data-test-id="filter"
                  style={{ cursor: 'pointer' }}
                  height="36px"
                  width="36px"
                  src={filterActive ? buttonFilterActive : buttonFilter}
                  alt="filter"
                  onClick={this.handleOpenFilterModal}
                />
              </Box>
            </Grid>
          </Grid>
          <Box >
            <Grid container style={{ display: "flex", gridRowGap: "12px" }}>
              {scheduleInspectionData.length > 0 && scheduleInspectionData?.map((val) => {
                return this.plannnedInsepctionCard(val);
              })}
              {scheduleInspectionData.length === 0 && !loading &&
                <Box className="noData">
                  <Typography>No Data Available.</Typography>
                </Box>
              }
            </Grid>
            {scheduleInspectionData.length > 0 &&
              <Grid style={{ display: "flex", justifyContent: "end" }}>
                <Pagination
                  count={Math.ceil(totalCount / 10)}
                  page={currentPage}
                  variant="outlined"
                  style={{ marginTop: "20px" }}
                  onChange={this.handlePageChange}
                  data-test-id="handlePageChange"
                />
              </Grid>}
          </Box>

          {this.renderFilterModal()}

          {loading && (
            <Box data-test-id={"activityLoader"} className="loader-outer">
              <Box className="loader-inner">
                <CircularProgress />
              </Box>
            </Box>
          )}

        </Wrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
  width: "100%",
  backgroundColor: "#f7f5f5",
  padding: "30px",
  boxSizing: "border-box",
  height: "calc(100vh - 100px)",
  overflow: "auto",
  position: "relative",
  "& .loader-outer": {
    position: "absolute",
    left: 0,
    bottom: 0,
    height: "180%",
    top: 0,
    right: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  "& .loader-inner": {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .headingWrapper": {
    display: "flex",
    gap: "10px",
    marginTop: '10px'
  },
  "& .subHeadingText": {
    color: '#18181E',
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherRegular",
  },
  "& .myPostsTitle": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px 20px 0px",
  },
  "& .mainHeading": {
    margin: "10px 0px",
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "26px",
    fontStyle: "normal",
    lineHeight: "25px",
    fontFamily: "ClotherBold",
  },
  "& .filter-wrapper": {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
  "& .noData": {
    textAlign: "center",
    margin: "20px 12px",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    fontSize: "18px"
  },
  "& .ScheduleButtonStyle": {
    border: "1px solid #8735E1",
    textTransform: "none",
    padding: "20px 12px",
    fontSize: "16px",
    fontFamily: "ClotherBold",
    height: "30px",
    color: "#8735E1",
    borderRadius: "8px",
    marginRight: "10px",
    "@media(max-width:601px)": {
      lineHeight: "15px",
      height: "45px"
    },
  },
  "& .cardContentMainBox": {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px"
  },
  "& .cardStyle": {
    cursor: "pointer",
    padding: "4px 2px",
    background: "#fff",
    border: "1.5px solid lightgray",
    boxShadow: "none",
    borderRadius: "8px",
    marginRight: "12px",
  },
  "& .cardMainHeading": {
    fontSize: "16px",
    fontFamily: "ClotherBold",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& .cardText": {
    color: "#A1A1A1",
    fontSize: "17px",
    fontFamily: "ClotherRegular",
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "& .imageAndText": {
    display: "flex",
    gap: 15,
    marginTop: 10
  },
  "& .blueChip": {
    backgroundColor: "#DDF6FF",
    color: "#00b0f0",
    borderRadius: "15px",
    height: "24px",
    "& .MuiChip-label": {
      padding: "-1px 5px",
      fontFamily: "ClotherBold",
      fontSize: "16px",
    }
  },
  "& .redChip": {
    backgroundColor: "#FFEDED",
    color: "#FA0000",
    height: "24px",
    borderRadius: "15px",
    "& .MuiChip-label": {
      padding: "-1px 5px",
      fontWeight: 600
    }
  },
  "& .greenChip": {
    backgroundColor: "#DBFFED",
    height: "24px",
    color: "#009A4F",
    borderRadius: "15px",
    "& .MuiChip-label": {
      padding: "-1px 5px",
      fontWeight: 600
    }
  },
  "& .orangeChip": {
    backgroundColor: "#FFF4DE",
    color: "#DF9300",
    height: "24px",
    borderRadius: "12px",
    "& .MuiChip-label": {
      padding: "-1px 5px",
      fontWeight: 600
    }
  },
})
export const Filters = styled(Box)({
  maxWidth: "720px",
  width: "80%",
  height: "auto",
  overflow: "auto",
  background: "#fff",
  maxHeight: "90vh",
  borderRadius: "8px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "& .filters-modal-header": {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 30px 0px",
    alignItems: "center",
    "& p": {
      color: "#2B2A29",
      fontFamily: "ClotherBold",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: 700,
      textTransform: "capitalize",
    },
  },
  "& .filters-modal-body": {
    padding: "15px 15px 15px 30px",
    borderBottom: "1px solid #EEE",
    maxHeight: '280px',
    overflow: 'auto'
  },
  "& .filters-label span": {
    cursor: 'pointer',
    borderRadius: "8px",
    border: "1px solid #DCDCDC",
    background: "#FFF",
    padding: "12px",
    color: "#A1A1A1",
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    display: "inline-block",
    margin: "0 10px 10px 0",
  },
  "& .Location-chip span": {
    padding: "9px 14px 11px 9px",
    color: "#FFF",
    fontFamily: "ClotherRegular",
    fontSize: "18px",
    borderRadius: "10px",
    border: "1px solid #DCDCDC",
    backgroundColor: "rgb(135, 53, 225)",
    fontStyle: "normal",
    fontWeight: 700,
    display: "inline-block",
    margin: "0 10px 10px 0",
  },
  "& input:checked+span": {
    background: "#8735E1 !important",
    borderColor: "#8735E1 !important",
    color: "#fff !important",
  },
  "& .filters-label": {
    position: "relative",
  },
  "& .filters-modal-body input": {
    position: "absolute",
    visibility: "hidden",
  },
  "& .status-card-title": {
    fontSize: "18px",
    color: "#000",
    fontFamily: "ClotherRegular",
    fontStyle: "normal",
    fontWeight: 400,
  },
  "& .risk-card": {
    paddingBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .borderRed": {
    borderColor: "red !important",
    color: "red !important",
  },
  "& .risk-level-body": {
    border: "0",
    paddingTop: "20px",
  },
  "& .borderYellow": {
    borderColor: "#FFD541 !important",
    color: "#FFD541 !important",
  },
  "& .borderGreen": {
    borderColor: "#21C274 !important",
    color: "#21C274 !important",
  },
  "& .clear-btn": {
    fontSize: "14px",
    fontStyle: "normal",
    color: "#8735E1",
    fontFamily: "ClotherBold",
    fontWeight: 700,
    textTransform: "capitalize",
    "&:hover": {
      background: "#fff",
    },
  },
  "& .bottom-wrapper": {
    display: "flex",
    justifyContent: "end",
    width: "100%",
    padding: "0px 30px 30px 30px",
    boxSizing: "border-box",
  },
  "& .apply-btn": {
    color: "#FFF",
    textAlign: "center",
    fontFamily: "ClotherRegular",
    borderRadius: "8px",
    background: "#8735E1",
    width: "160px",
    height: "50px",
    padding: "10px",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
    boxShadow: "none",
  },
  "& .location-dropdown": {
    marginBottom: "20px",
    border: '1px solid lightgray',
    fontFamily: 'ClotherRegular',
    width: "97%",
    borderRadius: "8px",
    backgroundColor: 'white',
    height: '50px',
    padding: '10px 15px'
  },
  "& .clear-all-btn": {
    fontFamily: "ClotherRegular",
    fontSize: "20px",
    fontStyle: "normal",
    borderRadius: "8px",
    border: "1px solid #8735E1",
    textTransform: "capitalize",
    marginRight: "10px",
    background: "#FFF",
    color: "#8735E1",
    textAlign: "center",
    fontWeight: 700,
    padding: "10px",
    width: "160px",
    height: "50px",
    boxShadow: "none",
  },
  "& .selectedIcon": {
    fontSize: "15px",
    verticalAlign: "middle",
  },
});
// Customizable Area End
