import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    Typography,
    // Customizable Area Start
    styled,
    Grid,
    Select,
    InputBase,
    MenuItem,
    CircularProgress
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext, KeyboardArrowDown } from "@material-ui/icons";
import { upload } from "./assets";
// Customizable Area End

import ManagerCheckpointController, {
    Props,
    configJSON,
} from "./ManagerCheckpointController";

export default class ManagerCheckpoint extends ManagerCheckpointController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    getReferenceImageSrc = () => {
        return this.state.uploadedImagePreview || upload;
    }

    managerCheckpointForm = () => {
        const { saveClicked, selectedInspectionFrequency, checkpoint, errorMessageReferenceImage, referenceImage, recommendedChecks, actionsRequired, selectedSubCategory, subCategories, selectedReferenceStandard, referenceStandard } = this.state;
        const inspectionFrequency = ["Daily", "Weekly", "Monthly", "Quarterly", "Half_yearly", "Yearly"];
        return (
            <Box className="mainBox">
                <Grid md={12} className="mainGrid">

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Checkpoint</Typography>
                        <Input
                            name="checkpoint"
                            data-test-id="checkpoint"
                            className="enterInputField"
                            value={checkpoint}
                            disableUnderline
                            placeholder="Checkpoint"
                            autoComplete="off"
                            onChange={this.handleChangeCheckpoint}
                        />
                        <Typography className="errorStyle">
                            {saveClicked && !checkpoint && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="inputLabel"> Inspection Frequency </Typography>
                        </Box>
                        <Select
                            data-test-id="inspection_frequency"
                            className="enterInputField"
                            inputProps={{
                                icon: {
                                    left: 0,
                                },
                            }}
                            variant="outlined"
                            IconComponent={KeyboardArrowDown}
                            value={selectedInspectionFrequency}
                            displayEmpty
                            renderValue={
                                selectedInspectionFrequency
                                    ? undefined
                                    : () => (
                                        <div style={{ color: "#DCDCDC", fontSize: 16 } as React.CSSProperties}>
                                            Select Inspection Frequency
                                        </div>
                                    )
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={this.handleChangeInspectionFrequency}
                            input={<InputBase />}
                        >
                            {inspectionFrequency.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography className="errorStyle">
                            {saveClicked && !selectedInspectionFrequency && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Reference Image</Typography>
                        <Button component="label" className="uploadImage">
                            <div className="upload-img">
                                <img
                                    src={this.getReferenceImageSrc()}
                                    alt="upload image"
                                    className="uploadedImg"
                                />
                            </div>
                            {!this.state.referenceImage && (
                                <>
                                    <div className="uploadImageFont">Upload Image</div>
                                </>
                            )}
                            <input
                                data-test-id="upload_referenceImage"
                                type="file"
                                name="image"
                                accept="image/png, image/jpeg"
                                onChange={(event) => {
                                    this.handleImageChange(event);
                                }}
                            />
                        </Button>
                        <Typography className="errorStyle">
                            {errorMessageReferenceImage.length > 0 && errorMessageReferenceImage}
                            {saveClicked && !referenceImage && errorMessageReferenceImage.length === 0 
                            && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid md={6} xs={12}>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Recommended Checks</Typography>
                        <textarea
                            name="recommendedChecks"
                            data-test-id="recommended_checks"
                            className="remarksTextarea"
                            value={recommendedChecks}
                            placeholder="Add Recommended Checks"
                            onChange={this.handleChangeRecommendedChecks}
                        />
                        <Typography className="errorStyle">
                            {saveClicked && !recommendedChecks && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Typography className="inputLabel">Actions Required</Typography>
                        <textarea
                            name="actionsRequired"
                            data-test-id="actions_required"
                            className="remarksTextarea"
                            value={actionsRequired}
                            placeholder="Actions Required"
                            onChange={this.handleChangeActionsRequired}
                        />
                        <Typography className="errorStyle">
                            {saveClicked && !actionsRequired && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="inputLabel"> Category </Typography>
                        </Box>
                        <Select
                            data-test-id="category"
                            className="enterInputField"
                            inputProps={{
                                icon: {
                                    left: 0,
                                },
                            }}
                            variant="outlined"
                            IconComponent={KeyboardArrowDown}
                            value={selectedSubCategory}
                            displayEmpty
                            renderValue={
                                selectedSubCategory
                                    ? undefined
                                    : () => (
                                        <div style={{ color: "#DCDCDC", fontSize: 16 } as React.CSSProperties}>
                                            Select Category
                                        </div>
                                    )
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={this.handleChangeCategory}
                            input={<InputBase />}
                        >
                            {subCategories.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography className="errorStyle">
                            {saveClicked && !selectedSubCategory && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                    <Grid className="inputBlock" md={6} xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className="inputLabel"> Reference Standard </Typography>
                        </Box>
                        <Select
                            data-test-id="reference_standard"
                            className="enterInputField"
                            inputProps={{
                                icon: {
                                    left: 0,
                                },
                            }}
                            variant="outlined"
                            IconComponent={KeyboardArrowDown}
                            value={selectedReferenceStandard}
                            displayEmpty
                            renderValue={
                                selectedReferenceStandard
                                    ? undefined
                                    : () => (
                                        <div style={{ color: "#DCDCDC", fontSize: 16 } as React.CSSProperties}>
                                            Select Reference Standard
                                        </div>
                                    )
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                            }}
                            onChange={this.handleChangeReferenceStandard}
                            input={<InputBase />}
                        >
                            {referenceStandard.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Typography className="errorStyle">
                            {saveClicked && !selectedReferenceStandard && configJSON.errorMsg}
                        </Typography>
                    </Grid>

                </Grid>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        const { selectedMachineData } = this.state;
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <ManagerWrapper>
                        <Container maxWidth={"xl"}>
                            <Box className="headingWrapper">
                                <Typography className="subHeadingText" data-test-id="navigate_to_audit" onClick={this.navigateToAudit}>Audit</Typography>
                                <Typography className="subHeadingText">
                                    <NavigateNext className="navigateNext" />
                                </Typography>
                                <Typography className="subHeadingText activeHeadingText trimWords">{selectedMachineData?.equipment_name}</Typography>
                            </Box>
                            <Box className="checkpointTitle">
                                <Typography className="mainHeading trimWords">{selectedMachineData?.equipment_name}</Typography>
                            </Box>

                            {this.managerCheckpointForm()}
                            <Box className="buttonsMainBox">
                                <Box>
                                    <Button
                                        data-test-id="previous_btn"
                                        variant="contained"
                                        className="PreviousBtn"
                                    onClick={this.handlePreviousButton}
                                    >
                                        Previous
                                    </Button>
                                </Box>
                                <Box>
                                    <Button
                                        data-test-id="save_btn"
                                        variant="contained"
                                        className="add-btn"
                                        disabled={this.state.disableSaveButton}
                                        onClick={this.handleSaveButton}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                            {this.state.loading && (
                                <Box data-test-id={"activityLoader"} className="loader-outer">
                                    <Box className="loader-inner">
                                        <CircularProgress />
                                    </Box>
                                </Box>
                            )}
                        </Container>
                    </ManagerWrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const ManagerWrapper = styled(Box)({
    boxSizing: "border-box",
    height: "100%",
    padding: "20px 0px",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    position: "relative",
    "& .loader-outer": {
        height: "100%",
        minHeight: "100vh",
        top: 0,
        position: "absolute",
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
        left: 0,
        bottom: 0,
        right: 0,
    },
    "& .loader-inner": {
        top: "40%",
        position: "absolute",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    "& .uploadImage": {
        backgroundColor: 'white',
        height: '150px',
        borderRadius: '8px',
        textTransform: 'capitalize',
        border: '1px solid lightgray',
        fontFamily: 'ClotherRegular',
        padding: '10px 15px',
        width: '100%',
        "& .MuiButton-label": {
            diplay: 'flex',
            flexDirection: 'column',
        },
    },
    "& .subHeadingText": {
        fontStyle: "normal",
        leadingTrim: "both",
        textEdge: "cap",
        fontWeight: 400,
        lineHeight: "22px",
        fontFamily: "ClotherRegular",
        color: "#A1A1A1",
        fontSize: "14px",
        cursor: "pointer"
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .uploadedImg": {
        maxWidth: '5rem',
        maxHeight: '5rem',
        objectFit: 'contain'
    },
    "& .enterInputField": {
        backgroundColor: 'white',
        height: '50px',
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontSie: '18px',
        fontFamily: 'ClotherRegular',
        padding: '10px 15px',
        width: '100%'
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .remarksTextarea": {
        borderRadius: '8px',
        fontFamily: 'ClotherRegular',
        backgroundColor: 'white',
        border: '1px solid lightgray',
        resize: 'none',
        userDrag: 'none',
        fontSize: '18px',
        fontWeight: 700,
        height: '150px',
        padding: '10px 15px',
        width: '100%'
    },
    "& .headingWrapper": {
        gap: "10px",
        display: "flex",
    },
   "& .inputLabel": {
        fontWeight: 600,
        fontFamily: 'ClotherRegular',
        color: 'var(--Black, #18181E)',
        fontSize: '18px',
        marginBottom: '5px'
    },
    "& .activeHeadingText": {
        textEdge: "cap",
        fontSize: "14px",
        lineHeight: "22px",
        fontStyle: "normal",
        fontWeight: 700,
        leadingTrim: "both",
        color: "#8735E1",
        fontFamily: "ClotherRegular",
        cursor: "pointer"
    },
    "& .uploadImageFont": {
        fontSize: '16px',
        fontFamily: 'ClotherRegular',
        color: 'lightgray',
    },
    "& .buttonsMainBox": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "25px",
    },
    "& .mainHeading": {
        fontSize: "26px",
        leadingTrim: "both",
        fontWeight: 700,
        color: "#2B2A29",
        textEdge: "cap",
        fontFamily: "ClotherBold",
        fontStyle: "normal",
    },
    "& .noData": {
        marginTop: "20px",
        textAlign: "center",
        fontSize: "18px"
    },
    "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8735E1",
    },
    "& .checkpointTitle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "10px 0",
    },
    "& .field-input": {
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        borderRadius: '8px',
        border: '1px solid lightgray',
        height: '50px',
        width: '100%',
        padding: '10px 15px',
    },
    "& .MuiInputBase-input": {
        height: "15px",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: '#fff'
    },
    "& .Location-input .MuiInputBase-input": {
        height: "14px",
    },
    "& .textarea-input, .textarea-input:focus-visible": {
        borderRadius: '8px',
        height: '152px',
        padding: '10px 15px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        width: '100%',
        outline: 'none'
    },
    "& .MuiInputBase-root": {
        fontFamily: "ClotherBold",
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px"
    },
    "& .submit-button": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        marginBottom: "10px",
    },
    "& .errorStyle": {
        color: "#F30202",
        fontSize: "0.85rem",
        marginBottom: "0.5rem",
    },
    "& .inputBlock": {
        maxWidth: '48%',
        marginBottom: "10px",
        "@media (max-width: 600px)": {
            maxWidth: '100%',
        }
    },
    "& .react-datepicker-wrapper": {
        display: 'flex',
    },
    "& .mainGrid": {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .mainBox": {
        backgroundColor: 'white',
        borderRadius: "8px",
        margin: "15px 0px",
        width: "100%",
    },
    "& .add-btn": {
        width: "160px",
        height: "50px",
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        boxShadow: "none",
        borderRadius: "8px",
        background: "#8735E1",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .PreviousBtn": {
        background: "#FFF",
        padding: "10px",
        color: "#8735E1",
        textAlign: "center",
        borderRadius: "8px",
        border: "1px solid #8735E1",
        width: "160px",
        height: "50px",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        boxShadow: "none",
        textTransform: "capitalize",
    },
    "& *::-ms-input-placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
    "& *::placeholder": {
        color: "#DCDCDC !important",
        fontSize: "18px",
        fontWeight: 400
    },
    "& input::placeholder": {
        color: "#A8A0A0 !important",
        fontSize: "16px"
    },
});

// Customizable Area End
