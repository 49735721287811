// Customizable Area Start
import React from "react";
import {
  Box,
  Typography,
  Grid,
  ThemeProvider,
  Tooltip,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import {
  tabIconUser,
  tabIconBag,
  tabIconPhone,
  account,
  posts,
  help,
  imgh,
  copyIcon,
} from "../assets";
import MobileFooterMenu from "../MobileFooterMenu.web";
import ProfileController, { Props } from "../ProfileController.web";
import { Formik } from "formik";
import { EditStep1 } from "./EditStep1";
import { EditSteps } from "./types";
import { EditStep2 } from "./EditStep2";
import { EditStep3 } from "./EditStep3";
import RiskStep from "../../src/RiskStep.web";
import { theme, webStyles } from "../../../../components/src/styles";
import PersonalGoal from "../PersonalGoalStep.web";
import CongratsModalStep from "../CongratesModalStep.web";
import { NavigateNext } from "@material-ui/icons";
export const configJSON = require("../config.js");
import RiskLevels from "../RiskLevels.web";
// Customizable Area End
export default class MyProfile extends ProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.setStep = this.setStep.bind(this);
    // Customizable Area End
  }
  // Customizable Area Start

  renderStepIdentifier = (stepNumber: any, title: any, icon: any) => {
    const isStep1 = this.state.step === EditSteps.Step1;
    const isStep2 = this.state.step === EditSteps.Step2;
    const isStep3 = this.state.step === EditSteps.Step3;
    const isActive =
      (isStep1 && stepNumber === 1) ||
      (isStep2 && stepNumber === 2) ||
      (isStep3 && stepNumber === 3);

    return (
      <>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          style={{
            ...(webStyles.stepIdentifier as React.CSSProperties),
            border: isActive ? `2px solid var(--Purple, #8735E1)` : "none",
          }}
        >
          <img src={icon} alt={`${title} icon`} />
          <Typography
            style={{
              fontWeight: isActive ? 700 : 400,
              textAlign: "center",
            }}
          >
            {title}
          </Typography>
        </Box>
      </>
    );
  };

  getStepTitle = (activeStep: any) => {
    switch (activeStep) {
      case 1:
        return "Personal Particulars";
      case 2:
        return "Broad Risk Categories";
      case 3:
        return "My Goals";
      default:
        return null;
    }
  };

  renderStepContent() {
    let stepContent;

    switch (this.state.activeStep) {
      case 2:
        stepContent = (
          <Grid container spacing={3} style={{ marginTop: "1.25rem" }}>
            <RiskStep
              navigation={this.props.navigation}
              activeStep={this.activeStepFun}
              privousStep={this.prviousStepFun}
              gotoLast={this.state.goToLast}
              notGoToPrevious={() => {
                this.setState({ goToLast: false });
              }}
            />
          </Grid>
        );
        break;
      case 3:
        stepContent = (
          <Grid container spacing={3} style={{ marginTop: "1.25rem" }}>
            <PersonalGoal
              navigation={this.props.navigation}
              privousStepFun={this.prviousStepFunPersonalGoal}
            />
          </Grid>
        );
        break;
      case 4:
        stepContent = (
          <Grid container spacing={3} style={{ marginTop: "1.25rem" }}>
            <RiskLevels
              navigation={this.props.navigation}
              activeStep={this.activeStepFun}
              privousStep={this.prviousStepFun}
              gotoLast={this.state.goToLast}
              notGoToPrevious={() => {
                this.setState({ goToLast: false });
              }}
            />
          </Grid>
        );
        break;
      default:
        stepContent = null;
    }

    return stepContent;
  }

  getHeadingText = () => {
    if (this.state.activeStep === 1) {
      return "Help us with your basic details to make your profile on U1st! Exciting journey ahead.";
    } else if (this.state.activeStep === 2) {
      return "Amazing! You reached here. Help U1st by sharing your applicable risk categories on Road, Home &  Work. Get insightful micro learning content.";
    } else if (this.state.activeStep === 3) {
      return "Marvellous ! Now add your personal Health, Safety & Environment Goals. For your wellbeing, Use U1st to keep track on your commitments.";
    }
  }

  getStepColor = () => {
    if (this.state.activeStep === 1) {
      return "#8735E1";
    } else if (this.state.activeStep === 2) {
      return "#21C274";
    } else if (this.state.activeStep === 3) {
      return "#21C274";
    } else if (this.state.activeStep === 4) {
      return "#21C274";
    } else {
      return "#A1A1A1";
    }
  };

  getColorStep2 = () => {
    if (this.state.activeStep === 2) {
      return "#8735E1";
    } else if (this.state.activeStep === 3) {
      return "#21C274";
    } else if (this.state.activeStep === 4) {
      return "#21C274";
    } else {
      return "#A1A1A1";
    }
  };

  getColorStep3 = () => {
    if (this.state.activeStep === 3) {
      return "#8735E1";
    } else if (this.state.activeStep === 4) {
      return "#21C274";
    } else {
      return "#A1A1A1";
    }
  };

  getgrayColor = () => {
    if (this.state.activeStep === 2) {
      return "#21C274";
    } else if (this.state.activeStep === 3) {
      return "#21C274";
    } else if (this.state.activeStep === 4) {
      return "#21C274";
    } else {
      return "#eee";
    }
  };

  getgrayColor2 = () => {
    if (this.state.activeStep === 3) {
      return "#21C274";
    } else if (this.state.activeStep === 4) {
      return "#21C274";
    } else {
      return "#eee";
    }
  };

  getgrayColorUnderline = () => {
    if (this.state.activeStep === 4) {
      return "#21C274";
    } else {
      return "#eee";
    }
  };

  renderStepSeparator = () => (
    <div
      style={{
        width: "3.5rem",
        height: "1px",
        backgroundColor: "rgb(135 53 225)",
        marginTop: "56px",
      }}
    />
  );

  renderSteps = () => {
    if (this.state.activeStep === 1) {
      return (
        <>
          {this.renderStepIdentifier(1, "Personal Details", tabIconUser)}
          {this.renderStepSeparator()}
          {this.renderStepIdentifier(2, "Work Details", tabIconBag)}
          {this.renderStepSeparator()}
          {this.renderStepIdentifier(3, "Emergency Details", tabIconPhone)}
        </>
      );
    }
    return null;
  };

  mapAttribute = (attribute: any, defaultValue = "") => {
    return this.state.editProfileData?.attributes?.[attribute] ?? defaultValue;
  };

  transformDate = (dateString: any) => {
    return dateString ? new Date(dateString) : null;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const isPhone = window.innerWidth < 600;
    const isWide = !isPhone;

    const sideBarItems = [
      {
        img: imgh,
        name: "Home",
        link: "Dashboard",
      },
      {
        img: posts,
        name: "My Posts",
      },
      {
        img: account,
        name: "Account",
      },
      {
        img: help,
        name: "Help",
      },
    ];

    const isStep1 = this.state.step === EditSteps.Step1;
    const isStep2 = this.state.step === EditSteps.Step2;
    const isStep3 = this.state.step === EditSteps.Step3;

    const isStepComp = this.state.step === EditSteps.StepComp;
    const { activeStep } = this.state;

    const stepColor = this.getStepColor();
    const step2Color = this.getColorStep2();
    const step3Color = this.getColorStep3();

    const step1Points = this.state.activeStep > 1 ? "10 pts" : "";
    const step2Points = this.state.activeStep > 2 ? "10 pts" : "";
    const marginBottom = step1Points ? "20px" : "0px";

    const step2ColorChanged = this.getgrayColor();
    const step3ColorChanged = this.getgrayColor2();
    const step2ColorChangedUnderline = this.getgrayColorUnderline();
    const stepActiveColor = activeStep === 4 ? "#8735E1" : "#A1A1A1";

    return this.state.isLoading ? (
      <Backdrop style={{ color: "#fff", zIndex: 1 }} open={this.state.isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (
      <div className="my-profile">
        <style>
          {`.my-profile * {
              font-family: 'ClotherRegular';
             
          }`}
        </style>
        <Box style={{ ...webStyles.breadcrumbWrapper, ...{ marginLeft: '30px', marginTop: '30px' } }}>
          <Typography data-test-id="home-link" style={webStyles.breadcrumbItem} onClick={() => { this.breadcrumbRedirect('Dashboard') }}>{configJSON.home}</Typography>
          <NavigateNext style={webStyles.navigateIcon} />
          <Typography data-test-id="profile-link" style={webStyles.breadcrumbItem} onClick={() => { this.breadcrumbRedirect('MyProfile') }}>{configJSON.Profile}</Typography>
          <NavigateNext style={webStyles.navigateIcon} />
          <Typography style={webStyles.breadcrumbActiveItem}>{configJSON.Edit_Profile}</Typography>
        </Box>
        <Grid container style={{ background: "rgb(238, 238, 238)" }}>
          <Grid item>
            <Box p={{ sm: 4 }}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                style={
                  {
                    ...webStyles.profileContainer,
                    ...(isWide && { backgroundColor: "#fff" }),
                  } as React.CSSProperties
                }
              >
                {isWide && (
                  <>
                    <Box
                      display="flex"
                      gridGap="0.5rem"
                      justifyContent="center"
                      alignItems="center"
                      padding="2rem 0"
                      borderBottom="1px solid #eee"
                      margin="auto"
                    >
                      <Box>
                        <Box display="flex" gridGap="0.5rem">
                          <Box
                            height="1.75rem"
                            width="1.75rem"
                            borderRadius="50%"
                            style={
                              {
                                ...webStyles.numberBullet,
                                backgroundColor: stepColor,
                              } as React.CSSProperties
                            }
                          >
                            1
                          </Box>
                          <Box
                            style={{
                              fontSize: "20px",
                              color: stepColor,
                            }}
                          >
                            Personal Particulars
                          </Box>
                        </Box>
                        {step1Points && <Box
                          style={{
                            fontSize: "14px",
                            color: stepColor,
                          }}>{step1Points}</Box>}
                      </Box>
                      <div
                        style={{
                          width: "3.5rem",
                          height: "0.125rem",
                          backgroundColor: step2ColorChanged,
                          marginTop: "5px",
                          marginBottom: marginBottom
                        }}
                      />
                      <Box marginBottom={step1Points && !step2Points ? "20px" : "0px"}>
                        <Box display="flex" gridGap="0.5rem">
                          <Box
                            height="1.75rem"
                            width="1.75rem"
                            borderRadius="50%"
                            style={
                              {
                                ...webStyles.numberBullet,
                                backgroundColor: step2Color,
                              } as React.CSSProperties
                            }
                          >
                            2
                          </Box>
                          <Box
                            style={{
                              fontSize: "20px",
                              color: step2Color,
                            }}
                          >
                            Risk Exposure
                          </Box>
                        </Box>
                        {step2Points && <Box
                          style={{
                            fontSize: "14px",
                            color: step2Color,
                          }}>{step2Points}</Box>}
                      </Box>
                      <div
                        style={{
                          width: "3.5rem",
                          height: "0.125rem",
                          backgroundColor: step3ColorChanged,
                          marginTop: "5px",
                          marginBottom: marginBottom
                        }}
                      />
                      <Box marginBottom={marginBottom}>
                        <Box display="flex" gridGap="0.5rem">
                          <Box
                            height="1.75rem"
                            width="1.75rem"
                            borderRadius="50%"
                            style={
                              {
                                ...webStyles.numberBullet,
                                backgroundColor: step3Color,
                              } as React.CSSProperties
                            }
                          >
                            3
                          </Box>
                          <Box
                            style={{
                              fontSize: "20px",
                              color: step3Color,
                            }}
                          >
                            My Goals
                          </Box>
                        </Box>
                      </Box>
                      <div
                        style={{
                          width: "3.5rem",
                          height: "0.125rem",
                          backgroundColor: step2ColorChangedUnderline,
                          marginTop: "5px",
                          marginBottom: marginBottom
                        }}
                      />
                      <Box marginBottom={marginBottom}>
                        <Box display="flex" gridGap="0.5rem">
                          <Box
                            height="1.75rem"
                            width="1.75rem"
                            borderRadius="50%"
                            style={
                              {
                                ...webStyles.numberBullet,
                                backgroundColor: stepActiveColor,
                              } as React.CSSProperties
                            }
                          >
                            4
                          </Box>
                          <Box
                            style={{
                              fontSize: "20px",
                              color: stepActiveColor,
                            }}
                          >
                            Risk Levels
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <div style={webStyle.uidStyle}>
                      <Typography style={{ color: " #a19d9d" }}>
                        UID: ******
                        {this.state.editProfileData?.attributes?.unique_auth_id?.slice(
                          -4
                        )}
                      </Typography>
                      <div>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={this.state.tooltipVisible}
                          title="Copied!"
                        >
                          <img
                            src={copyIcon}
                            alt="copy"
                            style={webStyle.uidCopyStyle}
                            onClick={this.copyToClipboard}
                            data-test-id="CopyTest"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </>
                )}
                <Box textAlign="center" marginBottom="4rem" style={webStyle.headingWrapperStyle}>
                  <p
                    style={webStyle.skipStyle}
                    onClick={this.skipStepFunction}
                    data-test-id="skipStep"
                  >
                    Skip {">>"}
                  </p>
                  <Typography
                    paragraph
                    style={{
                      fontSize: "1.6rem",
                      fontWeight: 700,
                      marginTop: "1rem",
                    }}
                  >
                    <p>{this.getStepTitle(activeStep)}</p>
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "1.2rem",
                      color: "#A1A1A1",
                      marginBottom: "1rem",
                    }}
                  >
                    {this.getHeadingText()}
                  </Typography>
                </Box>
                <Grid item xs={12}>
                  <Box
                    borderRadius="8px"
                    {...(isWide && {
                      padding: "4.375rem 2rem 2rem",
                      border: "1px solid #DCDCDC",
                    })}
                    {...(activeStep === 4 && {
                      marginTop: "200px",
                    })}
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      marginTop="-8rem"
                    >
                      {this.renderSteps()}
                    </Box>

                    <ThemeProvider theme={theme}>
                      {activeStep === 1 ? (
                        <Grid
                          container
                          spacing={3}
                          style={{ marginTop: "1.25rem" }}
                        >
                          {this.state.editProfileData?.attributes && (
                            <Formik
                              initialValues={{
                                photo: this.mapAttribute("profile_image"),
                                employee_id: this.mapAttribute("employee_id"),
                                full_name: this.mapAttribute("full_name"),
                                country_code: this.mapAttribute("country_code"),
                                mobile_number: this.mapAttribute(
                                  "full_phone_number"
                                ),
                                email_address: this.mapAttribute("email"),
                                Official_email_address: this.mapAttribute(
                                  "official_email"
                                ),
                                dob: this.transformDate(
                                  this.mapAttribute("date_of_birth")
                                ),
                                gender: this.mapAttribute("gender"),
                                employee_type: this.mapAttribute(
                                  "employee_type_id",
                                  ""
                                ),
                                contractor_name: this.mapAttribute(
                                  "contractor_name",
                                  ""
                                ),
                                role: this.mapAttribute("role_id", ""),
                                Countrie: this.mapAttribute("country_id", ""),
                                location: this.mapAttribute(
                                  "profile_location_id",
                                  ""
                                ),
                                function: this.mapAttribute("category_id", ""),
                                subFunction: this.mapAttribute(
                                  "sub_category_id",
                                  ""
                                ),
                                work_location: this.mapAttribute(
                                  "work_location_id",
                                  ""
                                ),
                                work_location_desc: this.mapAttribute(
                                  "other_work_location",
                                  ""
                                ),
                                ice_name: this.mapAttribute("icf_name", ""),
                                icf_Country_code: this.mapAttribute("icf_number_code", ""),
                                ice_number: this.mapAttribute("icf_number", ""),
                                blood_group: this.mapAttribute(
                                  "blood_group_id",
                                  ""
                                ),
                                fileUpload: null,
                              }}
                              data-test-id={"formikTest"}
                              onSubmit={(values, actions) => {
                                this.onSubmitButton(values)
                                actions?.setSubmitting(false)
                              }
                              }
                              validateOnChange={false}
                              validateOnBlur={false}
                              validationSchema={this.profileSchema}
                            >
                              {({
                                handleChange,
                                handleBlur,
                                submitForm,
                                errors,
                                values,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }) => {
                                const stepProps = {
                                  handleChange,
                                  handleBlur,
                                  submitForm,
                                  errors,
                                  values,
                                  touched,
                                  setFieldTouched,
                                  setFieldValue,
                                  setStep: this.setStep,
                                  selectEmployeType: this.state
                                    .selectEmployeType,
                                  selectLocation: this.state.selectLocation,
                                  selectRole: this.state.selectRole,
                                  selectFunction: this.state.selectFunctionType,
                                  selectSubFunction: this.state
                                    .selectSubFunctionType,
                                  selectWorkLocation: this.state
                                    .selectWorkLocation,
                                  selectBloodGroup: this.state
                                    .selectBloodGroupType,
                                  functionAPI: this.getFunctionAPI,
                                  subFunctionAPI: this.getSubFunctionAPI,
                                  subWorkLocationAPI: this.getWorkLocationAPI,
                                  locationAPI: this.getLocationAPI,
                                  selectCountrie: this.state.selectCountrie,
                                  countrieCode: this.state.countrieCode,
                                };
                                return (
                                  <>
                                    {isStep1 && <EditStep1 {...stepProps} />}
                                    {isStep2 && (
                                      <EditStep2
                                        {...stepProps}
                                        changleScemaBack={this.changleScemaBack}
                                      />
                                    )}
                                    {(isStep3) && (
                                      <EditStep3
                                        {...stepProps}
                                        changleScemaBack={this.changleScemaBack}
                                      />
                                    )}
                                  </>
                                );
                              }}
                            </Formik>
                          )}
                        </Grid>
                      ) : (
                        this.renderStepContent()
                      )}
                      {!this.state.isLoading && isStepComp && (
                        <CongratsModalStep
                          navigation={undefined}
                          activeStep={this.activeStepFun}
                          activeStepState={this.state.activeStep}
                        />
                      )}
                    </ThemeProvider>
                  </Box>
                </Grid>
              </Grid>

              {isPhone && (
                <MobileFooterMenu
                  navigation={this.props.navigation}
                  items={sideBarItems}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const webStyle = {
  uidStyle: {
    border: "1px solid #e1dcdc",
    width: "150px",
    background: "rgb(237 237 237)",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
  },
  uidCopyStyle: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  headingWrapperStyle: {
    width: "100%"
  },
  skipStyle: {
    width: "99%",
    display: "flex",
    justifyContent: "end",
    color: "#8735e1",
    cursor: "pointer",
    fontSize: "18px",
    fontWeight: 400,
  },
  breadcrumbWrapper: {
    display: "flex",
    gap: "10px",
    marginLeft: '32px',
    marginTop: '32px',
    marginBottom: '-15px',
  },
  breadcrumbItem: {
    color: '#18181E',
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherRegular",
  },
  breadcrumbActive: {
    color: "#8735E1",
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherBold",
    cursor: "pointer",
  },
  navigateNext: {
    color: "#DCDCDC",
  },
};
// Customizable Area End
