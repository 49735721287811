import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

// Customizable Area Start
import { Notifications } from '../../components/src/types';
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { UIEvent } from "react";
// Customizable Area End

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  notifications: Notifications | null;
  fullScreen: boolean;
  redirect?: any;
  getNotifications: any;
  totalPages: number;
  hideDropdown?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  pageForNotifications: number;
  totalPages: number;
  redirect: boolean;
  isActionTracker: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsBlockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPostDetailById: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      pageForNotifications: 2,
      totalPages: 0,
      redirect: false,
      isActionTracker: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    let token = await getStorageData("authToken");
    this.setState({ token, redirect: false });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    let apiResponse: any = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (this.getPostDetailById === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const postData = apiResponse.data;
      if (this.state.isActionTracker) {
        setStorageData("actionData", JSON.stringify(postData));
        this.notificationRedirection('/PerformanceTracker/ActionTrackerPost');
      } else {
        setStorageData("post", JSON.stringify(postData));
        this.notificationRedirection('/MyPosts/ViewPost');
      }
      this.setState({ redirect: false, isActionTracker: false });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  paginateOnScroll = (event: UIEvent<HTMLDivElement>) => {
    const { pageForNotifications } = this.state;
    const { totalPages } = this.props;
    if (pageForNotifications <= totalPages) {
      const targetElement = (event.target as any);
      const scrollHeight = targetElement.scrollHeight;
      const elementHeight = targetElement.offsetHeight;
      const topScrollCoord = targetElement.scrollTop;

      if (elementHeight + topScrollCoord + 1 >= scrollHeight) {
        this.props.getNotifications(pageForNotifications);
        this.setState({ pageForNotifications: this.state.pageForNotifications + 1 });
      }
    }
  }

  postView = (item: any) => {
    const notification = item.attributes;
    const postId = notification?.post_id;
    setStorageData("from", "notifications");

    if (notification?.headings === "safety_concern_published" || notification?.headings === "liked_post" || notification?.headings === "commented_on_your_post") {
      setStorageData('postId', postId);
      this.notificationRedirection('/Dashboard');
    } else if (notification?.headings.includes("action_tracker")) {
      if (postId) {
        this.props.redirect(true);
        this.setState({ redirect: true, isActionTracker: true }, () => {
          setStorageData("actionPostID", postId);
          this.getPostData(postId);
        });
      } else {
        this.notificationRedirection('/PerformanceTracker');
      }
    } else if (notification?.headings === "post_created" && postId !== null) {
      this.props.redirect(true);
      this.setState({ redirect: true }, () => {
        this.getPostData(postId);
      });
    } else if (notification?.headings === "story_created") {
      this.notificationRedirection('/Dashboard');
    }
  }

  notificationRedirection = (url: string) => {
    const currentPath = window.location.pathname;
    if (currentPath === url) {
      this.redirectTo(currentPath + '_');
    }
    setTimeout(() => {
      this.redirectTo(url);
    }, 10);
    this.props.hideDropdown(true);
  }

  redirectTo = (url: string) => {
    window.history.pushState({}, "", url);
    const navEvent = new PopStateEvent('popstate');
    window.dispatchEvent(navEvent);
  }

  getPostData(postId: number) {
    this.setState({ redirect: true });
    const header = {
      "token": this.state.token
    };
    const getMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    getMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Get'
    );
    getMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/${postId}`
    );
    this.getPostDetailById = getMessage.messageId;
    getMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getMessage.id, getMessage);
  }
  // Customizable Area End
}
