import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  userId: number | null;
  activeStep: number;
  nextClicked: boolean;
  currentCorrectiveActionSpeech: number;
  correctiveActions: {
    counter: number;
    correctiveAction: string;
    assignedPerson: {
      label: string;
      value: string;
    },
    dueDate: string;
  }[];
  currentPreventiveActionSpeech: number;
  preventiveActions: {
    counter: number;
    preventiveAction: string;
    assignedPerson: {
      label: string;
      value: string;
    },
    dueDate: string;
  }[];
  severity: string;
  frequency: string;
  customClass: string;
  postId: null | number;
  post: {
    description?: string;
    location?: string;
    post_concern_id?: number;
    hazard_id?: number;
    frequency_id?: number;
    severity_id?: number;
    consequence_id?: number;
    activity_identification_id?: number;
    task_identification_id?: number;
  },
  selectedConsequence: string;
  consequences: {};
  selectedFrequency: string | null;
  frequencies: any;
  selectedHazard: string;
  hazards: {};
  selectedSeverity: string | null;
  severities: any;
  selectedActivity: string;
  activityIdentifications: {};
  selectedTask: string;
  taskIdentifications: {};
  selectedConcern: string;
  concerns: {};
  statutory: string;
  sopCompliance: string;
  noGoParameter: string;
  safetyViolation: string;
  riskCount: string;
  issueDescription: null | string;
  location: null | string;
  loading: boolean;
  loadingGetPost: boolean;
  personList: {};
  transcription: string;
  listening: boolean;
  stoppedByTimeout: boolean;
  stoppedByTimeoutStatutory: boolean;
  listeningStatutory: boolean;
  listeningSopCompliance: boolean;
  stoppedByTimeoutSopCompliance: boolean;
  listeningNoGoParameter: boolean;
  stoppedByTimeoutNoGoParameter: boolean;
  listeningSafetyViolation: boolean;
  stoppedByTimeoutSafetyViolation: boolean;
  listeningCorrectiveAction: boolean;
  stoppedByTimeoutCorrectiveAction: boolean;
  listeningPreventiveAction: boolean;
  stoppedByTimeoutPreventiveAction: boolean;
  From: string;
  postClicked: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SupervisorPostCreationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPostDataApiCallId: string = "";
  getOptionsApiCallId: string = "";
  getTaskAndActivityOptionsApiCallId: string = "";
  getPersonDetailApiCallId: string = "";
  postDataApiCallId: string = "";
  postCapaDataApiCallId: string = "";
  private recognition: any | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      userId: null,
      activeStep: 0,
      nextClicked: false,
      currentCorrectiveActionSpeech: 0,
      currentPreventiveActionSpeech: 0,
      correctiveActions: [{ counter: 1, correctiveAction: "", assignedPerson: { label: "", value: "" }, dueDate: "" }],
      preventiveActions: [{ counter: 1, preventiveAction: "", assignedPerson: { label: "", value: "" }, dueDate: "" }],
      severity: "",
      frequency: "",
      customClass: "",
      postId: null,
      post: {},
      selectedConsequence: "",
      consequences: {},
      selectedFrequency: "",
      frequencies: {},
      selectedHazard: "",
      hazards: {},
      selectedSeverity: "",
      severities: {},
      selectedActivity: "",
      concerns: {},
      selectedConcern: "",
      activityIdentifications: {},
      selectedTask: "",
      taskIdentifications: {},
      statutory: "",
      sopCompliance: "",
      noGoParameter: "",
      safetyViolation: "",
      riskCount: "",
      issueDescription: null,
      location: null,
      loading: false,
      loadingGetPost: false,
      transcription: "",
      listening: false,
      stoppedByTimeout: false,
      listeningStatutory: false,
      stoppedByTimeoutStatutory: false,
      listeningSopCompliance: false,
      stoppedByTimeoutSopCompliance: false,
      listeningNoGoParameter: false,
      stoppedByTimeoutNoGoParameter: false,
      listeningSafetyViolation: false,
      stoppedByTimeoutSafetyViolation: false,
      personList: {},
      listeningCorrectiveAction: false,
      stoppedByTimeoutCorrectiveAction: false,
      listeningPreventiveAction: false,
      stoppedByTimeoutPreventiveAction: false,
      From: "",
      postClicked: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const responseData = message.getData(getName(MessageEnum.Props));
      this.setState({ postId: responseData.id })
      setStorageData('postId', responseData.id);
      this.getPostData(responseData.id)
    }

    if (
      message.id === getName(MessageEnum.RestAPIResponceMessage) &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      responseJson
    ) {
      if (apiRequestCallId === this.getPostDataApiCallId) {
        this.setState({ loadingGetPost: false });
        this.getPostCallback(responseJson);
      }
      if (apiRequestCallId === this.getOptionsApiCallId) {
        this.setState({ loading: false });
        this.getOptionsCallback(responseJson);
      }
      if (apiRequestCallId === this.getTaskAndActivityOptionsApiCallId) {
        this.setState({ loading: false });
        this.getTaskOptionsCallback(responseJson);
      }
      if (apiRequestCallId === this.postDataApiCallId) {
        this.setState({ loading: false });
      }
      if (apiRequestCallId === this.getPersonDetailApiCallId) {
        this.setState({ loading: false, personList: responseJson.data });
      }
      if (apiRequestCallId === this.postCapaDataApiCallId) {
        this.setState({ loading: false });
        this.getCapaCallback(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    let token = await getStorageData("authToken", false);
    const postId = await getStorageData("postId");
    const userId = await getStorageData("SignUpID");
    const From = await getStorageData("from");
    this.setState({ token, postId });
    this.getPostData(postId);
    this.getOptions();
    this.getTaskAndActivityOptions();
    this.getPersonData();

    if ("SpeechRecognition" in global || "webkitSpeechRecognition" in global) {
      console.log('mount transcription')
      this.setupSpeechRecognition();
    }

    this.setState({ userId: parseInt(userId.replace("\"", "")), From })
  }

  setupSpeechRecognition() {
    this.recognition = new ((global as any).SpeechRecognition ||
      (global as any).webkitSpeechRecognition)();

    if (this.recognition) {
      this.recognition.lang = "en-US";
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.onresult = (event: any) => {
        const transcription = Array.from(event.results)
          .map((result: any) => result[0].transcript)
          .join("");
        this.setState({ transcription });
        console.log('mount transcription', transcription)
      };
      const pageHiddenProp = this.getPageHiddenProp();
      if (pageHiddenProp) {
        this.addVisibilityChangeListener(pageHiddenProp);
      }
    }
  }

  addVisibilityChangeListener(pageHiddenProp: string) {
    document.addEventListener(pageHiddenProp.replace(/[H|h]idden/, '') + 'visibilitychange', () => {
      if ((document as any)[pageHiddenProp] && this.recognition) {
        this.stopRecognition();
      }
    });
  }

  stopRecognition() {
    if (this.state.listening) {
      this.recognition.stop();
      this.setState({ listening: false });
    }

    if (this.state.listeningStatutory) {
      this.recognition.stop();
      this.setState({ listeningStatutory: false });
    }

    if (this.state.listeningSopCompliance) {
      this.recognition.stop();
      this.setState({ listeningSopCompliance: false });
    }

    if (this.state.listeningNoGoParameter) {
      this.recognition.stop();
      this.setState({ listeningNoGoParameter: false });
    }

    if (this.state.listeningSafetyViolation) {
      this.recognition.stop();
      this.setState({ listeningSafetyViolation: false });
    }

    if (this.state.listeningCorrectiveAction) {
      this.recognition.stop();
      this.setState({ listeningCorrectiveAction: false });
    }

    if (this.state.listeningPreventiveAction) {
      this.recognition.stop();
      this.setState({ listeningPreventiveAction: false });
    }
  }

  getPageHiddenProp() {
    const hiddenProps = ["hidden", "webkitHidden", "mozHidden"];
    for (const prop of hiddenProps) {
      if (prop in document) {
        return prop;
      }
    }
    return null;
  }

  toggleListening = async () => {
    if (this.recognition) {
      if (this.state.listening) {
        this.setState({ stoppedByTimeout: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeout: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listening && !this.state.stoppedByTimeout) {
            this.recognition.stop();
            this.setState({ listening: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listening: !prevState.listening,
      }));
    }
  };

  toggleListeningForStatutory = async () => {
    if (this.recognition) {
      if (this.state.listeningStatutory) {
        this.setState({ stoppedByTimeoutStatutory: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutStatutory: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningStatutory && !this.state.stoppedByTimeoutStatutory) {
            this.recognition.stop();
            this.setState({ listeningStatutory: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningStatutory: !prevState.listeningStatutory,
      }));
    }
  };

  toggleListeningForSopCompliance = async () => {
    if (this.recognition) {
      if (this.state.listeningSopCompliance) {
        this.setState({ stoppedByTimeoutSopCompliance: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutSopCompliance: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningSopCompliance && !this.state.stoppedByTimeoutSopCompliance) {
            this.recognition.stop();
            this.setState({ listeningSopCompliance: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningSopCompliance: !prevState.listeningSopCompliance,
      }));
    }
  };

  toggleListeningForNoGoParameter = async () => {
    if (this.recognition) {
      if (this.state.listeningNoGoParameter) {
        this.setState({ stoppedByTimeoutNoGoParameter: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutNoGoParameter: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningNoGoParameter && !this.state.stoppedByTimeoutNoGoParameter) {
            this.recognition.stop();
            this.setState({ listeningNoGoParameter: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningNoGoParameter: !prevState.listeningNoGoParameter,
      }));
    }
  };

  toggleListeningForSafetyViolation = async () => {
    if (this.recognition) {
      if (this.state.listeningSafetyViolation) {
        this.setState({ stoppedByTimeoutSafetyViolation: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutSafetyViolation: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningSafetyViolation && !this.state.stoppedByTimeoutSafetyViolation) {
            this.recognition.stop();
            this.setState({ listeningSafetyViolation: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningSafetyViolation: !prevState.listeningSafetyViolation,
      }));
    }
  };

  toggleListeningForCorrectiveAction = async () => {
    if (this.recognition) {
      if (this.state.listeningCorrectiveAction) {
        this.setState({ stoppedByTimeoutCorrectiveAction: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutCorrectiveAction: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningCorrectiveAction && !this.state.stoppedByTimeoutCorrectiveAction) {
            this.recognition.stop();
            this.setState({ listeningCorrectiveAction: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningCorrectiveAction: !prevState.listeningCorrectiveAction,
      }));
    }
  };

  toggleListeningForPreventiveAction = async () => {
    if (this.recognition) {
      if (this.state.listeningPreventiveAction) {
        this.setState({ stoppedByTimeoutPreventiveAction: true });
        this.recognition.stop();
      } else {
        this.setState({ stoppedByTimeoutPreventiveAction: false });
        await this.recognition.start();
        this.setState({ transcription: "" });
        setTimeout(() => {
          if (this.state.listeningPreventiveAction && !this.state.stoppedByTimeoutPreventiveAction) {
            this.recognition.stop();
            this.setState({ listeningPreventiveAction: false });
          }
        }, 30000);

      }
      this.setState((prevState) => ({
        listeningPreventiveAction: !prevState.listeningPreventiveAction,
      }));
    }
  };

  getCapaCallback(response: any) {
    if (response?.data.type === "post") {
      if (this.state.From === "notifications") {
        this.navigatToNotifications()
      } else {
        this.navigateToMyPosts();
      }
    }
  }

  getOptionsCallback(response: any) {
    this.updateRiskLevel(this.state.post, response?.frequencies, response?.severities);
    this.setState({ consequences: response?.consequences, frequencies: response?.frequencies, hazards: response?.hazards, severities: response?.severities, concerns: response?.post_concerns });
  }

  getPostCallback(response: any) {
    const data = response?.data?.attributes;
    if (data.action_trackers.length > 0) {
      this.navigateToMyPosts();
      return false;
    }

    this.setState({
      post: data, issueDescription: data.description ?? "", location: data.work_location ?? "", selectedConcern: data.post_concern_id, selectedHazard: data.hazard_id, frequency: data.frequency_id, severity: data.severity_id, selectedConsequence: data.consequence_id, selectedActivity: data.activity_identification_id, selectedTask: data.task_identification_id,
      statutory: data.statutory_regulatory_legal_concern ?? "",
      sopCompliance: data.standard_sop_compliance ?? "",
      noGoParameter: data.no_go_parameter_violation ?? "",
      safetyViolation: data.safety_absolute_violation ?? "",
      riskCount: data.risk_count ?? ""
    })

    this.updateRiskLevel(data, this.state.frequencies, this.state.severities);
  }

  updateRiskLevel = (postData: any, frequencyData: any, severitiesData: any) => {
    const freQId = postData?.frequency_id;
    const frequency = frequencyData?.data?.find((item: { id: number }) => item.id == freQId);
    const frequencyName = frequency ? frequency.attributes.name : '';

    const severityId = postData?.severity_id;
    const severity = severitiesData?.data?.find((item: { id: number }) => item.id == severityId);
    const severityName = severity ? severity.attributes.name : '';

    this.updateColor(severityName, frequencyName);
    this.setState({ selectedSeverity: severityName, selectedFrequency: frequencyName })
  }

  getTaskOptionsCallback(response: any) {
    this.setState({ activityIdentifications: response?.activity_identifications, taskIdentifications: response?.task_identification });
  }

  isCorrectiveActionsFilled() {
    return this.state.correctiveActions.every(
      (action: any) => {
        if ((action.assignedPerson?.value === "" || action.assignedPerson === null) &&
          (action.dueDate === null || action.dueDate === "") &&
          action.correctiveAction === ""
        ) {
          return true;
        }

        if (action.correctiveAction === "" ||
          (action.assignedPerson === null || action.assignedPerson?.value === "") ||
          (action.dueDate === "" || action.dueDate === null)) {
          return false;
        }

        return true;
      }
    );
  }

  isPreventiveActionsFilled() {
    return this.state.preventiveActions.every(
      (action: any) => {
        if ((action.assignedPerson?.value === "" || action.assignedPerson === null) &&
          (action.dueDate === null || action.dueDate === "") &&
          action.preventiveAction === ""
        ) {
          return true;
        }

        if (action.preventiveAction === "" ||
          (action.assignedPerson === null || action.assignedPerson?.value === "") ||
          (action.dueDate === "" || action.dueDate === null)) {
          return false;
        }

        return true;
      }
    );
  }

  isCorrectivePreventiveActionsFilled = () => {
    let flag = false;
    let allBlank = true;

    const correctiveActions = this.state.correctiveActions.map(value => ({
      ...value, action: value.correctiveAction
    }));

    const preventiveActions = this.state.preventiveActions.map(value => ({
      ...value, action: value.preventiveAction
    }));

    const combineState = [...correctiveActions, ...preventiveActions];

    flag = combineState.every(
      (action: any) => {
        if ((action.assignedPerson?.value === "" || action.assignedPerson === null) &&
          (action.dueDate === null || action.dueDate === "") &&
          action.action === ""
        ) {
          return true;
        }

        if ((action.assignedPerson !== null && action.assignedPerson?.value !== "") ||
          (action.dueDate !== null && action.dueDate !== "") ||
          action.action !== "") {
          allBlank = false;
        }

        if (action.action === "" ||
          (action.assignedPerson === null || action.assignedPerson?.value === "") ||
          (action.dueDate === "" || action.dueDate === null)) {
          return false;
        }

        return true;
      }
    );

    return { allBlank, flag };
  }

  postData = async (id: number) => {
    const { userId, postId } = this.state;

    const isCorrectiveActionsFilled = this.isCorrectiveActionsFilled();

    const isPreventiveActionsFilled = this.isPreventiveActionsFilled();

    const isCorrectivePreventiveActionsFilled = this.isCorrectivePreventiveActionsFilled();
    const allBlank = isCorrectivePreventiveActionsFilled.allBlank;
    const flag = isCorrectivePreventiveActionsFilled.flag;
    const finalFilled = allBlank ? !allBlank : flag;

    const isAnyActionFilled = finalFilled && isCorrectiveActionsFilled && isPreventiveActionsFilled;

    if (!isAnyActionFilled) {
      if(allBlank) {
        alert("Atleast one corrective or preventive action should be added.");
      } else {
        alert("All the fields are mandatory.");
      }
      return;
    }

    this.setState({ loading: true, postClicked: true }, async () => {
      let formData: any = new FormData();

      let indexNumber = 0
      formData.append("id", postId)
      this.state.correctiveActions.forEach((item: any, index: number) => {
        if (item.assignedPerson.value != '' && item.dueDate != '' && item.correctiveAction != '') {
          indexNumber = index
          formData.append(`post[bx_block_posts_action_trackers_attributes[${index}][account_id]]`, userId)
          formData.append(`post[bx_block_posts_action_trackers_attributes[${index}][action_type]]`, 'corrective_action')
          formData.append(`post[bx_block_posts_action_trackers_attributes[${index}][description]]`, item.correctiveAction)
          formData.append(`post[bx_block_posts_action_trackers_attributes[${index}][corrective_or_preventive_action_assigned_person_id]]`, item.assignedPerson.value)
          formData.append(`post[bx_block_posts_action_trackers_attributes[${index}][due_date]]`, item.dueDate)
        }
      })

      this.state.preventiveActions.forEach((item: any, index: number) => {
        if (item.assignedPerson.value != '' && item.dueDate != '' && item.preventiveAction != '') {
          formData.append(`post[bx_block_posts_action_trackers_attributes[${(index + 1) + indexNumber}][account_id]]`, userId)
          formData.append(`post[bx_block_posts_action_trackers_attributes[${(index + 1) + indexNumber}][action_type]]`, 'preventive_action')
          formData.append(`post[bx_block_posts_action_trackers_attributes[${(index + 1) + indexNumber}][description]]`, item.preventiveAction)
          formData.append(`post[bx_block_posts_action_trackers_attributes[${(index + 1) + indexNumber}][corrective_or_preventive_action_assigned_person_id]]`, item.assignedPerson.value)
          formData.append(`post[bx_block_posts_action_trackers_attributes[${(index + 1) + indexNumber}][due_date]]`, item.dueDate)
        }
      })

      this.postCapaDataApiCallId = await this.apiCallForSupervisor({
        contentType: configJSON.formDataApiMethodType,
        method: configJSON.patchApiMethodType,
        endPoint: configJSON.postForCapaEndPoint,
        body: formData,
        type: 'formData'
      });
    });
  };

  getPostData = async (id: number) => {
    this.setState({ loadingGetPost: true });
    this.getPostDataApiCallId = await this.apiCallForSupervisor({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.getPostEndpoint}/${id}`,
    });
  };

  getOptions = async () => {
    this.setState({ loading: true });
    this.getOptionsApiCallId = await this.apiCallForSupervisor({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getOptionsEndpoint,
    });
  };

  getTaskAndActivityOptions = async () => {
    this.setState({ loading: true });
    this.getTaskAndActivityOptionsApiCallId = await this.apiCallForSupervisor({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getTaskAndActivityIdentification,
    });
  };

  getPersonData = async () => {
    this.setState({ loading: true });
    this.getPersonDetailApiCallId = await this.apiCallForSupervisor({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethodType,
      endPoint: configJSON.getPersonDataEndPoint,
    });
  };

  // Api Call Function
  apiCallForSupervisor = async (data: { contentType: string; method: string; endPoint: string; body?: object; type?: string }) => {
    const { method, endPoint, body, type } = data;
    const header = { token: this.state.token };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);

    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);

    body && type != "formData"
      ? requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  handleNext = (id: number) => {
    this.setState({ nextClicked: true });

    const { activeStep, statutory, riskCount, safetyViolation, sopCompliance, noGoParameter, issueDescription, selectedConcern, selectedHazard, frequency, severity, selectedConsequence, selectedActivity, selectedTask, selectedSeverity, selectedFrequency } = this.state;

    const errorInDescription = !issueDescription;
    const errorInConcern = !selectedConcern;
    const errorInHazard = !selectedHazard;
    const errorInFrequency = !frequency;
    const errorInSeverity = !severity;
    const errorInConsequence = !selectedConsequence;

    const errorInActivity = !selectedActivity;
    const errorInTask = !selectedTask;

    const errorInStatuary = statutory === "";
    const errorInSOP = sopCompliance === "";
    const errorInNoGoParameter = noGoParameter === "";
    const errorInSafetyViolation = safetyViolation === "";
    const errorInRiskCount = riskCount === "" || !(/^\d*$/.test(riskCount));


    if (activeStep === 0 && (errorInDescription || errorInConcern || errorInHazard || errorInFrequency || errorInSeverity || errorInConsequence)) {
      return;
    }

    if (activeStep === 1 && (errorInActivity || errorInTask)) {
      return;
    }

    if (activeStep === 2 && (errorInStatuary || errorInSOP || errorInNoGoParameter || errorInSafetyViolation || errorInRiskCount)) {
      return;
    }

    const riskLevelName = selectedSeverity! + selectedFrequency;

    this.setState({ loading: false }, async () => {
      let formdata: any = new FormData();
      formdata.append("description", issueDescription);
      formdata.append("post_concern_id", selectedConcern);
      formdata.append("hazard_id", selectedHazard);
      formdata.append("frequency_id", frequency);
      formdata.append("severity_id", severity);
      formdata.append("consequence_id", selectedConsequence);
      formdata.append("risk_level_name", riskLevelName);
      formdata.append("activity_identification_id", selectedActivity);
      formdata.append("task_identification_id", selectedTask);
      formdata.append("statutory_regulatory_legal_concern", statutory);
      formdata.append("standard_sop_compliance", sopCompliance);
      formdata.append("no_go_parameter_violation", noGoParameter);
      formdata.append("safety_absolute_violation", safetyViolation);
      formdata.append("risk_count", riskCount);

      this.postDataApiCallId = await this.apiCallForSupervisor({
        contentType: configJSON.formDataApiMethodType,
        method: configJSON.patchApiMethodType,
        endPoint: `${configJSON.getPostEndpoint}/${id}`,
        body: formdata,
        type: 'formData'
      });
    });


    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
      nextClicked: false
    }));
  };

  navigateToMyPosts = () => {
    this.props.navigation.navigate("MyPosts");
  }

  navigatToNotifications = () => {
    this.props.navigation.navigate("Notifications");
  }

  navigateBack = () => {
    this.props.navigation.goBack();
  }

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };


  addCorrectiveAction = () => {
    const { correctiveActions } = this.state;
    const counter = correctiveActions[correctiveActions.length - 1].counter + 1;
    this.setState({
      correctiveActions: [...correctiveActions, { counter, correctiveAction: "", assignedPerson: { label: "", value: "" }, dueDate: "" }],
    });
  };

  addPreventiveAction = () => {
    const { preventiveActions } = this.state;
    const counter = preventiveActions[preventiveActions.length - 1].counter + 1;
    this.setState({
      preventiveActions: [...preventiveActions, { counter, preventiveAction: "", assignedPerson: { label: "", value: "" }, dueDate: "" }],
    });
  };

  removeCorrectiveAction = (indexToRemove: number) => {
    const { correctiveActions } = this.state;
    if (correctiveActions.length > 1) {
      const updatedActions = correctiveActions.filter(
        (_, index) => index !== indexToRemove
      );
      this.setState({ correctiveActions: updatedActions });
    }
  };

  handleChangeCorrectiveAction = (index: number, field: string, value: string) => {
    const { correctiveActions } = this.state;
    const updatedCorrectiveActions: any = [...correctiveActions];
    updatedCorrectiveActions[index][field] = value;
    this.setState({ correctiveActions: updatedCorrectiveActions });
  };

  handleChangePreventiveAction = (index: number, field: string, value: string) => {
    const { preventiveActions } = this.state;
    const updatedPreventiveActions: any = [...preventiveActions];
    updatedPreventiveActions[index][field] = value;
    this.setState({ preventiveActions: updatedPreventiveActions });
  };

  removePreventiveAction = (indexToRemove: number) => {
    const { preventiveActions } = this.state;
    if (preventiveActions.length > 1) {
      const updatedActions = preventiveActions.filter(
        (_, index) => index !== indexToRemove
      );
      this.setState({ preventiveActions: updatedActions });
    }
  };

  handleSeverityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSeverity = event.currentTarget.textContent;
    const severity = event.target.value;
    this.setState({ severity, selectedSeverity });
    this.updateColor(selectedSeverity, this.state.selectedFrequency);
  };

  handleFrequencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFrequency = event.currentTarget.textContent;
    const frequency = event.target.value;
    this.setState({ frequency, selectedFrequency });
    this.updateColor(this.state.selectedSeverity, selectedFrequency);
  };

  updateColor = (s: any, f: any) => {
    const conditions = {
      riskLevelInputGreen: {
        "1": ["A", "B", "C"],
        "2": ["A", "B"]
      },
      riskLevelInputYellow: {
        "1": ["D", "E"],
        "2": ["C", "D", "E"],
        "3": ["A", "B", "C"]
      },
      riskLevelInputRed: {
        "3": ["D", "E"],
        "4": ["A", "B", "C", "D", "E"],
        "5": ["A", "B", "C", "D", "E"]
      }
    };

    for (const [clr, condition] of Object.entries(conditions)) {
      if ((condition as { [key: string]: string[] })[s]?.includes(f)) {
        this.setState({ customClass: clr });
        return;
      }
    }
  }


  // Customizable Area End
}
