import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    // Customizable Area Start
    styled,
    Grid,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavigateNext } from "@material-ui/icons";
import { noChecklistImg, profile, calender, moreVerticle } from "./assets";
import moment from "moment";

import { CheckpointData } from "./MachineCheckpointsController";

// Customizable Area End

import MachineCheckpointsController, {
    Props,
} from "./MachineCheckpointsController";
import { webStyles } from "../../../components/src/styles";

export default class MachineCheckpoints extends MachineCheckpointsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    noCheckpoints = () => {
        return (
            <Box className="mainBox">
                <Grid container style={{ height: '55vh' }} justifyContent='center' alignItems='center' direction="column">
                    <Grid>
                        <img
                            height="70px"
                            width="70px"
                            src={noChecklistImg}
                            alt="No Checklist Available"
                        />
                    </Grid>
                    <Grid>
                        <Typography className="addCheckpointText">No Checkpoints Available</Typography>
                    </Grid>
                    {this.state.role !== 'Worker' &&
                        <Grid>
                            <Typography className="getStartedText">Add Checkpoints to get started.</Typography>
                        </Grid>
                    }
                </Grid>
            </Box>
        )
    }
    checkpointList = (value: CheckpointData) => {
        let chipClass;
        if (value?.attributes?.status === 'Due') {
            chipClass = 'orangeChip';
        } else if (value?.attributes?.status === "Completed") {
            chipClass = 'greenChip';
        } else if (value?.attributes?.status === "Overdue") {
            chipClass = 'redChip';
        } else {
            chipClass = 'blueChip';
        }
        return (<Grid item xs={12} sm={6} md={4}>
            <Card className="cardStyle"
                onClick={() => this.navigateToCheckpointDetail(value.id)}
                data-test-id="checkpoint_list_card">
                <CardContent>
                    <Box className="cardContentMainBox">
                        <Typography className="cardMainHeading trimWords"> {value?.attributes?.name}</Typography>
                        {this.state.role !== "Worker" ?
                            <Typography variant="body1" style={{ position: "relative" }}>
                                <img
                                    src={moreVerticle}
                                    alt="more"
                                    data-test-id="edit_delete_popup"
                                    className="moreClass"
                                    onClick={(event) => this.handleEditDeletePopup(event, value.id)} />
                                {this.state.isEditDeleteOpen === value.id && this.editAndDeleteDialog(value.id)}
                            </Typography>
                            :
                            <Chip className={`chipClass workerChipClass ${chipClass}`} label={value?.attributes?.status} />
                        }
                    </Box>

                    <Box className="imageAndText">
                        <img src={profile} alt="Profile" height={23} width={23} />
                        <Typography className="cardText trimWords">Last Checked by: {value?.attributes?.last_checked_by}</Typography>
                    </Box>
                    <Box className="imageAndText">
                        <img src={calender} alt="calender" height={23} width={23} />
                        <Typography className="cardText trimWords">Last Checked on: {(value?.attributes?.last_checked_on) ? moment(value?.attributes?.last_checked_on).format('DD/MM/YYYY') : null} </Typography>
                    </Box>
                    <Box>
                        <Grid container justifyContent="space-between" spacing={1} alignItems="center">
                            <Grid item className="trimWords">
                                <Box className="imageAndText">
                                    <img src={calender} alt="calender" height={23} width={23} />
                                    <Typography className="cardText trimWords">Next Due Date: {moment(value?.attributes?.due_date).format('DD/MM/YYYY')}</Typography>
                                </Box>
                            </Grid>
                            {this.state.role !== "Worker" && <Grid item>
                                <Box className="imageAndText trimWords">
                                    <Chip className={`chipClass workerChipClass ${chipClass}`} label={value?.attributes?.status} />
                                </Box></Grid>}
                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        </Grid>)
    }
    editAndDeleteDialog = (checkpointId: number) => {
        return (
            <Box className="editDeleteBox">
                <Box className="editText" data-test-id="edit_checkpoint" onClick={(event) => this.handleEdit(event, checkpointId)}>Edit</Box>
                <Box className="deleteText" data-test-id="delete_checkpoint" onClick={(event) => this.handleDelete(event, checkpointId)}>Delete</Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        const { role, checkpoint, selectedMachineData, loading } = this.state;
        return (
            // Customizable Area Start
            <>
                <Grid container>
                    <Wrapper>                        
                            <Box style={webStyles.breadcrumbWrapper}>
                                <Typography style={webStyles.breadcrumbItem} data-test-id="navigate_to_audit_screen" onClick={this.navigateToAuditScreen}>Audit</Typography>
                                <NavigateNext style={webStyles.navigateIcon} />
                                <Typography style={webStyles.breadcrumbActiveItem}>{selectedMachineData?.equipment_name}</Typography>
                            </Box>
                            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                                <Grid item className="trimWords" style={{
                                    width: "calc(100% - 248px)"
                                }}>
                                    <Typography className="mainHeading">{selectedMachineData?.equipment_name}</Typography>
                                </Grid>
                                <Grid item style={{ width: "248px" }}>
                                    {role !== 'Worker' && checkpoint.length !== 0 &&
                                        <Button
                                            data-test-id="add_new_checkpoint"
                                            className="addCheckpointButtonStyle"
                                            type="submit"
                                            onClick={() => this.handleAddCheckpointBtn()}
                                        >
                                            Add New Checkpoint
                                        </Button>}
                                </Grid>
                            </Grid>

                            {!loading && checkpoint.length > 0 && <Box>
                                <Grid container spacing={2} style={{ marginTop: '8px' }}>
                                    {checkpoint.map((value) => {
                                        return this.checkpointList(value);
                                    })}
                                </Grid>
                            </Box>}

                            {!loading && checkpoint.length === 0 && this.noCheckpoints()}

                            {role !== 'Worker' && role !== '' && !loading && checkpoint.length === 0 && <Box className="addButton">
                                <Button
                                    data-test-id="add_new_checkpoint"
                                    variant="contained"
                                    className="add-btn"
                                    onClick={this.handleAddCheckpointBtn}
                                >
                                    Add
                                </Button>
                            </Box>}
                            {loading && (
                                <Box data-test-id={"activityLoader"} className="loader-outer">
                                    <Box className="loader-inner">
                                        <CircularProgress />
                                    </Box>
                                </Box>
                            )}
                    </Wrapper>
                </Grid>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
    padding: "30px",
    width: "100%",
    backgroundColor: "rgb(238, 238, 238)",
    height: "100%",
    position: "relative",
    boxSizing: "border-box",
    "& .loader-inner": {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        top: "40%",
        left: "50%",
    },
    "& .loader-outer": {
        position: "absolute",
        left: 0,
        bottom: 0,
        height: "100%",
        minHeight: "100vh",
        top: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "rgb(0, 0, 0, .4)",
    },
    "& .subHeadingText": {
        lineHeight: "22px",
        cursor: "pointer",
        fontFamily: "ClotherRegular",
        color: '#18181E',
        fontSize: "14px",
    },
    "& .headingWrapper": {
        display: "flex",
    },
    "& .addCheckpointText": {
        color: '#2B2A29',
        fontSize: '20px',
        fontWeight: 400,
        fontFamily: 'ClotherBold',
    },
    "& .addCheckpointButtonStyle": {
        border: "1px solid #8735E1",
        textTransform: "none",
        padding: "20px 12px",
        fontSize: "16px",
        fontWeight: 700,
        width: "195px",
        height: "30px",
        color: "#8735E1",
        borderRadius: "8px",
        fontFamily: "ClotherBold",
        "@media(max-width:601px)": {
            lineHeight: "15px",
            height: "45px"
        },
    },
    "& .getStartedText": {
        color: '#A1A1A1',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'ClotherRegular',
    },
    "& .cardStyle": {
        background: "#fff",
        border: "1.5px solid lightgray",
        cursor: "pointer",
        padding: "4px 2px",
        boxShadow: "none",
        borderRadius: "8px",
    },
    "& .activeHeadingText": {
        lineHeight: "22px",
        color: "#8735E1",
        fontSize: "14px",
        fontFamily: "ClotherBold",
        cursor: "pointer",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    "& .navigateNext": {
        color: "#DCDCDC",
    },
    "& .editDeleteBox": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "absolute",
        zIndex: 1,
        border: "1px solid #E2E8F0",
        borderRadius: "8px",
        width: "70px",
        height: "72px",
        padding: "0px 16px",
        right: "10",
        backgroundColor: "white",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)"
    },
    "& .editText": {
        fontSize: "14px",
        fontFamily: "ClotherRegular",
        cursor: "pointer",
        fontWeight: 400,
        paddingBottom: "8px",
        color: "#2B2A29",
    },
    "& .deleteText": {
        fontSize: "14px",
        fontFamily: "ClotherRegular",
        cursor: "pointer",
        fontWeight: 400,
        color: "#F30202",
    },
    "& .Location-input .mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#8735E1",
    },
    "& .cardMainHeading": {
        fontSize: "16px",
        fontFamily: "ClotherBold",
    },
    "& .imageAndText": {
        display: "flex",
        gap: 15,
        marginTop: 10
    },
    "& .cardText": {
        color: "#A1A1A1",
        fontWeight: 400,
        fontSize: "17px",
        fontFamily: "ClotherRegular",
    },
    "& .trimWords": {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: "nowrap",
        maxWidth: '100%'
    },
    "& .moreClass": {
        zIndex: 1
    },
    "& .mainHeading": {
        fontWeight: 700,
        margin: "10px 0",
        color: "#2B2A29",
        fontSize: "26px",
        fontStyle: "normal",
        fontFamily: "ClotherBold",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    "& .textarea-input, .textarea-input:focus-visible": {
        borderRadius: '8px',
        border: '1px solid lightgray',
        fontFamily: 'ClotherBold',
        backgroundColor: 'white',
        height: '152px',
        padding: '10px 15px',
        width: '100%',
        outline: 'none'
    },
    "& .addButton": {
        display: "flex",
        justifyContent: "end",
        width: "100%",
        margin: "25px 0px 0px 2px"
    },
    "& .mainGrid": {
        display: 'flex',
        flexDirection: 'row',
        padding: '20px',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        "@media (max-width: 600px)": {
            flexDirection: 'column'
        }
    },
    "& .workerChipClass": {
        backgroundColor: "#DDF6FF",
        color: "#00b0f0",
        borderRadius: "15px",
        fontFamily: "ClotherBold",
        fontSize: "16px",
        padding: "5px",
        minWidth: "105px",
        "& .MuiChip-label": {
            padding: "0px 5px",
            marginBottom: "2px"
        }
    },
    "& .chipClass": {
        backgroundColor: "white",
        color: "#A1A1A1",
        borderRadius: "15px",
        height: "25px",
        fontFamily: "ClotherBold",
        width: "100px",
        "& .MuiChip-label": {
            padding: "0px 5px",
        }
    },
    "& .blueChip": {
        backgroundColor: "#DDF6FF",
        color: "#00b0f0",
        borderRadius: "15px",
        fontFamily: "ClotherBold",
        "& .MuiChip-label": {
            padding: "0px 5px",
        }
    },
    "& .innerGrid": {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    "& .mainBox": {
        backgroundColor: 'white',
        width: "100%",
        borderRadius: "8px",
        margin: "15px 0px"
    },
    "& .add-btn": {
        padding: "10px",
        color: "#FFF",
        textAlign: "center",
        borderRadius: "8px",
        background: "#8735E1",
        width: "160px",
        height: "50px",
        boxShadow: "none",
        fontFamily: "ClotherBold",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        textTransform: "capitalize",
    },
    "& .cardContentMainBox": {
        display: "flex",
        justifyContent: "space-between",
        gap: "15px"
    },
    "& .orangeChip": {
        backgroundColor: "#FFF4DE",
        color: "#DF9300",
        borderRadius: "12px",
        fontFamily: "ClotherBold",
        "& .MuiChip-label": {
            padding: "0px 5px",
        }
    },

    "& .redChip": {
        backgroundColor: "#FFEDED",
        color: "#FA0000",
        borderRadius: "15px",
        fontFamily: "ClotherBold",
        "& .MuiChip-label": {
            padding: "0px 5px",
        }
    },
    "& .greenChip": {
        backgroundColor: "#DBFFED",
        color: "#009A4F",
        borderRadius: "15px",
        fontFamily: "ClotherBold",
        height: "25px",
        "& .MuiChip-label": {
            padding: "0px 5px",
        }
    },
});
// Customizable Area End
