import React from "react";

// Customizable Area Start
import { Box, Button, Typography, Grid, styled, Select, InputBase, MenuItem, Card, CardContent, Chip, CircularProgress } from "@material-ui/core";
import { NavigateNext, KeyboardArrowDown } from "@material-ui/icons";
import { totalAssignedActions, completed, overdueActions, dueActions, cancelledActions, moreVert, notMyDomain } from "./assets";
import { DropdownValues } from "./AnalyticsController.web";
import { Pagination } from "@material-ui/lab";
import { webStyles } from "../../../components/src/styles";
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  dropdownRow = () => {
    const { country, selectedCountry, cities, selectedCities, plants, selectedPlants, departments, selectedDepartments, allWorkLocation, selectedAllWorkLocation, assignedBy, selectedAssignedBy, assignedTo, selectedAssignedTo, selectedYear } = this.state;
    return (
      <Grid container spacing={1} className="dropDownRowMargin">
        <Grid item className="dropDownWidth">
          <Select
            labelId="country_dropdown"
            id="country_dropdown"
            data-test-id="country_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedCountry}
            className="enterInputField"
            onChange={(event) => this.handleChangeCountry(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Country
                  </div>);
              }
              return (selected as DropdownValues[])[(selected as DropdownValues[]).length - 1]?.attributes?.name;
            }}
            input={<InputBase />}
          >
            {country.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Select
            labelId="city_dropdown"
            id="city_dropdown"
            data-test-id="city_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedCities}
            className="enterInputField"
            onChange={(event) => this.handleChangeCity(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    City
                  </div>);
              }
              return (selected as DropdownValues[])[(selected as DropdownValues[]).length - 1]?.attributes?.name;
            }}
            input={<InputBase />}
          >
            {cities.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Select
            labelId="plant_dropdown"
            id="plant_dropdown"
            data-test-id="plant_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedPlants}
            className="enterInputField"
            onChange={(event) => this.handleChangePlant(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Plant
                  </div>);
              }
              return (selected as DropdownValues[])[(selected as DropdownValues[]).length - 1]?.attributes?.name;
            }}
            input={<InputBase />}
          >
            {plants.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Select
            labelId="department_dropdown"
            id="department_dropdown"
            data-test-id="department_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedDepartments}
            className="enterInputField"
            onChange={(event) => this.handleChangeDepartment(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Department
                  </div>);
              }
              return (selected as DropdownValues[])[(selected as DropdownValues[]).length - 1]?.attributes?.name;
            }}
            input={<InputBase />}
          >
            {departments.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Select
            labelId="all_dropdown"
            id="all_dropdown"
            data-test-id="all_dropdown"
            IconComponent={KeyboardArrowDown}
            variant="outlined"
            displayEmpty
            value={selectedAllWorkLocation}
            className="enterInputField"
            onChange={(event) => this.handleChangeAllWorkLocation(event.target.value)}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            renderValue={(selected: unknown) => {
              if ((selected as DropdownValues[]).length === 0) {
                return (
                  <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>
                    Completed
                  </div>);
              }
              return (selected as DropdownValues[])[(selected as DropdownValues[]).length - 1]?.attributes?.name;
            }}
            input={<InputBase />}
          >
            {allWorkLocation.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Select
            data-test-id="assigned_by_dropdown"
            className="enterInputField"
            inputProps={{
              icon: {
                left: 0,
              },
            }}
            variant="outlined"
            IconComponent={KeyboardArrowDown}
            value={selectedAssignedBy}
            displayEmpty
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            onChange={this.handleChangeAssignedBy}
            input={<InputBase />}
          >
            {selectedAssignedBy.length === 0 &&
              <MenuItem value={""}>
                <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>Assigned By</div>
              </MenuItem>
            }
            {assignedBy.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Select
            data-test-id="assigned_to_dropdown"
            className="enterInputField"
            inputProps={{
              icon: {
                left: 0,
              },
            }}
            variant="outlined"
            IconComponent={KeyboardArrowDown}
            value={selectedAssignedTo}
            displayEmpty
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            onChange={this.handleChangeAssignedTo}
            input={<InputBase />}
          >
            {selectedAssignedTo.length === 0 &&
              <MenuItem value={""}>
                <div style={{ color: "#A1A1A1", fontSize: 16 } as React.CSSProperties}>Assigned To</div>
              </MenuItem>
            }
            {assignedTo.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Select
            data-test-id="year_dropdown"
            className="enterInputField"
            inputProps={{
              icon: {
                left: 0,
              },
            }}
            variant="outlined"
            IconComponent={KeyboardArrowDown}
            value={selectedYear}
            displayEmpty
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            onChange={this.handleChangeCurrentYear}
            input={<InputBase />}
          >
            {selectedYear === '' && (
              <MenuItem value="">
                <div style={{ color: '#A1A1A1', fontSize: 16 }}>
                  Year
                </div>
              </MenuItem>
            )}
            {this.handleYearsDropDownArray().map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item className="dropDownWidth">
          <Button
            data-test-id="search_button"
            variant="contained"
            className="SearchButtonStyle"
            onClick={this.handleSearch}
          >
            Search
          </Button>
        </Grid>
      </Grid >
    );
  };

  actionsCards = () => {
    const { actionsCount, tableText } = this.state;
    return (
      <Grid container justifyContent="center" spacing={2} alignItems="stretch">
        <Grid item xs={12} sm={6} md={4}>
          <Card
            className={`${tableText === "Total Assigned Actions" ? "selectedCardStyle" : "cardStyle"}`}
            onClick={() => this.handleTableandText("Total Assigned Actions")}
            data-test-id="total_assigned_action_count">
            <CardContent>
              <Box>
                <img src={totalAssignedActions} alt="Total Assigned Actions" className={`${tableText === "Total Assigned Actions" ? "activeIconStyle" : "iconStyle"}`} />
                <Typography className="cardText1">Total Assigned Actions</Typography>
                <Typography className="cardText2">{actionsCount?.total_assigned_action_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card
            className={`${tableText === "Completed Actions" ? "selectedCardStyle" : "cardStyle"}`}
            onClick={() => this.handleTableandText("Completed Actions")}
            data-test-id="total_completed_action_count">
            <CardContent>
              <Box>
                <img src={completed} alt="Completed Actions" className={`${tableText === "Completed Actions" ? "activeIconStyle" : "iconStyle"}`} />
                <Typography className="cardText1">Completed Actions</Typography>
                <Typography className="cardText2">{actionsCount?.total_completed_action_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >

        <Grid item xs={12} sm={6} md={4}>
          <Card
            className={`${tableText === "Overdue Actions" ? "selectedCardStyle" : "cardStyle"}`}
            onClick={() => this.handleTableandText("Overdue Actions")}
            data-test-id="total_overdue_action_count">
            <CardContent>
              <Box>
                <img src={overdueActions} alt="Overdue Actions" className={`${tableText === "Overdue Actions" ? "activeIconStyle" : "iconStyle"}`} />
                <Typography className="cardText1">Overdue Actions</Typography>
                <Typography className="cardText2">{actionsCount?.total_overdue_action_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >

        <Grid item xs={12} sm={6} md={4}>
          <Card
            className={`${tableText === "Due Actions" ? "selectedCardStyle" : "cardStyle"}`}
            onClick={() => this.handleTableandText("Due Actions")}
            data-test-id="total_due_action_count">
            <CardContent>
              <Box>
                <img src={dueActions} alt="Due Actions" className={`${tableText === "Due Actions" ? "activeIconStyle" : "iconStyle"}`} />
                <Typography className="cardText1">Due Actions</Typography>
                <Typography className="cardText2">{actionsCount?.total_due_action_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >

        <Grid item xs={12} sm={6} md={4}>
          <Card
            className={`${tableText === "Cancelled Actions" ? "selectedCardStyle" : "cardStyle"}`}
            onClick={() => this.handleTableandText("Cancelled Actions")}
            data-test-id="total_cancelled_action_count">
            <CardContent>
              <Box>
                <img src={cancelledActions} alt="Cancelled Actions" className={`${tableText === "Cancelled Actions" ? "activeIconStyle" : "iconStyle"}`} />
                <Typography className="cardText1">Cancelled Actions</Typography>
                <Typography className="cardText2">{actionsCount?.total_cancelled_action_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >

        <Grid item xs={12} sm={6} md={4}>
          <Card
            className={`${tableText === "Not My Domain" ? "selectedCardStyle" : "cardStyle"}`}
            onClick={() => this.handleTableandText("Not My Domain")}
            data-test-id="total_not_my_domain_action_count">
            <CardContent>
              <Box>
                <img src={notMyDomain} alt="Not My Domain" className={`${tableText === "Not My Domain" ? "activeIconStyle" : "iconStyle"}`} />
                <Typography className="cardText1">Not My Domain</Typography>
                <Typography className="cardText2">{actionsCount?.total_not_my_domain_action_count}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid >
      </Grid>
    )
  }

  actionTable = () => {
    const columnTitles = ["Assigned To", "Assigned By", "Department", "Description", "Assigned On", "Due Date", "Status"]
    const data = this.state.actionTableData?.length > 0 && this.state.actionTableData?.map((data) => {
      const array = [];
      array.push(data.attributes?.assigned_to ? data.attributes?.assigned_to : "No Data");
      array.push(data.attributes?.assigned_by ? data.attributes?.assigned_by : "No Data");
      array.push(data.attributes?.department_name ? data.attributes?.department_name : "No Data");
      array.push(data.attributes?.description ? data.attributes?.description : "No Data");
      array.push(data.attributes?.assinged_on ? this.formatDate(data.attributes?.assinged_on) : "No Data");
      array.push(data.attributes?.due_date ? this.formatDate(data.attributes?.due_date) : "No Data");

      let chipClass;
      if (data.attributes?.status === 'completed') {
        chipClass = 'greenStatusChip';
      } else if (data.attributes?.status === "not_my_domain") {
        chipClass = 'blueStatusChip';
      } else if (data.attributes?.status === "overdue") {
        chipClass = 'purpleStatusChip';
      } else if (data.attributes?.status === "cancelled") {
        chipClass = 'redStatusChip';
      } else if (data.attributes?.status === "planned") {
        chipClass = 'plannedStatusChip';
      } else if (data.attributes?.status === "pending_review") {
        chipClass = 'orangeStatusChip';
      } else if (data.attributes?.status === "due") {
        chipClass = 'dueStatusChip';
      } else {
        chipClass = 'blueChip';
      }

      array.push(<Chip className={chipClass} label={data.attributes?.status} style={{ justifyContent: "center" }} />)

      return array;
    }) || [];
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item xs={12} className="analyticTitle analyticFilterTitle">
          <Typography className="mainHeading">{this.state.tableText}</Typography>
        </Grid>
        <Grid item xs={12} className="tableScroll">
          <Box className="tableStyle">
            <Box className="rowStyle">
              {columnTitles.map((title, index) => (
                <Box key={index} className={`headerCellStyle ${index == 0 ? ' first' : ''}`}>{title}</Box>
              ))}
              <Box className="headerCellStyle last"></Box>
            </Box>
            {data.map((row, rowIndex) =>
            (
              <Box key={rowIndex} className="rowStyle">
                {row.map((cell, cellIndex) => {
                  const value = typeof (cell) === 'string' && cell.length > 25 ? `${cell.slice(0, 25)}...` : cell;
                  return (
                    <Box key={cellIndex} className="cellStyle trimWords">{value}</Box>
                  )
                })
                }
                <Box className="cellStyle">
                  <img src={moreVert} alt="More" height={24} width={24} />
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container className="firstGridContainer">
          <Wrapper>
            <Box style={webStyles.breadcrumbWrapper}>
              <Typography style={webStyles.breadcrumbItem}>Analytics</Typography>
              <NavigateNext style={webStyles.navigateIcon} />
              <Typography style={webStyles.breadcrumbActiveItem}>{this.state.tableText}</Typography>
            </Box>
            <Box className="analyticTitle">
              <Typography className="mainHeading">Action Tracker</Typography>

              {!this.state.loading && !this.state.isWorker && <Box>
                <Button
                  data-test-id="add_btn"
                  variant="contained"
                  className="exportCSVButtonStyle"
                >
                  Export CSV
                </Button>
              </Box>}
            </Box>
            {!this.state.loading && !this.state.isWorker && <Box>
              {this.dropdownRow()}
              {this.actionsCards()}
              {this.actionTable()}
            </Box>}
            {!this.state.loading && this.state.totalPageCount > 0 &&
              <Grid container className="paginationGrid">
                <Grid item className="paginationGridLeft">
                  {(this.state.currentPage - 1) * this.state.perPage + 1} - {(this.state.currentPage - 1) * this.state.perPage + this.state.perPage} of {this.state.totalPageCount}
                </Grid>
                <Grid item>
                  <Pagination
                    count={Math.ceil(this.state.totalPageCount / 10)}
                    page={this.state.currentPage}
                    size="small"
                    variant="text"
                    boundaryCount={1}
                    siblingCount={0}
                    onChange={this.handlePageChange}
                    data-test-id="handlePageChange"
                  />
                </Grid>
              </Grid>
            }
            {!this.state.loading && this.state.errorMsg && (
              <Grid container justifyContent="center" alignItems="center">
                <Typography
                  variant="body1"
                  className="errorText"
                >
                  {this.state.errorMsg}
                </Typography>
              </Grid>
            )}
            {this.state.loading && (
              <Grid container justifyContent="center">
                <CircularProgress className="loader" />
              </Grid>
            )}

          </Wrapper>
        </Grid>
      </>
    // Customizable Area End
    );
  }
}

// Customizable Area Start
export const Wrapper = styled(Box)({
  width: "100%",
  backgroundColor: "rgb(238, 238, 238)",
  padding: "30px",
  boxSizing: "border-box",
  height: "100%",
  overflowY: "auto",
  overflowX: "hidden",
  position: "relative",
  "& .loader": {
    color: "#8735e1"
  },
  "& .errorText": {
    fontSize: "20px",
    marginTop: "40px",
    fontFamily: "clotherRegular"
  },
  "& .analyticHeadingWrapper": {
    display: "flex",
    gap: "10px",
    marginTop: '10px'
  },
  "& .subHeadingText": {
    color: '#18181E',
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherRegular",
  },
  "& .analyticTitle": {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0px",
  },
  "& .analyticFilterTitle": {
    margin: "30px 0px 10px 0px",
  },
  "& .mainHeading": {
    color: "#2B2A29",
    leadingTrim: "both",
    textEdge: "cap",
    fontSize: "26px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "25px",
    fontFamily: "ClotherBold",
    width: "300px"
  },
  "& .exportCSVButtonStyle": {
    boxShadow: "none",
    padding: "10px 10px 14px 10px",
    color: "#FFF",
    textAlign: "center",
    borderRadius: "8px",
    background: "#8735E1",
    width: "140px",
    height: "35px",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  "& .SearchButtonStyle": {
    boxShadow: "none",
    padding: "5px 10px 10px 10px",
    color: "#8735E1",
    backgroundColor: "#FFF",
    textAlign: "center",
    borderRadius: "8px",
    border: "1px solid #8735E1",
    width: "160px",
    height: "48px",
    fontFamily: "ClotherBold",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize",
  },
  "& .activeHeadingText": {
    color: "#8735E1",
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "ClotherBold",
    cursor: "pointer"
  },
  "& .navigateNext": {
    color: "#DCDCDC",
  },
  "& .enterInputField": {
    fontFamily: 'ClotherRegular',
    backgroundColor: 'white',
    border: '1px solid lightgray',
    fontSie: '18px',
    borderRadius: '8px',
    height: '50px',
    padding: '10px',
    width: "100%"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "white"
  },
  "& .MuiSelect-outlined.MuiSelect-outlined": {
    paddingRight: "16px",
    marginBottom: "6px"
  },
  "& .cardText1": {
    fontFamily: "ClotherRegular",
    fontWeight: 400,
    fontSize: "18px"
  },
  "& .cardText2": {
    fontFamily: "ClotherRegular",
    fontWeight: 700,
    fontSize: "30px"
  },
  "& .cardStyle": {
    cursor: "pointer",
    margin: 1,
    background: "#fff",
    border: "1px solid #FFFFF",
    borderRadius: "8px",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "8px",
    },
    "& .MuiCardContent-root": {
      padding: "8px 16px 16px 16px"
    }
  },
  "& .cardStyle:hover": {
    border: "1px solid #8735E1",
    backgroundColor: "#F7F0FF",
    margin: 0
  },
  "& .selectedCardStyle": {
    cursor: "pointer",
    margin: 0,
    background: "#F7F0FF",
    border: "1px solid #8735E1",
    borderRadius: "8px",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    "& .MuiCardContent-root:last-child": {
      paddingBottom: "8px",
    },
    "& .MuiCardContent-root": {
      padding: "8px 16px 16px 16px"
    }
  },
  "& .tableStyle": {
    display: 'table',
    width: '100%',
    backgroundColor: 'white',
  },
  "& .rowStyle": {
    display: 'table-row'
  },
  "& .redStatusChip": {
    borderRadius: "15px",
    color: "#DC2626",
    backgroundColor: "#FEE2E2",
    border: "1px solid #FEE2E2",
    fontFamily: "ClotherBold",
    fontSize: "12px",
    height: "22px",
    marginTop: 10,
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },
  "& .blueChip": {
    width: "auto",
    borderRadius: "15px",
    color: "darkgrey",
    backgroundColor: "#e0e0e0;",
    border: "1px solid #FEE2E2",
    fontFamily: "ClotherBold",
    fontSize: "12px",
    height: "22px",
    marginTop: 10,
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },

  "& .headerCellStyle": {
    display: 'table-cell',
    backgroundColor: "#8735E1",
    color: 'white',
    padding: '10px',
    textAlign: 'left',
    fontFamily: "ClotherRegular",
    fontSize: "16px",
    fontWeight: 700,
  },
  "& .headerCellStyle.first": {
    borderRadius: "8px 0px 0px 0px"
  },
  "& .headerCellStyle.last": {
    borderRadius: "0px 8px 0px 0px"
  },
  "& .tableScroll": {
    overflowX: "auto"
  },
  "& .cellStyle": {
    display: 'table-cell',
    padding: '10px',
    textAlign: 'left',
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .plannedStatusChip": {
    backgroundColor: "#DDF6FF",
    color: "#00B0F0",
    border: "1px solid #DDF6FF",
    borderRadius: "15px",
    fontFamily: "ClotherBold",
    fontSize: "12px",
    height: "22px",
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },
  "& .trimWords": {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: "nowrap",
    maxWidth: '100%'
  },
  "& .chipClass": {
    height: "22px",
    marginTop: 10,
    backgroundColor: "Green",
    color: "#A1A1A1",
    border: "1px solid",
    borderRadius: "15px",
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
      fontWeight: 400
    }
  },
  "& .greenStatusChip": {
    fontFamily: "ClotherBold",
    fontSize: "12px",
    backgroundColor: "#DBFFED",
    color: "#21C274",
    border: "1px solid #DBFFED",
    borderRadius: "15px",
    height: "22px",
    marginTop: 10,
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },
  "& .blueStatusChip": {
    border: "1px solid #DBE5FF",
    borderRadius: "15px",
    backgroundColor: "#DBE5FF",
    color: "#2175C2",
    fontFamily: "ClotherBold",
    fontSize: "12px",
    height: "22px",
    marginTop: 10,
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },
  "& .purpleStatusChip": {
    backgroundColor: "#EEDEFF",
    color: "#8735E1",
    border: "1px solid #EEDEFF",
    borderRadius: "15px",
    fontFamily: "ClotherBold",
    fontSize: "12px",
    height: "22px",
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },
  "& .orangeStatusChip": {
    fontSize: "12px",
    height: "22px",
    color: "#DF9300",
    backgroundColor: "#FFF4DE",
    border: "1px solid #FFF4DE",
    borderRadius: "15px",
    fontFamily: "ClotherBold",
    marginTop: 10,
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },
  "& .firstGridContainer": {
    height: "100%",
  },
  "& .dueStatusChip": {
    borderRadius: "15px",
    fontFamily: "ClotherBold",
    color: "#DF9300",
    backgroundColor: "#FFF4DE",
    border: "1px solid #FFF4DE",
    fontSize: "12px",
    height: "22px",
    marginTop: 10,
    width: "auto",
    "& .MuiChip-label": {
      padding: "0px 5px 4px 5px",
    }
  },
  "& .dropDownRowMargin": {
    marginBottom: 16
  },
  "& .dropDownWidth": {
    minWidth: "150px",
    width: "10%"
  },
  "& .paginationGrid": {
    display: "flex",
    margin: "20px 10px 9px 1px",
    alignItems: "center",
    justifyContent: "space-between"
  },
  "& .paginationGridLeft": {
    fontFamily: "ClotherRegular",
    fontSize: "14px"
  },

  "& .MuiSelect-iconOutlined": {
    color: "#DCDCDC"
  },
  "& .MuiPaginationItem-page.Mui-selected": {
    color: "#8735E1",
    backgroundColor: "inherit",
    fontWeight: 700
  },
  "& .iconStyle": {
    width: "40px",
    height: "40px",
    padding: "9px",
    border: "1px solid #F7F0FF",
    borderRadius: "8px",
    marginBottom: "8px",
    marginTop: "8px",
  },
  "& .activeIconStyle": {
    width: "40px",
    height: "40px",
    padding: "9px",
    borderRadius: "8px",
    boxShadow: "-2px 2px 8px 0px #8735e11f",
    marginBottom: "8px",
    marginTop: "8px",
  }
})
// Customizable Area End
