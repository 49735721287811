// Customizable Area Start
//@ts-nocheck
import React from "react";

import {
  Container,
  Grid,
  Typography,
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Switch,
  TextField,
  Select,
  Input,
  InputBase,
  InputAdornment,
  MenuItem,
  Chip,
  FormHelperText,
  withStyles
} from "@material-ui/core";

import { Close, NavigateNext } from "@material-ui/icons";

import Autocomplete from "@material-ui/lab/Autocomplete";

import DatePicker from "react-datepicker";

import { mic, micStart, mikeIcon, calendarIcon } from "../assets";
import SupervisorpostCreationController, {
  Props,
  configJSON
} from "./SupervisorPostCreationController";
import { WrapperStepOne } from "../styles";

const today = new Date()

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: "38px",
    height: "22px",
    margin: "0px 5px 5px 0px"
  },
  switchBase: {
    color: "#fff",
    bottom: 0,
    "&$checked": {
      color: "#8735e1",
    },
    "&$checked + $track": {
      backgroundColor: "#8735e1",
    },
  },
  checked: {},
  track: {},
}))(Switch);

export default class SupervisorPostCreation extends SupervisorpostCreationController {
  constructor(props: Props) {
    super(props);
  }

  renderMic = () => {
    return this.state.listening ? micStart : mic;
  }

  renderMicForStatutory = () => {
    return this.state.listeningStatutory ? micStart : mic;
  }

  renderMicForSopCompliance = () => {
    return this.state.listeningSopCompliance ? micStart : mic;
  }

  renderMicForNoGoParameter = () => {
    return this.state.listeningNoGoParameter ? micStart : mic;
  }

  renderMicForSafetyViolation = () => {
    return this.state.listeningSafetyViolation ? micStart : mic;
  }

  renderMicForCorrectiveAction = (counter, currentCounter) => {
    return counter === currentCounter && this.state.listeningCorrectiveAction ? micStart : mic;
  }

  renderMicForPreventiveAction = (counter, currentCounter) => {
    return counter === currentCounter && this.state.listeningPreventiveAction ? micStart : mic;
  }

  Step1 = () => {
    const { nextClicked, severity, frequency, customClass, post, consequences, selectedConsequence, frequencies, selectedFrequency, severities, selectedSeverity, hazards, selectedHazard, concerns, selectedConcern, issueDescription, location, loadingGetPost } = this.state;
   
    return (
      <WrapperStepOne>
        <Container maxWidth={"xl"} className="custom-container">
          <Box className="card">
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Typography className="related-title">
                  {configJSON.relatedImgText}
                </Typography>
                <Box>
                  <img
                    name="related_image"
                    className="related-img"
                    src={post?.image_url}
                    alt="issue-image"
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="Describe_wrapper">
                  <Typography className="related-title">
                    {configJSON.describeIssue}
                  </Typography>
                  <img
                    src={this.renderMic()}
                    alt="mic icon"
                    className="mikeIcon"
                    onClick={
                      async () => {
                        await this.toggleListening();
                        this.setState({
                          issueDescription: issueDescription + " " + this.state.transcription
                        });
                      }
                    }
                    data-test-id="issue_description_speechtext"
                  />
                </Box>
                <textarea
                  data-test-id="issue_description"
                  name="issue_description"
                  placeholder="Write Your Comment"
                  className="Location-area"
                  rows={3}
                  value={issueDescription}
                  onChange={(e) => this.setState({ issueDescription: e.target.value })}
                />
                <FormHelperText error>
                  {!loadingGetPost && nextClicked && !issueDescription && configJSON.errorText}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: '0' }}>
                <Box className="locationWrapper">
                  <Typography className="related-title">
                    {configJSON.location}
                  </Typography>
                  <Box className="auto_location">
                  <CustomSwitch size="small" />
                    <Typography className="related-title">
                      {configJSON.autoDetect}
                    </Typography>
                  </Box>
                </Box>
                <TextField
                  data-test-id="location"
                  id="outlined-basic"
                  name="location"
                  className="Location-input"
                  variant="outlined"
                  value={location}
                  placeholder="Location"
                  InputProps={{
                    readOnly: true,
                    style: { pointerEvents: 'none', backgroundColor: "#e4e2e2" },
                  }}
                />
                <FormHelperText error>
                  {!loadingGetPost && nextClicked && !location && configJSON.errorText}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} style={{ paddingBottom: '0' }}>
                <Box className="ConcernWrapper">
                  <Typography className="related-title">
                    {configJSON.concern}
                  </Typography>
                  <Select
                    data-test-id="concern"
                    className="Location-select"
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    variant="outlined"
                    name="related_concern"
                    value={selectedConcern + ''}
                    onChange={(event) => this.setState({ selectedConcern: event.target.value })}
                    input={<InputBase />}
                    displayEmpty
                    renderValue={
                      selectedConcern
                        ? undefined
                        : () => (
                          <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                            Concern is related to
                          </div>
                        )
                    }
                    MenuProps={{
                      MenuListProps: {
                        className: 'MenuListProps-modal',
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {concerns?.data?.map(concern => (
                      <MenuItem key={concern.id} value={concern.id}>
                        {concern.attributes.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error>
                    {!loadingGetPost && nextClicked && !selectedConcern && configJSON.errorText}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ padding: '5px 15px' }}>
                <Chip
                  label="#AB675"
                  onDelete={() => { }}
                  className="color-code-btn"
                  deleteIcon={<Close />}
                />
              </Grid>
              <Grid item xs={6}>
                <Box className="Location-select-wrapper" style={{ position: "relative" }}>
                  <Typography className="related-title">{configJSON.hazard}</Typography>
                  <Select
                    data-test-id="hazard"
                    className="Location-select"
                    variant="outlined"
                    name="hazard"
                    value={selectedHazard + ''}
                    onChange={(event) => this.setState({ selectedHazard: event.target.value })}
                    input={<InputBase />}
                    displayEmpty
                    renderValue={
                      selectedHazard
                        ? undefined
                        : () => (
                          <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                            Hazard
                          </div>
                        )
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {hazards?.data?.map(hazard => (
                      <MenuItem key={hazard.id} value={hazard.id}>
                        {hazard.attributes.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error>
                    {!loadingGetPost && nextClicked && !selectedHazard && configJSON.errorText}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="Location-select-wrapper">
                  <Typography className="related-title">
                    {configJSON.frequency}
                  </Typography>
                  <Select
                    data-test-id="frequency"
                    className="Location-select"
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    variant="outlined"
                    value={frequency + ''}
                    onChange={this.handleFrequencyChange}
                    label="Frequency"
                    input={<InputBase />}
                    displayEmpty
                    renderValue={
                      frequency
                        ? undefined
                        : () => (
                          <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                            Frequency
                          </div>
                        )
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {frequencies?.data?.map(frequency => (
                      <MenuItem key={frequency.id} value={frequency.id}>
                        {frequency.attributes.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error>
                    {!loadingGetPost && nextClicked && !frequency && configJSON.errorText}
                  </FormHelperText>

                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="Location-input-wrapper">
                  <Typography className="related-title">
                    {configJSON.severity}
                  </Typography>
                  <Select
                    data-test-id="severity"
                    className="Location-select"
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    variant="outlined"
                    value={severity + ''}
                    onChange={this.handleSeverityChange}
                    label="Severity"
                    input={<InputBase />}
                    displayEmpty
                    renderValue={
                      severity
                        ? undefined
                        : () => (
                          <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                            Severity
                          </div>
                        )
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {severities?.data?.map(severity => (
                      <MenuItem key={severity.id} value={severity.id}>
                        {severity.attributes.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error>
                    {!loadingGetPost && nextClicked && !severity && configJSON.errorText}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="Location-input-wrapper">
                  <Typography className="related-title">
                    {configJSON.consequence}
                  </Typography>
                  <Select
                    data-test-id="consequence"
                    className="Location-select"
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    variant="outlined"
                    name="consequence"
                    value={selectedConsequence + ''}
                    onChange={(event) => this.setState({ selectedConsequence: event.target.value })}
                    label="Consequence(HSE) - Potential Impact"
                    input={<InputBase />}
                    displayEmpty
                    renderValue={
                      selectedConsequence
                        ? undefined
                        : () => (
                          <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                            Consequence
                          </div>
                        )
                    }
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {consequences?.data?.map(consequence => (
                      <MenuItem key={consequence.id} value={consequence.id}>
                        {consequence.attributes.name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error>
                    {!loadingGetPost && nextClicked && !selectedConsequence && configJSON.errorText}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className="Location-input-wrapper">
                  <Typography className="related-title">
                    {configJSON.riskLevel}
                  </Typography>
                  <input
                    disabled
                    placeholder="Risk Levels"
                    value={customClass && selectedSeverity + selectedFrequency}
                    type="text"
                    className={`riskLevelInput ${customClass || ""}`}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </WrapperStepOne >)
  }

  Step2 = () => {
    const { nextClicked, selectedActivity, activityIdentifications, selectedTask, taskIdentifications } = this.state;
    return (<WrapperStepOne>
      <Container maxWidth={"xl"} className="custom-container">
        <Box style={{ height: "calc(100vh - 320px)" }} className="card">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box className="Location-select-wrapper">
                <Typography className="related-title">
                  {configJSON.activity}
                </Typography>
                <Select
                  data-test-id="activity-identification"
                  className="Location-select"
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  variant="outlined"
                  value={selectedActivity + ''}
                  onChange={(event) => this.setState({ selectedActivity: event.target.value })}
                  input={<InputBase />}
                  displayEmpty
                  renderValue={
                    selectedActivity
                      ? undefined
                      : () => (
                        <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                          Activity Identification
                        </div>
                      )
                  }
                  MenuProps={{
                    MenuListProps: {
                      className: 'MenuListProps-modal',
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {activityIdentifications?.data?.map(activity => (
                    <MenuItem key={activity.id} value={activity.id}>
                      {activity.attributes.name}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error>
                  {!selectedActivity && nextClicked && configJSON.errorText}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="Location-select-wrapper">
                <Typography className="related-title">
                  {configJSON.task}
                </Typography>
                <Select
                  data-test-id="task-identification"
                  className="Location-select"
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  variant="outlined"
                  value={selectedTask + ''}
                  onChange={(event) => this.setState({ selectedTask: event.target.value })}
                  displayEmpty
                  renderValue={
                    selectedTask
                      ? undefined
                      : () => (
                        <div style={{ color: "#DCDCDC" } as React.CSSProperties}>
                          Task Identification
                        </div>
                      )
                  }
                  MenuProps={{
                    MenuListProps: {
                      className: 'MenuListProps-modal',
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  input={<InputBase />}
                >
                  {taskIdentifications?.data?.map(task => (
                    <MenuItem key={task?.id} value={task?.id}>
                      {task.attributes.name}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error>
                  {!selectedTask && nextClicked && configJSON.errorText}
                </FormHelperText>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="Location-input-wrapper">
                <Typography className="related-title">
                  {configJSON.training}
                </Typography>
                <TextField
                  className="Location-input"
                  id="outlined-basic"
                  label="N/A"
                  variant="outlined"
                  disabled
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box className="Location-input-wrapper">
                <Typography className="related-title">
                  {configJSON.safetyContact}
                </Typography>
                <TextField
                  className="Location-input"
                  id="outlined-basic"
                  label="N/A"
                  variant="outlined"
                  disabled
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </WrapperStepOne>)
  }

  Step3 = () => {
    const {
      statutory,
      sopCompliance,
      noGoParameter,
      safetyViolation,
      riskCount,
      nextClicked
    } = this.state;
    return (<WrapperStepOne>
      <Container maxWidth={"xl"} className="custom-container">
        <Box className="card">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Box className="Describe_wrapper">
                <Typography className="related-title">
                  {configJSON.statutory}
                </Typography>
                <img
                  src={this.renderMicForStatutory()}
                  className="mikeIcon"
                  data-test-id="statutory_speechtext"
                  onClick={
                    async () => {
                      await this.toggleListeningForStatutory();
                      this.setState({
                        statutory: statutory + " " + this.state.transcription
                      });
                    }
                  }
                />
              </Box>

              <textarea
                data-test-id="statutory"
                placeholder="Statutory / Regulatory / Legal Concern Description"
                className="Location-area"
                rows={5}
                value={statutory}
                onChange={(e) => this.setState({ statutory: e.target.value })}
              />

              <FormHelperText error>
                {statutory === "" && nextClicked && configJSON.errorText}
              </FormHelperText>

            </Grid>
            <Grid item xs={6}>
              <Box className="Describe_wrapper">
                <Typography className="related-title">
                  {configJSON.sop}
                </Typography>
                <img
                  src={this.renderMicForSopCompliance()}
                  data-test-id="sopCompliance_speechtext"
                  className="mikeIcon"
                  onClick={
                    async () => {
                      await this.toggleListeningForSopCompliance();
                      this.setState({
                        sopCompliance: sopCompliance + " " + this.state.transcription
                      });
                    }
                  }
                />
              </Box>

              <textarea
                data-test-id="compliance-description"
                placeholder="SOP Compliance Description"
                className="Location-area"
                rows={5}
                value={sopCompliance}
                onChange={(e) =>
                  this.setState({ sopCompliance: e.target.value })
                }
              />

              <FormHelperText error>
                {sopCompliance === "" && nextClicked && configJSON.errorText}
              </FormHelperText>

            </Grid>
            <Grid item xs={6}>
              <Box className="Describe_wrapper">
                <Typography className="related-title">
                  {configJSON.noGo}
                </Typography>
                <img
                  data-test-id="noGoParameter_speechtext"
                  src={this.renderMicForNoGoParameter()}
                  className="mikeIcon"
                  onClick={
                    async () => {
                      await this.toggleListeningForNoGoParameter();
                      this.setState({
                        noGoParameter: noGoParameter + " " + this.state.transcription
                      });
                    }
                  }
                />
              </Box>

              <textarea
                data-test-id="violation-description"
                placeholder="No Go Parameter Violation Description"
                className="Location-area"
                rows={5}
                value={noGoParameter}
                onChange={(e) =>
                  this.setState({ noGoParameter: e.target.value })
                }
              />

              <FormHelperText error>
                {noGoParameter === "" && nextClicked && configJSON.errorText}
              </FormHelperText>

            </Grid>
            <Grid item xs={6}>
              <Box className="Describe_wrapper">
                <Typography className="related-title">
                  {configJSON.safety}
                </Typography>
                <img
                  src={this.renderMicForSafetyViolation()}
                  data-test-id="safetyViolation_speechtext"
                  className="mikeIcon"
                  onClick={
                    async () => {
                      await this.toggleListeningForSafetyViolation();
                      this.setState({
                        safetyViolation: safetyViolation + " " + this.state.transcription
                      });
                    }
                  }
                />
              </Box>

              <textarea
                data-test-id="safety-violation"
                placeholder="Safety Absolute Violation Description"
                className="Location-area"
                rows={5}
                value={safetyViolation}
                onChange={(e) =>
                  this.setState({ safetyViolation: e.target.value })
                }
              />

              <FormHelperText error>
                {safetyViolation === "" && nextClicked && configJSON.errorText}
              </FormHelperText>
            </Grid>

            <Grid item xs={6}>
              <Box
                style={{ color: "#DCDCDC", fontWeight: 400 }}
                className="Location-input-wrapper"
              >
                <Typography className="related-title">{configJSON.riskCount}</Typography>
                <TextField
                  data-test-id="risk-count"
                  placeholder="Risk Count"
                  className="Location-input"
                  id="outlined-basic"
                  variant="outlined"
                  value={riskCount}
                  onChange={(e) =>
                    this.setState({ riskCount: e.target.value })
                  }
                />

                <FormHelperText error>
                  {riskCount === "" && nextClicked && configJSON.errorText}
                  {riskCount !== "" && !(/^\d*$/.test(riskCount)) && "Please enter numeric value"}
                </FormHelperText>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </WrapperStepOne>)
  }

  Step4 = () => {
    const { correctiveActions, preventiveActions, personList } = this.state;
    let options = [];
    personList.length > 0 && personList?.forEach(item => {
      if (item["full name"] !== null && item["full name"] !== "") {
        options.push({
          label: `${item["full name"].trim()}`,
          value: item.id
        })
      }
    })
    options = options.sort((a, b) => a.label.localeCompare(b.label));

    return (<WrapperStepOne>
      <Container maxWidth={"xl"} className="custom-container">
        <Box className="card">
          <Grid container>

            {correctiveActions.map((value, index) => {
              return (
                <Grid container spacing={3} key={value.counter}>
                  <Grid item xs={6}>
                    <Box style={{ marginTop: "10px" }} className="Describe_wrapper">
                      <Typography className="related-title">
                        Corrective Action {index + 1}
                      </Typography>
                      <img
                        src={this.renderMicForCorrectiveAction(value.counter, this.state.currentCorrectiveActionSpeech)}
                        className="mikeIcon"
                        data-test-id="correctiveAction_speechtext"
                        onClick={
                          async () => {
                            this.setState({currentCorrectiveActionSpeech : value.counter})
                            await this.toggleListeningForCorrectiveAction();
                            this.handleChangeCorrectiveAction(index, "correctiveAction", value.correctiveAction + " " + this.state.transcription);
                          }
                        }
                      />
                    </Box>
                    <textarea
                      data-test-id="corrective-description"
                      placeholder={`Corrective Action ${index + 1}`}
                      className="Location-area"
                      rows={5}
                      name="description"
                      value={value.correctiveAction}
                      onChange={(e) => this.handleChangeCorrectiveAction(index, "correctiveAction", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <Autocomplete
                      options={options}
                      loading={!Object.keys(personList).length}
                      value={value?.assignedPerson}
                      data-test-id="corrective-assigned-person"
                      id={`assigned_person_for_CA_${index}`}
                      getOptionLabel={(option) => `${option?.label}`}
                      name={`correctiveActions.${index}.assigned_person_for_CA`}
                      onChange={(e, newValue) => this.handleChangeCorrectiveAction(index, "assignedPerson", newValue)}
                      onInputChange={() => { }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id={`assigned_person_for_CA_input_${index}`}
                          inputLabelProps={{
                            shrink: true,
                          }}
                          placeholder={`Assign Person for the Corrective Action ${index + 1}`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} className="date-wrapper">
                    <DatePicker
                      scrollableYearDropdown
                      className="Location-select"
                      showYearDropdown
                      yearDropdownItemNumber={100}
                      dateFormatCalendar="MMMM"
                      dateFormat="dd/MM/yyyy"
                      data-test-id="corrective-due-date"
                      name="due_date_CA"
                      placeholderText="Due Date"
                      minDate={today}
                      wrapperClassName="date-picker"
                      data-testId="date-picker-inline-todate"
                      selected={correctiveActions[index].dueDate}
                      onChange={(date) => this.handleChangeCorrectiveAction(index, "dueDate", date)}
                      popperClassName="example-datepicker-class"
                      customInput={
                        <Input
                          onClick={() => { }}
                          fullWidth={true}
                          disableUnderline
                          data-testid="textInputTest"
                          name="dateTest"
                          defaultValue={""}
                          endAdornment={
                            <div>
                              <InputAdornment position="end">
                                <img
                                  src={calendarIcon}
                                  style={{
                                    width: 28,
                                    height: 28,
                                    cursor: "pointer",
                                  }}
                                />
                              </InputAdornment>
                            </div>
                          }
                        />
                      }
                    />
                  </Grid>
                  {correctiveActions.length > 1 && (
                    <Grid item xs={6}>
                      <Button
                        data-test-id="remove-corrective"
                        className="next-btn add-action"
                        onClick={() => this.removeCorrectiveAction(index)}
                      >
                        {configJSON.remove}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )
            })}

            <Grid container spacing={3} >
              <Grid item xs={6}>
                <Button
                  data-test-id="add-corrective"
                  className="next-btn add-action"
                  onClick={this.addCorrectiveAction}
                >
                  {configJSON.addCA}
                </Button>
              </Grid>
            </Grid>

            {preventiveActions.map((value, index) => (
              <Grid container spacing={3} key={value.counter}>
                <Grid item xs={6}>
                  <Box style={{ marginTop: "10px" }} className="Describe_wrapper">
                    <Typography className="related-title">
                      Preventive Action {index + 1}
                    </Typography>
                    <img
                      src={this.renderMicForPreventiveAction(value.counter, this.state.currentPreventiveActionSpeech)}
                      className="mikeIcon"
                      data-test-id="preventiveAction_speechtext"
                      onClick={
                        async () => {
                          this.setState({currentPreventiveActionSpeech : value.counter})
                          await this.toggleListeningForPreventiveAction();
                          this.handleChangePreventiveAction(index, "preventiveAction", value.preventiveAction + " " + this.state.transcription);
                        }
                      }
                    />
                  </Box>
                  <textarea
                    data-test-id="preventive-description"
                    placeholder={`Preventive Action ${index + 1}`}
                    className="Location-area"
                    rows={5}
                    name="preventive_action"
                    value={value.preventiveAction}
                    onChange={(e) => this.handleChangePreventiveAction(index, "preventiveAction", e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <Autocomplete
                    data-test-id="preventive-assigned-person"
                    id={`assigned_person_for_PA_${index}`}
                    name={`preventiveActions.${index}.assigned_person_for_PA`}
                    loading={!Object.keys(personList).length}
                    options={options}
                    value={value?.assignedPerson}
                    getOptionLabel={(option) => `${option?.label}`}
                    onChange={(e, newValue) => this.handleChangePreventiveAction(index, "assignedPerson", newValue)}
                    onInputChange={() => { }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={`assigned_person_for_PA_input_${index}`}
                        inputLabelProps={{
                          shrink: true,
                        }}
                        placeholder={`Assign Person for the Preventive Action ${index + 1}`}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6} className="date-wrapper">
                  <DatePicker
                    className="Location-select"
                    scrollableYearDropdown
                    showYearDropdown
                    yearDropdownItemNumber={100}
                    dateFormat="dd/MM/yyyy"
                    dateFormatCalendar="MMMM"
                    data-test-id="preventive-due-date"
                    name="due_date_PA"
                    minDate={today}
                    placeholderText="Due Date"
                    wrapperClassName="date-picker"
                    data-testId="date-picker-inline-todate"
                    selected={preventiveActions[index].dueDate}
                    onChange={(date) => this.handleChangePreventiveAction(index, "dueDate", date)}
                    popperClassName="example-datepicker-class"
                    customInput={
                      <Input
                        name="dateTest"
                        onClick={() => { }}
                        fullWidth={true}
                        disableUnderline
                        data-testid="textInputTest"
                        defaultValue={""}
                        endAdornment={
                          <div>
                            <InputAdornment position="end">
                              <img
                                src={calendarIcon}
                                style={{
                                  width: 28,
                                  height: 28,
                                  cursor: "pointer",
                                }}
                              />
                            </InputAdornment>
                          </div>
                        }
                      />
                    }
                  />
                </Grid>

                {preventiveActions.length > 1 && (
                  <Grid item xs={6}>
                    <Button
                      data-test-id="remove-preventive"
                      className="next-btn add-action"
                      onClick={() => this.removePreventiveAction(index)}
                    >
                      {configJSON.remove}
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}

            <Grid container spacing={3} >
              <Grid item xs={6}>
                <Button
                  data-test-id="add-preventive"
                  className="next-btn add-action"
                  onClick={this.addPreventiveAction}
                >
                  {configJSON.addPA}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </WrapperStepOne>)
  }

  render() {
    const { activeStep, loading, postClicked } = this.state;

    const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];

    let stepComponent;
    switch (activeStep) {
      case 0:
        stepComponent = this.Step1();
        break;
      case 1:
        stepComponent = this.Step2();
        break;
      case 2:
        stepComponent = this.Step3();
        break;
      case 3:
        stepComponent = this.Step4();
        break;
      default:
        stepComponent = this.Step1();
    }

    return (
      <Grid container>
        <WrapperStepOne>
          <Container maxWidth={"xl"}>
            {
              this.state.From === "notifications" ?
                <Box className="headingWrapper">
                  <Typography className="subHeadingText">Home</Typography>
                  <Typography className="subHeadingText">
                    <NavigateNext className="navigateNext" />
                  </Typography>
                  <Typography className="subHeadingText">Notifications</Typography>
                  <Typography className="subHeadingText">
                    <NavigateNext className="navigateNext" />
                  </Typography>
                  <Typography className="subHeadingText">
                    Post
                  </Typography>
                </Box> :
                <>
                  <Box className="headingWrapper">
                    <Typography
                      data-test-id="navigate-myposts"
                      className="subHeadingText"
                      onClick={this.navigateToMyPosts}
                    >
                      {configJSON.HomeText}
                    </Typography>
                    <Typography>
                      <NavigateNext className="navigateNext" />
                    </Typography>
                    <Typography
                      className="subHeadingText"
                      onClick={this.navigateToMyPosts}
                    >
                      {configJSON.unActioned}
                    </Typography>
                    <Typography>
                      <NavigateNext className="navigateNext" />
                    </Typography>
                    <Typography
                      data-test-id="navigate-back"
                      className="subHeadingText"
                      onClick={this.navigateBack}
                    >
                      {configJSON.postHeaderText}
                    </Typography>
                  </Box>
                  <Box className="myPostsTitle">
                    <Typography className="mainHeading">Post</Typography>
                  </Box>
                </>
            }
            <Box sx={{ width: "50%" }}>
              <Stepper className="customStepper" activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Box>

            {stepComponent}

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mb: 5,
              }}
            >
              <Box>
                {activeStep !== 0 && <Button data-test-id="back-button" className="next-btn" onClick={this.handleBack}>
                  {configJSON.PreviousText}
                </Button>}
              </Box>
              <Box sx={{ display: "flex" }}>
                {activeStep === 3 && <Button
                  data-test-id="Post-btn"
                  className="post-btn"
                  disabled={postClicked}
                  onClick={() => this.postData(this.state.postId)}
                  sx={{ mr: 1 }}
                >
                  {configJSON.postHeaderText}
                </Button>}
                <Box />
                {activeStep !== steps.length - 1 && (
                  <Button data-test-id="Next-btn" className="next-btn" onClick={() => this.handleNext(this.state.postId)}>
                    {configJSON.NextText}
                  </Button>
                )}
              </Box>
            </Box>

            {loading && (
              <Box data-test-id={"activityLoader"} className="loader-outer">
                <Box className="loader-inner">
                  <CircularProgress />
                </Box>
              </Box>
            )}
          </Container>
        </WrapperStepOne>
      </Grid>
    );
  }
}

// Customizable Area End
