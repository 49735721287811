// Customizable Area Start
import { Box, Dialog, Typography, Button } from "@material-ui/core";
import React from "react";
import { congrats, heartHands, spots, star } from "./assets";
import { AccountTile } from "../../../components/src/AccountTile";
import { webStyles } from "../../../components/src/styles";
import CongratsModalController, { Props } from "./CongratsModalController.web";
import { Link } from "react-router-dom";
// Customizable Area End
export default class CongratsModalStep extends CongratsModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  calculateProgress = () => {
    const { activeStepState } = this.props;

    let progress = 100;

    if (activeStepState === 1) {
      progress = 50;
    } else if (activeStepState === 3) {
      progress = 80;
    }

    return progress;
  };

  getContributionMessage = () => {
    const { activeStepState } = this.props;

    if (activeStepState === 1) {
      return "50 contribution points earned for adding Personal Details.";
    } else if (activeStepState === 3) {
      return "80 contribution points earned for adding Risk Details.";
    } else if (activeStepState === 4) {
      return "100 contribution points earned for adding Goal Details.";
    }

    return null;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const progress = this.calculateProgress();
    const contributionMessage = this.getContributionMessage();
    return (
      <>
        <Dialog data-id="congrats-dialog" open={this.state.openModal} fullWidth>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={webStylesLocal.dialogContainer}
          >
            <Box textAlign="center" fontFamily="ClotherRegular" mt={5}>
              <img src={congrats} alt="congrats" />
              <h3 style={webStylesLocal.noteFont}>
                Congrats {this.state.accountData.attributes?.full_name}!
              </h3>
              <Typography style={webStylesLocal.noteFont}>
                {contributionMessage}
              </Typography>
              <AccountTile
                name="Contribution"
                bottomContent={
                  <div style={webStyles.bottomContent}>
                    <span style={webStyles.bottomSpan}>Level Coming Soon</span>
                    <img src={star} alt="star" style={webStyles.starImg} />
                  </div>
                }
                img={heartHands}
                addTop={
                  <div>
                    <span style={webStyles.addTop as React.CSSProperties}>
                      Coming Soon
                    </span>
                    <span>Points</span>
                  </div>
                }
                addBottom={
                  <div style={webStyles.addBottomCont}>
                    <span style={webStyles.addBottom as React.CSSProperties}>
                      Need
                    </span>
                    <span
                      style={webStyles.addBottomHigh as React.CSSProperties}
                    >
                      Coming Soon
                    </span>
                    <span style={webStyles.addBottom as React.CSSProperties}>
                      For Level Coming Soon
                    </span>
                  </div>
                }
                extended
                customStyles={{
                  background: "#FAF3FD",
                  border: "1px solid var(--Purple, #8735E1)",
                  height: "auto",
                }}
              />
            </Box>
            <Box display="flex" gridGap="1rem" flexDirection="column">
              <Button
                style={
                  {
                    ...webStyles.fullButton,
                    ...webStylesLocal.button,
                  } as React.CSSProperties
                }
                onClick={this.handleNext}
                data-test-id="handleClose"
              >
                Add risk details and earn {progress} points
              </Button>

              <Button
                style={
                  {
                    ...webStyles.transButton,
                    ...webStylesLocal.button,
                  } as React.CSSProperties
                }
                onClick={() => {
                  window.location.href = "Dashboard";
                }}
              >
                Back to Home
              </Button>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
const webStylesLocal = {
  dialogContainer: {
    minWidth: "32rem",
    minHeight: "36.5rem",
    padding: "1.9rem",
    borderRadius: "0.5rem",
    background: `url(${spots}) no-repeat`,
  },
  noteFont: {
    fontSize: "1.25rem",
    marginBottom: "1.25rem",
  },
  button: {
    width: "100%",
    height: "3.4rem",
    fontSize: "1.25rem",
    fontWeight: 700,
    textTransform: "none",
  },
};
// Customizable Area End
