import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
} from "@material-ui/core";
import { errorIcon } from "./assets";
// Customizable Area End

import CustomMessageController, {
  Props,
} from "./CustomMessageController";

export default class CustomMessage extends CustomMessageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { message } = this.props;
    return (
      <Box style={webStyle.mainContainer}>
        <img src={errorIcon} />
        <Typography style={webStyle.messageText} component={'p'}>{message}</Typography>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainContainer: {
    width: '100%',
    height: '55vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#fff',
    marginTop: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
  },
  messageText: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'center',
    marginTop: '12px',
    color: '#ababab',
  }
} as const;
// Customizable Area End
