import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

export interface DropdownValues {
  id: string;
  attributes: {
    name: string;
  }
}

interface ActionsCount {
  total_assigned_action_count: number;
  total_completed_action_count: number;
  total_overdue_action_count: number;
  total_due_action_count: number;
  total_cancelled_action_count: number;
  total_not_my_domain_action_count: number;
}
interface ActionsTableData {
  attributes?: {
    assigned_to: string;
    assigned_by: string;
    department_name: string;
    description: string;
    assinged_on: string;
    due_date: string;
    status: string;
  }
};
interface AnalyticsResponse {
  error: string;
  message: string;
  action_counts: ActionsCount;
  action_trackers: {
    data: ActionsTableData[];
    meta: {
      pagination: {
        total_count: number;
        total_pages: number;
        current_count: number;
        per_page: number;
        current_page: number;
      }
    }
  }
}
interface CountryResponse {
  error: string;
  countries: {
    data: DropdownValues[];
  }
}
interface CityResponse {
  error: string;
  plants: {
    data: DropdownValues[];
  }
}
interface PlantResponse {
  error: string;
  business_units: {
    data: DropdownValues[];
  }
}
interface DepartmentResponse {
  error: string;
  designations: {
    data: DropdownValues[];
  }
}
interface AllWorkLocationResponse {
  error: string;
  work_locations: {
    data: DropdownValues[];
  }
}
interface AssigneByAndToResponse {
  error: string;
  assigned_by: string[];
  assigned_to: string[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  token: string;
  loading: boolean;
  isWorker: boolean;
  errorMsg: string;
  tableText: string;
  selectedCountry: DropdownValues[];
  country: DropdownValues[];
  selectedCities: DropdownValues[];
  cities: DropdownValues[];
  selectedPlants: DropdownValues[];
  plants: DropdownValues[];
  selectedDepartments: DropdownValues[];
  departments: DropdownValues[];
  allWorkLocation: DropdownValues[];
  selectedAllWorkLocation: DropdownValues[];
  selectedAssignedBy: string;
  assignedBy: string[];
  selectedAssignedTo: string;
  assignedTo: string[];
  selectedYear: string;
  actionsCount: ActionsCount;
  actionTableData: ActionsTableData[];
  totalPageCount: number;
  currentPage: number;
  perPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAnalyticsActionsCallId: string = "";
  getAnalyticsCountryCallId: string = "";
  getAnalyticsCitiesCallId: string = "";
  getAnalyticsPlantsCallId: string = "";
  getAnalyticsDepartmentsCallId: string = "";
  getAnalyticsAllWorkLocationCallId: string = "";
  getAnalyticsAssignedByAndToCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      ,
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      token: "",
      loading: false,
      isWorker: false,
      errorMsg: "",
      tableText: "Total Assigned Actions",
      selectedCountry: [],
      country: [],
      selectedCities: [],
      cities: [],
      selectedPlants: [],
      plants: [],
      selectedDepartments: [],
      departments: [],
      allWorkLocation: [],
      selectedAllWorkLocation: [],
      assignedBy: [],
      selectedAssignedBy: "",
      assignedTo: [],
      selectedAssignedTo: "",
      selectedYear: "",
      actionsCount: {
        total_assigned_action_count: 0,
        total_completed_action_count: 0,
        total_overdue_action_count: 0,
        total_due_action_count: 0,
        total_cancelled_action_count: 0,
        total_not_my_domain_action_count: 0,
      },
      actionTableData: [],
      totalPageCount: 0,
      currentPage: 1,
      perPage: 10,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getAnalyticsActionsCallId) {
        this.handleAnalyticsActionsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsCountryCallId) {
        this.handleAnalyticsCountriesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsCitiesCallId) {
        this.handleAnalyticsCitiesApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsPlantsCallId) {
        this.handleAnalyticsPlantsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsDepartmentsCallId) {
        this.handleAnalyticsDepartmentsApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsAllWorkLocationCallId) {
        this.handleAnalyticsAllWorkLocationApiResponse(responseJson)
      }
      if (apiRequestCallId === this.getAnalyticsAssignedByAndToCallId) {
        this.handleAnalyticsAssignedByAndToApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    let token = await getStorageData("authToken", false);
    this.setState({ token });

    this.getAnalyticsActionsApiCall();
    this.getAnalyticsCountriesApiCall();
    this.getAnalyticsAssignedByAndToApiCall();
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage }, () => {
      this.getAnalyticsActionsApiCall();
    });
  };

  handleAnalyticsAssignedByAndToApiResponse = (responseJson: AssigneByAndToResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({
        assignedBy: responseJson.assigned_by.filter((value: string) => value !== "" && value !== null),
        assignedTo: responseJson.assigned_to.filter((value: string) => value !== "" && value !== null)
      })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleAnalyticsActionsApiResponse = (responseJson: AnalyticsResponse) => {
    if (responseJson && !responseJson.error) {
      if (responseJson?.message !== "" && !responseJson?.action_counts) {
        this.setState({
          loading: false,
          errorMsg: responseJson?.message,
          isWorker: true
        })
      } else {
        this.setState({
          actionsCount: responseJson?.action_counts,
          actionTableData: responseJson?.action_trackers?.data,
          totalPageCount: responseJson?.action_trackers?.meta.pagination?.total_count,
          loading: false,
          errorMsg: ""
        })
      }
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error,
        actionTableData: [],
        totalPageCount: 0,
        loading: false
      });
    }
  }

  handleAnalyticsCountriesApiResponse = (responseJson: CountryResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ country: responseJson.countries.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleAnalyticsCitiesApiResponse = (responseJson: CityResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ cities: responseJson.plants.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleAnalyticsPlantsApiResponse = (responseJson: PlantResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ plants: responseJson.business_units.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleAnalyticsDepartmentsApiResponse = (responseJson: DepartmentResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ departments: responseJson.designations.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleAnalyticsAllWorkLocationApiResponse = (responseJson: AllWorkLocationResponse) => {
    if (responseJson && !responseJson.error) {
      this.setState({ allWorkLocation: responseJson.work_locations.data })
    } else if (responseJson && responseJson.error) {
      this.setState({
        errorMsg: responseJson.error
      });
    }
  }

  handleChangeCurrentYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedYear: event.target.value as string });
  };

  handleChangeAssignedBy = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
    this.setState({ selectedAssignedBy: (event.target.value) as string });
  }

  handleChangeAssignedTo = (event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
    this.setState({ selectedAssignedTo: (event.target.value) as string });
  }

  handleChangeCountry = (value: unknown) => {
    const currrentValue = value;
    if (currrentValue) {
      this.setState((prevState) => {

        let updatedSelectedCountry: DropdownValues[] = [...prevState.selectedCountry];
        const exists = updatedSelectedCountry.filter((country: DropdownValues) => currrentValue === country.id).length;

        if (exists) {
          updatedSelectedCountry = updatedSelectedCountry.filter((country: DropdownValues) => currrentValue !== country.id)
        } else {
          updatedSelectedCountry.push(this.state.country.filter((country) => currrentValue === country.id)[0]);
        }

        return {
          selectedCountry: updatedSelectedCountry,
        };
      }, () => {
        this.getAnalyticsCitiesApiCall();
      });
    }
  }

  handleChangeCity = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedCity: DropdownValues[] = [...prevState.selectedCities];
      const exists = updatedSelectedCity.filter((city: DropdownValues) => currrentValue === city.id).length;

      if (exists) {
        updatedSelectedCity = updatedSelectedCity.filter((city: DropdownValues) => currrentValue !== city.id)
      } else {
        updatedSelectedCity.push(this.state.cities.filter((city) => currrentValue === city.id)[0]);
      }

      return {
        selectedCities: updatedSelectedCity
      };
    }, () => {
      this.getAnalyticsPlantsApiCall();
    });
  }

  handleChangePlant = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedPlant: DropdownValues[] = [...prevState.selectedPlants];
      const exists = updatedSelectedPlant.filter((plant: DropdownValues) => currrentValue === plant.id).length;

      if (exists) {
        updatedSelectedPlant = updatedSelectedPlant.filter((plant: DropdownValues) => currrentValue !== plant.id)
      } else {
        updatedSelectedPlant.push(this.state.plants.filter((plant) => currrentValue === plant.id)[0]);
      }

      return {
        selectedPlants: updatedSelectedPlant
      };
    }, () => {
      this.getAnalyticsDepartmentsApiCall();
    });
  }

  handleChangeDepartment = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedDepartment: DropdownValues[] = [...prevState.selectedDepartments];
      const exists = updatedSelectedDepartment.filter((department: DropdownValues) => currrentValue === department.id).length;

      if (exists) {
        updatedSelectedDepartment = updatedSelectedDepartment.filter((department: DropdownValues) => currrentValue !== department.id)
      } else {
        updatedSelectedDepartment.push(this.state.departments.filter((department) => currrentValue === department.id)[0]);
      }

      return {
        selectedDepartments: updatedSelectedDepartment
      };
    }, () => {
      this.getAnalyticsAllWorkLocationApiCall();
    });
  }

  handleChangeAllWorkLocation = (value: unknown) => {
    const currrentValue = value;
    this.setState((prevState) => {

      let updatedSelectedAllWorkLocation: DropdownValues[] = [...prevState.selectedAllWorkLocation];
      const exists = updatedSelectedAllWorkLocation.filter((all: DropdownValues) => currrentValue === all.id).length;

      if (exists) {
        updatedSelectedAllWorkLocation = updatedSelectedAllWorkLocation.filter((all: DropdownValues) => currrentValue !== all.id)
      } else {
        updatedSelectedAllWorkLocation.push(this.state.allWorkLocation.filter((all) => currrentValue === all.id)[0]);
      }

      return {
        selectedAllWorkLocation: updatedSelectedAllWorkLocation
      };
    });
  }

  handleTableandText = (text: string) => {
    this.setState({ tableText: text, currentPage: 1 }, () => { this.getAnalyticsActionsApiCall() })
  }

  handleSearch = () => {
    this.getAnalyticsActionsApiCall()
  }

  handleYearsDropDownArray = () => {
    return Array.from({ length: 5 }, (_dummy, iLength) => new Date().getFullYear() - iLength)
  }

  formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getAnalyticsActionsApiCall = () => {
    this.setState({ loading: true });
    let queryParam;
    if (this.state.tableText === "Total Assigned Actions") {
      queryParam = 'total_assigned_actions=true'
    } else if (this.state.tableText === "Completed Actions") {
      queryParam = 'completed_actions=true'
    } else if (this.state.tableText === "Overdue Actions") {
      queryParam = 'overdue_actions=true'
    } else if (this.state.tableText === "Due Actions") {
      queryParam = 'due_actions=true'
    } else if (this.state.tableText === "Cancelled Actions") {
      queryParam = 'cancelled_actions=true'
    } else if (this.state.tableText === "Not My Domain") {
      queryParam = 'not_my_domain_actions=true'
    }

    if (this.state.selectedCountry.length) {
      let contryIds = '';
      this.state.selectedCountry.forEach((value) => {
        contryIds += `${value.id},`;
      });
      queryParam += `&country_ids[]=${contryIds}`;
    }

    if (this.state.selectedCities.length) {
      let cityIds = '';
      this.state.selectedCities.forEach((value) => {
        cityIds += `${value.id},`;
      });
      queryParam += `&plant_ids[]=${cityIds}`;
    }

    if (this.state.selectedPlants.length) {
      let plantIds = '';
      this.state.selectedPlants.forEach((value) => {
        plantIds += `${value.id},`;
      });
      queryParam += `&bu_ids[]=${plantIds}`;
    }

    if (this.state.selectedDepartments.length) {
      let departmentIds = '';
      this.state.selectedDepartments.forEach((value) => {
        departmentIds += `${value.id},`;
      });
      queryParam += `&designation_id[]=${departmentIds}`;
    }

    if (this.state.selectedAllWorkLocation.length) {
      let worklocationIds = '';
      this.state.selectedAllWorkLocation.forEach((value) => {
        worklocationIds += `${value.id},`;
      });
      queryParam += `&work_location_ids[]=${worklocationIds}`;
    }

    if (this.state.selectedAssignedTo !== "") {
      queryParam += `&assigned_to[]=${this.state.selectedAssignedTo}`;
    }

    if (this.state.selectedAssignedBy !== "") {
      queryParam += `&assigned_by[]=${this.state.selectedAssignedBy}`;
    }

    if (this.state.selectedYear !== "") {
      queryParam += `&year=${this.state.selectedYear}`;
    }

    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsActionsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsActionsCallId = getAnalyticsActionsRequestMsg.messageId;
    getAnalyticsActionsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsActionsApiEndPoint}?${queryParam}&page=${this.state.currentPage}&per_page=${this.state.perPage}`
    );
    getAnalyticsActionsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsActionsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsActionsRequestMsg.id, getAnalyticsActionsRequestMsg);
  }

  getAnalyticsCountriesApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsCountryCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAnalyticsCountryApiEndPoint
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  getAnalyticsCitiesApiCall = () => {
    const queryParam = this.state.selectedCountry.map((val) => `country_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsCitiesCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsCitiesApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  getAnalyticsPlantsApiCall = () => {
    const queryParam = this.state.selectedCities.map((val) => `plant_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsPlantsCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsPlantsApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  getAnalyticsDepartmentsApiCall = () => {
    const queryParam = this.state.selectedPlants.map((val) => `bu_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsDepartmentsCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsDepartmentsApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  getAnalyticsAllWorkLocationApiCall = () => {
    const queryParam = this.state.selectedDepartments.map((val) => `designation_ids[]=${val.id}`).join('&')
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsAllWorkLocationCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsAllApiEndPoint}?${queryParam}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }

  getAnalyticsAssignedByAndToApiCall = () => {
    const headers = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const getAnalyticsRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAnalyticsAssignedByAndToCallId = getAnalyticsRequestMsg.messageId;
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAnalyticsAssignedByAndToApiEndPoint}`
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAnalyticsRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );
    runEngine.sendMessage(getAnalyticsRequestMsg.id, getAnalyticsRequestMsg);
  }
  // Customizable Area End
}
